export const INIT_GATNO = 'INIT_GATNO'

export const FETCH_GATNO_LIST = {
  START: 'FETCH_GATNO_START',
  SUCCESS: 'FETCH_GATNO_SUCCESS',
  ERROR: 'FETCH_GATNO_ERROR'
}

export const FETCH_GATNO_RECORD = {
  START: 'FETCH_GATNO_RECORD_START',
  SUCCESS: 'FETCH_GATNO_RECORD_SUCCESS',
  ERROR: 'FETCH_GATNO_RECORD_ERROR'
}

export const FETCH_GATNO_RECORD_BY_URL = {
  START: 'FETCH_GATNO_RECORD_BY_URL_START',
  SUCCESS: 'FETCH_GATNO_RECORD_BY_URL_SUCCESS',
  ERROR: 'FETCH_GATNO_RECORD_BY_URL_ERROR'
}

export const CREATE_GATNO_RECORD = {
  START: 'CREATE_GATNO_RECORD_START',
  SUCCESS: 'CREATE_GATNO_RECORD_SUCCESS',
  ERROR: 'CREATE_GATNO_RECORD_ERROR'
}

export const UPDATE_GATNO_RECORD = {
  START: 'UPDATE_GATNO_RECORD_START',
  SUCCESS: 'UPDATE_GATNO_RECORD_SUCCESS',
  ERROR: 'UPDATE_GATNO_RECORD_ERROR'
}

export const DELETE_GATNO = {
  START: 'DELETE_GATNO_START',
  SUCCESS: 'DELETE_GATNO_SUCCESS',
  ERROR: 'DELETE_GATNO_ERROR'
}

export const GET_GATNO_COUNT = {
  START: 'GET_GATNO_COUNT_START',
  SUCCESS: 'GET_GATNO_COUNT_SUCCESS',
  ERROR: 'GET_GATNO_COUNT_ERROR'
}
