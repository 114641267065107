export const FORM_FIELDS_NAME = {
  FIRST_NAME: {
    name: 'fname',
    label: 'First Name',
    type: 'text',
    placeholder: 'Enter your first name',
    rules: {
      required: 'First name is required',
      minLength: {
        value: 3,
        message: 'First name must be at least 3 characters'
      },
      maxLength: {
        value: 20,
        message: 'First name cannot exceed 20 characters'
      }
    }
  },
  LAST_NAME: {
    name: 'lname',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Enter your last name',
    rules: {
      required: 'Last name is required',
      minLength: {
        value: 3,
        message: 'Last name must be at least 3 characters'
      },
      maxLength: {
        value: 20,
        message: 'Last name cannot exceed 20 characters'
      }
    }
  },
  MOBILE: {
    name: 'mobile',
    label: 'Mobile Number',
    type: 'tel',
    placeholder: 'Enter your mobile number',
    rules: {
      required: 'Mobile number is required',
      pattern: {
        value: /^[0-9]{12}$/,
        message: 'Invalid mobile number'
      }
    }
  },
  EMAIL: {
    name: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Enter your email',
    rules: {
      required: 'Email is required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'Invalid email address'
      }
    }
  },
  ADDRESS: {
    name: 'address',
    label: 'Address',
    type: 'textarea',
    placeholder: 'Enter your address',
    rules: {
      required: 'Address is required',
      minLength: {
        value: 3,
        message: 'Address must be at least 3 characters'
      }
    }
  },
  MESSAGE: {
    name: 'msg',
    label: 'Message',
    type: 'textarea',
    placeholder: 'Enter your message',
    rules: {
      required: 'Message is required',
      minLength: {
        value: 3,
        message: 'Message must be at least 3 characters'
      }
    }
  }
}
