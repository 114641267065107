export const FORM_FIELDS_NAME = {
  FIRST_NAME: {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'Enter your first name',
    rules: {
      required: 'First name is required',
      minLength: {
        value: 3,
        message: 'First name must be at least 3 characters'
      },
      maxLength: {
        value: 20,
        message: 'First name cannot exceed 20 characters'
      }
    }
  },
  LAST_NAME: {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Enter your last name',
    rules: {
      required: 'Last name is required',
      minLength: {
        value: 3,
        message: 'Last name must be at least 3 characters'
      },
      maxLength: {
        value: 20,
        message: 'Last name cannot exceed 20 characters'
      }
    }
  },
  PHONE_NUMBER: {
    name: 'mobile',
    label: 'Phone Number',
    type: 'tel',
    placeholder: 'Enter phone number',
    rules: {
      required: 'Phone number is required'
    }
  },
  USERNAME: {
    name: 'name',
    label: 'Username',
    type: 'text',
    placeholder: 'Enter your username',
    rules: {
      required: 'Email is required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'Invalid email address'
      }
    }
  },
  EMAIL: {
    name: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Enter your email',
    rules: {
      required: 'Email is required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'Invalid email address'
      }
    }
  },
  PASSWORD: {
    name: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Enter your password',
    rules: {
      required: 'Password is required',
      minLength: {
        value: 4,
        message: 'Password must be at least 4 characters'
      },
      maxLength: {
        value: 40,
        message: 'Password cannot exceed 40 characters'
      }
    }
  },

  FILE: {
    name: 'file',
    label: 'Profile Photo',
    type: 'file',
    placeholder: 'Upload a Profile Photo',
    rules: {
      required: 'Profile Photo is required'
    }
  },
  TERMS_CONDITIONS: {
    name: 'termsConditions', 
    label: 'I agree to the Terms and Conditions', 
    type: 'checkbox',
    rules: {
      required: {
        value: true,
        message: 'You must agree to the Terms and Conditions' // Error message
      }
    }
  }
}
