export const INIT_DISTRICT = 'INIT_DISTRICT'

export const FETCH_DISTRICT_LIST = {
  START: 'FETCH_DISTRICT_START',
  SUCCESS: 'FETCH_DISTRICT_SUCCESS',
  ERROR: 'FETCH_DISTRICT_ERROR'
}

export const FETCH_DISTRICT_RECORD = {
  START: 'FETCH_DISTRICT_RECORD_START',
  SUCCESS: 'FETCH_DISTRICT_RECORD_SUCCESS',
  ERROR: 'FETCH_DISTRICT_RECORD_ERROR'
}

export const CREATE_DISTRICT_RECORD = {
  START: 'CREATE_DISTRICT_RECORD_START',
  SUCCESS: 'CREATE_DISTRICT_RECORD_SUCCESS',
  ERROR: 'CREATE_DISTRICT_RECORD_ERROR'
}

export const UPDATE_DISTRICT_RECORD = {
  START: 'UPDATE_DISTRICT_RECORD_START',
  SUCCESS: 'UPDATE_DISTRICT_RECORD_SUCCESS',
  ERROR: 'UPDATE_DISTRICT_RECORD_ERROR'
}

export const DELETE_DISTRICT = {
  START: 'DELETE_DISTRICT_START',
  SUCCESS: 'DELETE_DISTRICT_SUCCESS',
  ERROR: 'DELETE_DISTRICT_ERROR'
}

export const GET_DISTRICT_COUNT = {
  START: 'GET_DISTRICT_COUNT_START',
  SUCCESS: 'GET_DISTRICT_COUNT_SUCCESS',
  ERROR: 'GET_DISTRICT_COUNT_ERROR'
}
