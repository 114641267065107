export const FORM_FIELDS_NAME = {
    EMAIL: {
        name: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter your email address',
        rules: {
          required: 'Email is required',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: 'Invalid email address'
          }
        }
      },


}