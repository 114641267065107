import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { initcontactUs, fetchcontactUsList } from '../../redux/action/contactUs'
import enquiryTableData from './data.json'
import { ProgressBar } from 'primereact/progressbar'
import EnquiryComponent from '../../components/enquiry'

const EnquiryScreen = (props) => {
  const {
    fetchcontactUsList,
    initcontactUs,
    contactUsList,
    isLoading,
    isPageLevelError,
    // error,
    userRole,

    isDeleteUserSuccess
  } = props

  const enquiryProps = {
    contactUsList,
    fetchcontactUsList,
    isPageLevelError,
    isLoading,
    userRole,
    enquiryTableData
  }

  useEffect(() => {
    initcontactUs()
    const paylaod = {
      pageNumber: 0,
      pageSize: 10
    }
    // if (USER_ROLE===USER_ROLE.SUPER_ADMIN) {
    fetchcontactUsList(paylaod)
    // }
    // eslint-disable-next-line
  }, [isDeleteUserSuccess])
  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <EnquiryComponent enquiryProps={enquiryProps} />
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    initcontactUs: () => dispatch(initcontactUs()),
    fetchcontactUsList: (payload) => dispatch(fetchcontactUsList(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.contactUsReducer.isPageLevelError,
    isLoading: state.contactUsReducer.isLoading,
    error: state.contactUsReducer.error,
    contactUsList: state.contactUsReducer.contactUsList,
    isDeleteUserSuccess: state.contactUsReducer.isDeleteUserSuccess,
    isDeleteUserError: state.contactUsReducer.isDeleteUserError
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnquiryScreen)
