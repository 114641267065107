import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'

const DropDown = (props) => {
  const {
    control,
    name,
    options,
    optionLabel,
    optionValue,
    rules,
    onChange,
    disabled,
    placeholder,
    inputStyle,
    loading
  } = props

  const [filteredOptions, setFilteredOptions] = useState(options)

  const handleFilter = (inputValue) => {
    if (inputValue) {
      const filtered = options.filter((option) =>
        option[optionLabel].toLowerCase().includes(inputValue.toLowerCase())
      )
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions(options)
    }
  }

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Dropdown
          disabled={disabled}
          style={inputStyle ? JSON.parse(inputStyle) : { width: '100%' }}
          placeholder={loading ? placeholder : placeholder}
          editable={true}
          id={field.name}
          value={field.value}
          options={filteredOptions}
          optionLabel={optionLabel}
          optionValue={optionValue}
          loading={loading}
          onChange={(e) => {
            if (onChange) {
              onChange(e)
            }
            field.onChange(e.value)
          }}
          onInput={(e) => handleFilter(e.target.value)}
          className={classNames({
            'p-invalid': fieldState.invalid
          })}
        />
      )}
    />
  )
}

DropDown.defaultProps = {
  optionLabel: 'label',
  optionValue: 'value',
  inputStyle: '',
  loading: false
}

export default DropDown
