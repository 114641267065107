import { connect } from 'react-redux'
import {
  deleteAccess,
  fetchAccessList,
  initAccess
} from '../../redux/action/access'
import { useEffect, useState } from 'react'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import accessTableData from './data.json'
import AccessComponent from '../../components/access'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const AccessScreen = (props) => {
  const {
    fetchAccessList,
    initAccess,
    accessList,
    isLoading,
    isPageLevelError,
    userRole,
    deleteAccess,
    isdeleteAccessSuccess,
    isdeleteAccessError
  } = props

  useEffect(() => {
    initAccess()
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      const paylaod = {
        pageNumber: 0,
        pageSize: 10
      }
      fetchAccessList(paylaod)
    }
    // eslint-disable-next-line
  }, [isdeleteAccessSuccess])

  const history = useNavigate()

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [selectedAccess, setSelectedAccess] = useState(null)

  const handleOnDeleteRecord = (data) => {
    setSelectedAccess(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.ACCESS.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.ACCESS.ADD)
  }

  const confirmDelete = () => {
    if (selectedAccess) {
      deleteAccess(selectedAccess.id)
      setIsDeleteDialogVisible(false)
      setSelectedAccess(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedAccess(null)
  }

  const accessProps = {
    accessList,
    fetchAccessList,
    isPageLevelError,
    isLoading,
    accessTableData,
    userRole,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const getToastProps = () => {
    if (isdeleteAccessSuccess) {
      const toastTitle = 'Access Status Change Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isdeleteAccessError) {
      let toastTitle = 'Error while Changing Access Status'
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />

      <AccessComponent accessProps={accessProps} />

      <Dialog
        header={`Confirm ${selectedAccess?.active ? 'Deactivate' : 'Activate'}`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedAccess?.active ? 'danger' : ''}
              onClick={confirmDelete}
            >
              Yes
            </Button>
            <Button severity="" onClick={cancelDelete}>
              No
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          Are you sure you want to{' '}
          {selectedAccess?.active ? 'deactivate' : 'activate'} this User?{' '}
          <span className="text-primary">{selectedAccess?.username}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initAccess: () => dispatch(initAccess()),
    fetchAccessList: (payload) => dispatch(fetchAccessList(payload)),
    deleteAccess: (payload) => dispatch(deleteAccess(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.accessReducer.isPageLevelError,
    isLoading: state.accessReducer.isLoading,
    error: state.accessReducer.error,
    accessList: state.accessReducer.accessList,
    userRole: state.loginReducer.userRole,
    isdeleteAccessSuccess: state.accessReducer.isdeleteAccessSuccess,
    isdeleteAccessError: state.accessReducer.isdeleteAccessError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessScreen)
