import React from 'react'
import DropDown from '../index'
import { classNames } from 'primereact/utils'
import { PropTypes } from 'prop-types'
import { Message } from 'primereact/message'

const MzDropDown = (props) => {
  const {
    control,
    name,
    shouldFilter,
    filter,
    options,
    optionLabel,
    optionValue,
    rules,
    onChange,
    // disabled,
    placeholder,
    inputStyle,
    labelClassName,
    label,
    errorMsg,
    isError,
    id,
    loading
  } = props
  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      [labelClassName]: labelClassName
    })
  }

  return (
    <div className="field" style={{ textAlign: 'start' }}>
      <label htmlFor={name} className={getLabelClassName()}>
        {label}
        {rules?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </label>
      <span>
        <DropDown
          id={id}
          name={name}
          control={control}
          shouldFilter={shouldFilter}
          disabled={loading}
          filter={filter}
          loading={loading || options?.length === 0}
          optionLabel={optionLabel}
          optionValue={optionValue}
          options={options}
          onChange={onChange}
          rules={rules}
          inputStyle={inputStyle}
          placeholder={placeholder}
        />
      </span>
      {errorMsg && (
        <Message
          className="mt-1 flex"
          style={{
            borderWidth: '0 0 0 2px'
          }}
          severity="error"
          content={errorMsg}
        />
      )}
    </div>
  )
}

MzDropDown.propTypes = {
  loading: PropTypes.bool
}

MzDropDown.defaultProps = {
  shouldFilter: false,
  filter: true,
  loading: false
}

export default MzDropDown
