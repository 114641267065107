import {
  INIT_KML_UPLOAD,
  FETCH_KML_UPLOAD_LIST,
  FETCH_KML_UPLOAD_RECORD,
  CREATE_KML_UPLOAD_RECORD,
  DELETE_KML_UPLOAD,
  UPDATE_KML_UPLOAD_RECORD,
  GET_KML_UPLOAD_COUNT
} from '../../../constant/actionTypes/kmlUpload'

const formFieldValueMap = {
  province: '',
  district: '',
  subDistrict: '',
  village: '',
  kml: null
}

const initialState = {
  kmlUploadList: [],
  kmlUploadCount: 0,
  selectedKmlUpload: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateKmlUploadSuccess: false,
  isEditKmlUploadSuccess: false,
  isKmlUploadDetailSuccess: false,
  isDeleteKmlUploadSuccess: false,
  isDeleteKmlUploadError: false,
  isKmlUploadDetailError: false,
  isCreateKmlUploadError: false,
  isEditKmlUploadError: false
}

const kmlUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_KML_UPLOAD:
      return {
        ...state,
        formFieldValueMap: null,
        kmlUploadList: [],
        selectedKmlUpload: null,
        isLoading: false,
        error: null
      }
    case FETCH_KML_UPLOAD_LIST.START:
    case FETCH_KML_UPLOAD_RECORD.START:
    case CREATE_KML_UPLOAD_RECORD.START:
    case DELETE_KML_UPLOAD.START:
    case UPDATE_KML_UPLOAD_RECORD.START:
    case GET_KML_UPLOAD_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateKmlUploadSuccess: false,
        isEditKmlUploadSuccess: false,
        isKmlUploadDetailSuccess: false,
        isDeleteKmlUploadSuccess: false,
        isDeleteKmlUploadError: false,
        isKmlUploadDetailError: false,
        isCreateKmlUploadError: false,
        isEditKmlUploadError: false
      }
    case FETCH_KML_UPLOAD_LIST.SUCCESS:
      return {
        ...state,
        kmlUploadList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_KML_UPLOAD_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        kmlUploadCount: action.payload
      }
    }
    case GET_KML_UPLOAD_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        kmlUploadCount: 0
      }
    }
    case FETCH_KML_UPLOAD_RECORD.SUCCESS:
      return {
        ...state,
        selectedKmlUpload: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          tenant: action?.payload?.tenant?.id ?? '',
          username: action?.payload?.username ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          authorities: action?.payload?.authorities[0]?.name ?? '',
          department: action?.payload?.department?.id ?? '',
          profilePicture: action?.payload?.profilePicture ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobileNumber: action?.payload?.mobileNumber ?? ''
        },
        isLoading: false,
        error: null,
        isKmlUploadDetailSuccess: true
      }
    case CREATE_KML_UPLOAD_RECORD.SUCCESS:
      return {
        ...state,
        kmlUploadList: [...state.kmlUploadList, action.payload],
        isLoading: false,
        error: null,
        isCreateKmlUploadSuccess: true
      }
    case UPDATE_KML_UPLOAD_RECORD.SUCCESS:
      return {
        ...state,
        kmlUploadList: state.kmlUploadList.map((kmlUpload) =>
          kmlUpload.id === action.payload.id ? action.payload : kmlUpload
        ),
        isLoading: false,
        error: null,
        isEditKmlUploadSuccess: true
      }
    case DELETE_KML_UPLOAD.SUCCESS:
      return {
        ...state,
        kmlUploadList: state.kmlUploadList.filter(
          (kmlUpload) => kmlUpload.id !== action.payload.kmlUploadId
        ),
        isLoading: false,
        error: null,
        isDeleteKmlUploadSuccess: true
      }
    case FETCH_KML_UPLOAD_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_KML_UPLOAD_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isKmlUploadDetailError: true
      }
    case CREATE_KML_UPLOAD_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateKmlUploadError: true
      }

    case UPDATE_KML_UPLOAD_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditKmlUploadError: true
      }
    case DELETE_KML_UPLOAD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteKmlUploadError: true
      }

    default:
      return state
  }
}

export default kmlUploadReducer
