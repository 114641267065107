import {
  INIT_DISTRICT,
  FETCH_DISTRICT_LIST,
  FETCH_DISTRICT_RECORD,
  CREATE_DISTRICT_RECORD,
  DELETE_DISTRICT,
  UPDATE_DISTRICT_RECORD,
  GET_DISTRICT_COUNT
} from '../../../constant/actionTypes/district'

const formFieldValueMap = {
  tenant: '',
  username: '',
  email: '',
  password: '',
  authorities: '',
  department: '',
  profilePicture: null
}

const initialState = {
  districtsList: [],
  districtCount: 0,
  selectedDistrict: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateDistrictSuccess: false,
  isEditDistrictSuccess: false,
  isDistrictDetailSuccess: false,
  isDeleteDistrictSuccess: false,
  isDeleteDistrictError: false,
  isDistrictDetailError: false,
  isCreateDistrictError: false,
  isEditDistrictError: false
}

const districtReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_DISTRICT:
      return {
        ...state,
        formFieldValueMap: null,
        districtsList: [],
        selectedDistrict: null,
        isLoading: false,
        error: null
      }
    case FETCH_DISTRICT_LIST.START:
    case FETCH_DISTRICT_RECORD.START:
    case CREATE_DISTRICT_RECORD.START:
    case DELETE_DISTRICT.START:
    case UPDATE_DISTRICT_RECORD.START:
    case GET_DISTRICT_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateDistrictSuccess: false,
        isEditDistrictSuccess: false,
        isDistrictDetailSuccess: false,
        isDeleteDistrictSuccess: false,
        isDeleteDistrictError: false,
        isDistrictDetailError: false,
        isCreateDistrictError: false,
        isEditDistrictError: false
      }
    case FETCH_DISTRICT_LIST.SUCCESS:
      return {
        ...state,
        districtsList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_DISTRICT_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        districtCount: action.payload
      }
    }
    case GET_DISTRICT_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        districtCount: 0
      }
    }
    case FETCH_DISTRICT_RECORD.SUCCESS:
      return {
        ...state,
        selectedDistrict: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          tenant: action?.payload?.tenant?.id ?? '',
          username: action?.payload?.username ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          authorities: action?.payload?.authorities[0]?.name ?? '',
          department: action?.payload?.department?.id ?? '',
          profilePicture: action?.payload?.profilePicture ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobileNumber: action?.payload?.mobileNumber ?? ''
        },
        isLoading: false,
        error: null,
        isDistrictDetailSuccess: true
      }
    case CREATE_DISTRICT_RECORD.SUCCESS:
      return {
        ...state,
        districtsList: [...state.districtsList, action.payload],
        isLoading: false,
        error: null,
        isCreateDistrictSuccess: true
      }
    case UPDATE_DISTRICT_RECORD.SUCCESS:
      return {
        ...state,
        districtsList: state.districtsList.map((district) =>
          district.id === action.payload.id ? action.payload : district
        ),
        isLoading: false,
        error: null,
        isEditDistrictSuccess: true
      }
    case DELETE_DISTRICT.SUCCESS:
      return {
        ...state,
        districtsList: state.districtsList.filter(
          (district) => district.id !== action.payload.districtId
        ),
        isLoading: false,
        error: null,
        isDeleteDistrictSuccess: true
      }
    case FETCH_DISTRICT_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_DISTRICT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDistrictDetailError: true
      }
    case CREATE_DISTRICT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateDistrictError: true
      }

    case UPDATE_DISTRICT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditDistrictError: true
      }
    case DELETE_DISTRICT.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteDistrictError: true
      }

    default:
      return state
  }
}

export default districtReducer
