import React from 'react'
import {
  ABOUTIMG1,
  ABOUTIMG2,
  // INVESTORIMG1,
  // INVESTORIMG2,
  // INVESTORIMG3,
  // INVESTORIMG4,
  // INVESTORIMG5,
  // INVESTORIMG6,
  // TEAMIMG1,
  // TEAMIMG2,
  // TEAMIMG3
} from '../../assets/images'
import './index.css'
const AboutComponent = () => {
  return (
    <div className=" mt-8 about-us-main-page-container">

      <div
        className="flex flex-column align-items-center heading-bg-container justify-content-center py-5 mb-3"
      >
        <div className="py-4 px-4 text-center">
          <h1
            className="font-bold text-4xl md:text-5xl lg:text-6xl mb-4"
          >
            About Us
          </h1>
          <p className="text-lg text-700 font-medium lg:font-normal ">
          Welcome to Bhoomi22, your trusted online platform for comprehensive land and property management solutions. 
          </p>
        </div>
      </div>

      <div className="grid m-0  sm:px-5 md:px-7 pt-5 mb-3 lg:mb-7 mr-2 ml-2 lg:mr-6 lg:ml-6">
        <div className="flex flex-column lg:flex-row">
          <div className="col-12 md:col-12 lg:col-6 p-0">
            <div className="flex ai-center overflow-hidden">
              <div className="col-6 mb-2 lg:mb-8">
                <img className="w-full" src={ABOUTIMG1} alt="" />
              </div>
              <div className="col-6 aboutImg2">
                <img className="w-full" src={ABOUTIMG2} alt="" />
              </div>
            </div>
          </div>

          <div className="col-12 md:col-12 lg:col-6">
            <div className="pt-1">
              <p
                className="text-uppercase font-semibold  mb-2 text-lg content-heading"
              >
                About Us
              </p>
              <h1
                className="mb-3 mt-0 font-bold text-4xl md:text-5xl  lg:text-6xl about-h1-heading-style"
              >
                Empowering You with 
                <br />
                Verified Land Information
              </h1>
              <p className=" text-justify ">
              Welcome to <span className='font-bold'>Bhoomi22</span>, your trusted online platform for comprehensive land and property management solutions. Developed by <span className='font-bold'>Clearcole Solution</span>, Bhoomi22 is designed to simplify and streamline the complex processes involved in managing land records, legal documentation, and property transactions. <br/> 
              Our platform is tailored for individuals, businesses, and professionals looking for accurate, reliable, and efficient tools to manage land-related matters with ease. Whether you're a property owner, real estate agent, or legal expert, Bhoomi22 empowers you to access, manage, and secure your property data in a few simple clicks.<br/> 
              At Bhoomi22, we believe in leveraging technology to make land management more transparent and accessible for everyone. 
              </p>

              <div className="flex flex-column lg:flex-row  mt-4">
                <div
                  className="text-center  px-4 py-2 mr-0  lg:mr-2 experience-text-bg"
                >
                  <h1 className="experience-text-color experience-text-h1 text-6xl">10</h1>
                  <h5 className=" experience-text-color experience-text-h5 text-xl">Years of</h5>
                  <h5 className=" experience-text-color experience-text-h5 text-xl">Experience</h5>
                </div>
                <div className=" ml-2 mt-4 mt-0">
                  <p className=" mb-3">
                    <i
                      className="pi pi-check mr-1 text-success font-bold values-icon-color"
                    ></i>
                    Accuracy And Trust
                  </p>
                  <p className=" mb-3">
                    <i
                      className="pi pi-check mr-1 text-success font-bold values-icon-color"      
                    ></i>
                    Genuine Information
                  </p>
                  <p className=" mb-3">
                    <i
                      className="pi pi-check mr-1 text-success font-bold values-icon-color"                   
                    ></i>
                    Location On One Click
                  </p>
                  <p className=" mb-3">
                    <i
                      className="pi pi-check mr-1 text-success font-bold values-icon-color"          
                    ></i>
                    Accurate Land Data
                  </p>
                  <p>
                    <i
                      className="pi pi-check mr-1 text-success font-bold values-icon-color"  
                    ></i>
                    Ensuring Transparency
                  </p>
                </div>
              </div>

              <div className="grid m-0 mt-3">
                <div className="  col-12 lg:col-7 md:col-12 flex ai-center">
                  <div
                    className="border-circle w-3rem h-3rem  flex align-items-center justify-content-center about-contact-bg"
                  >
                    <a href="mailto:support@bhoomi22.com">
                      <i className="pi pi-envelope text-white text-2xl"></i>
                    </a>
                  </div>
                  <div className=" pl-2">
                    <p className=" m-1">Email us</p>
                    <p className=" m-0">
                      {' '}
                      <a
                        href="mailto:support@bhoomi22.com."
                        className='about-links-style'
                      >
                        support@bhoomi22.com.
                      </a>
                    </p>
                  </div>
                </div>
                <div className=" col-12 lg:col-5 md:col-12 flex ai-center ml-0">
                  <div
                    className="border-circle w-3rem h-3rem flex align-items-center justify-content-center about-contact-bg"
                  > 
                    <a href="tel:+919689501000">
                      <i className="pi pi-phone text-white text-2xl"></i>
                    </a>
                  </div>
                  <div className=" pl-2">
                    <p className="text-secondary-800 m-1 ">Call us</p>
                    <p className="text-secondary-800 m-0">
                      <a
                        href="tel:+919689501000"
                        className='about-links-style'
                      >
                        +91 96895-01000
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" sm:px-5 md:px-7 pt-5 mb-3 lg:mb-7 mr-4 ml-4 lg:mr-6 lg:ml-6">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-6  flex align-items-center justify-content-start">
            <div className="pt-2 md:pt-3">
              <h1
                className="font-bold text-4xl mb-3 md:text-5xl  lg:text-6xl"
              >
                Why We Best From Others
              </h1>
              <ul className="list-none p-0 mt-3">
                <li className="mb-2 ">
                  <span className="font-medium ">Mastery:</span> With a commitment to innovation, security, and customer satisfaction, Bhoomi22 is built on the values of trust, transparency, and technological excellence.
                </li>
                <li className="mb-2">
                  <span className="font-medium ">Trust:</span> Our dedicated support team is always here to assist you, making sure your experience with Bhoomi22 is seamless and hassle-free.
                </li>
                <li className="mb-2">
                  <span className="font-medium ">Accuracy:</span> Our platform integrates advanced tools for land record maintenance, property verification, and compliance with local regulations, ensuring that you have the most up-to-date and accurate information available.
                </li>
                <li className="mb-2">
                  <span className="font-medium ">Harmony:</span> Join us on our journey to revolutionize land management in India. Whether you're buying, selling, or managing property, Bhoomi22 is your go-to platform for reliable, efficient, and user-friendly solutions.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 md:col-12 lg:col-6 flex align-items-center justify-content-center">
            <div className="w-full">
              <iframe
                width="100%"
                height="335"
                src="https://www.youtube.com/embed/VZQfbmvCJrM?si=wN4S76H0_F1j6e6q" 
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
       
       {/* Team member information */}

      {/* <div className="sm:px-5 md:px-7 pt-5 mb-3 lg:mb-7 mr-4 ml-4 lg:mr-6 lg:ml-6 text-center">
        <div className="grid m-0">
          <div className="col-12 p-0">
            <div>
              <p
                className="text-uppercase font-semibold  mb-2 text-lg"
                style={{ color: '#a39f9f' }}
              >
                Our Team
              </p>
              <h1
                className="font-bold text-4xl md:text-5xl mb-3 lg:text-6xl"
                style={{ color: '#4caf50' }}
              >
                Dedicated Team Members
              </h1>

              <div className="flex flex-wrap justify-content-center mt-5">
                <div className="col-12 md:col-4 lg:col-4 mb-2">
                  <div className="shadow-2 p-2">
                    <img src={TEAMIMG1} alt="John Bush" className="w-full" />
                    <div className="pt-2">
                      <h2 className="  text-1xl" style={{ color: '#4caf50' }}>
                        John Bush
                      </h2>
                      <p className="text-lg lg:text-base md:text-base text-gray-600">
                        CEO & FOUNDER
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-4 lg:col-4 mb-2">
                  <div className="shadow-2 p-2">
                    <img
                      src={TEAMIMG2}
                      alt="Andrew Markos"
                      className="w-full"
                    />
                    <div className="pt-2">
                      <h2
                        className=" font-semibold text-1xl"
                        style={{ color: '#4caf50' }}
                      >
                        Andrew Markos
                      </h2>
                      <p className="text-lg lg:text-base md:text-base  text-gray-600">
                        CO-FOUNDER
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-4 lg:col-4 ">
                  <div className="shadow-2 p-2">
                    <img src={TEAMIMG3} alt="Stivan Smith" className="w-full" />
                    <div className="pt-2">
                      <h2
                        className=" font-semibold text-1xl"
                        style={{ color: '#4caf50' }}
                      >
                        Stivan Smith
                      </h2>
                      <p className="text-lg lg:text-base md:text-base  text-gray-600">
                        PROJECT MANAGER
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

     {/* sponsers information */}
   
      {/* <div className="sm:px-5 md:px-7 pt-5 mb-3 lg:mb-7 mr-4 ml-4 lg:mr-6 lg:ml-6">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-12">
            <h1
              className="font-bold text-4xl md:text-5xl mb-3 lg:text-6xl text-center"
              style={{ color: '#4caf50' }}
            >
              Our Investors
            </h1>
            <div className="flex justify-content-evenly flex-wrap">
              <div className="lg:col-2 md:col-4 sm:col-12 p-2">
                <img src={INVESTORIMG1} alt="Investor 1" className="w-full" />
              </div>
              <div className="lg:col-2 md:col-4 sm:col-12 p-2">
                <img src={INVESTORIMG2} alt="Investor 2" className="w-full" />
              </div>
              <div className="lg:col-2 md:col-4 sm:col-12 p-2">
                <img src={INVESTORIMG3} alt="Investor 3" className="w-full" />
              </div>
              <div className="lg:col-2 md:col-4 sm:col-12 p-2">
                <img src={INVESTORIMG4} alt="Investor 4" className="w-full" />
              </div>
              <div className="lg:col-2 md:col-4 sm:col-12 p-2">
                <img src={INVESTORIMG5} alt="Investor 5" className="w-full" />
              </div>
              <div className="lg:col-2 md:col-4 sm:col-12 p-2">
                <img src={INVESTORIMG6} alt="Investor 6" className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default AboutComponent
