import {
  INIT_PAYMENT,
  FETCH_PLAN_LIST
} from '../../../constant/actionTypes/payment'
import { paymentService } from '../../../services'

export const initPayment = () => ({
  type: INIT_PAYMENT
})

export const fetchPlanListStart = () => ({
  type: FETCH_PLAN_LIST.START
})

export const fetchPlanListSuccess = (Plans) => ({
  type: FETCH_PLAN_LIST.SUCCESS,
  payload: Plans
})

export const fetchPlanListError = (error) => ({
  type: FETCH_PLAN_LIST.ERROR,
  payload: { error }
})

export const fetchPlanList = (payload) => {
  return (dispatch) => {
    dispatch(fetchPlanListStart(payload))
    paymentService.fetchPlanList(payload).then((PlanData) => {
      if (!PlanData.isError) {
        dispatch(fetchPlanListSuccess(PlanData))
      } else {
        dispatch(fetchPlanListError(PlanData))
      }
    })
  }
}
