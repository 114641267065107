import { BreadCrumb } from 'primereact/breadcrumb'
import React, { useState } from 'react'
import { ROUTE_PATH } from '../../constant/urlConstant'
import MzTable from '../../common/MzTable'
import { useNavigate } from 'react-router-dom'
import ErrorPage from '../../common/Error'

const EnquiryComponent = (props) => {
  // const [enquiryData, setenquiryData] = useState([])
  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: 10,
    pageNumber: 0,
    totalRecords: 0,
    totalPages: 0
  })

  const {
    contactUsList,
    fetchcontactUsList,
    isPageLevelError,
    isLoading,
    enquiryTableData,
    handleOnReadRecord
  } = props.enquiryProps
  const history = useNavigate()
  const shouldRenderFullPageError = () => isPageLevelError
  const shouldRenderUserTransactionList = () =>
    enquiryTableData?.tableData?.columns?.length > 0
  const shouldRenderNotFoundView = () =>
    !shouldRenderFullPageError && !shouldRenderUserTransactionList

  const filters = {
    global: { value: null },
    userName: { filterKey: 'username', value: null },
    email: { filterKey: 'email', value: null }
  }
  
  const loadLazyData = (event) => {
    const { pageNumber, pageSize } = event
     

    setPaginationInfo({
     
      pageNumber,
      pageSize
    })
    fetchcontactUsList({
      pageNumber,
      pageSize
    })
  }
    
  const tableProps = {
    value: contactUsList?.content ?? [],
    loading: isLoading,
    columns: enquiryTableData?.tableData?.columns,
    paginationInfo: {
      ...paginationInfo,
      totalRecords: contactUsList?.totalElements ?? 0
    },
    screenPermission: enquiryTableData?.tableData?.screenPermission,
    loadLazyData,
    emptyMessage: 'No Enquiry Record Found',
    filters,
    sortField: null,
    showMoreActions: false,
    onReadRecord: handleOnReadRecord,
  }
  

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'Enquiry', command: () => history(ROUTE_PATH.ENQUIRY.HOME) }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center border-1 border-200 border-round bg-white">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
        <div className="mr-2 flex justify-content-between h-full align-items-center gap-3"></div>
      </div>
    )
  }

  return (
    <div>
      <div className=" ">
        {renderPageHeaderSection()}
        {shouldRenderFullPageError() && (
          <div>
            <ErrorPage />
          </div>
        )}
        {shouldRenderUserTransactionList() && (
          <div className="border-1 border-200 border-round mt-3 p-2 bg-white">
            <MzTable {...tableProps} />
          </div>
        )}
        {shouldRenderNotFoundView() && <div>No record to show</div>}
      </div>
    </div>
  )
}
export default EnquiryComponent
