import { connect } from 'react-redux'
import { useEffect } from 'react'
import {
  createAccessRecord,
  fetchAccessRecord,
  initAccess,
  updateAccessRecord
} from '../../../redux/action/access'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import accessTableData from '../data.json'
import AddAccessComponent from '../../../components/access/addAccess'
import { useNavigate } from 'react-router-dom'
import { fetchProvinceList } from '../../../redux/action/province'
import { fetchDistrictList } from '../../../redux/action/district'
import { fetchSubDistrictList } from '../../../redux/action/subDistrict'
import { fetchVillageList } from '../../../redux/action/village'
import { fetchUserList, initUser } from '../../../redux/action/user'

const AddAccessScreen = (props) => {
  const {
    isCreateAccessSuccess,
    isCreateAccessError,
    isAccessDetailSuccess,
    isAccessDetailError,
    isEditAccessSuccess,
    isEditAccessError,
    initAccess,
    accessList,
    isEdit,
    isLoading,
    createAccessRecord,

    isPageLevelError,

    userRole,
    fetchAccessRecord,

    isdeleteAccessSuccess,
    isdeleteAccessError,
    fetchAccessList,

    fetchProvinceList,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchUserList,

    userList
  } = props

  const history = useNavigate()

  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.ACCESS.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.ACCESS.ADD)
  }

  useEffect(() => {
    initAccess()
    fetchProvinceList()
    initUser()
    fetchUserList()
    if (userRole === USER_ROLE.SUPER_ADMIN) {
    }
    // eslint-disable-next-line
  }, [userRole])

  const accessProps = {
    accessList,
    fetchAccessList,
    isPageLevelError,
    isLoading,
    accessTableData,
    userRole,
    fetchProvinceList,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    userList,
    isEdit,
    fetchUserList,
    createAccessRecord,
    isCreateAccessSuccess,
    isCreateAccessError,
    isEditAccessSuccess,
    isEditAccessError,
    isAccessDetailError,
    isAccessDetailSuccess,
    fetchAccessRecord,

    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isdeleteAccessSuccess) {
      const toastTitle = 'Access Status Change Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isdeleteAccessError) {
      let toastTitle = 'Error while Changing Access Status'
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddAccessComponent accessProps={accessProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initAccess: () => dispatch(initAccess()),
    createAccessRecord: (payload) => dispatch(createAccessRecord(payload)),
    fetchAccessRecord: (payload) => dispatch(fetchAccessRecord(payload)),
    updateAccessRecord: (payload, id) =>
      dispatch(updateAccessRecord(payload, id)),
    fetchProvinceList: (payload) => dispatch(fetchProvinceList(payload)),
    fetchDistrictList: (payload) => dispatch(fetchDistrictList(payload)),
    fetchSubDistrictList: (payload) => dispatch(fetchSubDistrictList(payload)),
    fetchVillageList: (payload) => dispatch(fetchVillageList(payload)),
    fetchUserList: () => dispatch(fetchUserList())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.accessReducer.isPageLevelError,
    isLoading: state.accessReducer.isLoading,
    error: state.accessReducer.error,
    accessList: state.accessReducer.accessList,
    userRole: state.loginReducer.userRole,
    isdeleteAccessSuccess: state.accessReducer.isdeleteAccessSuccess,
    isdeleteAccessError: state.accessReducer.isdeleteAccessError,
    provincesList: state.provinceReducer.provincesList,
    districtsList: state.districtReducer.districtsList,
    subDistrictsList: state.subDistrictReducer.subDistrictsList,
    villagesList: state.villageReducer.villagesList,
    userList: state.userReducer.userList,
    isCreateAccessSuccess: state.accessReducer.isCreateAccessSuccess,
    isCreateAccessError: state.accessReducer.isCreateAccessError,
    isEditAccessSuccess: state.accessReducer.isEditAccessSuccess,
    isEditAccessError: state.accessReducer.isEditAccessError,
    isAccessDetailError: state.accessReducer.isAccessDetailError,
    isAccessDetailSuccess: state.accessReducer.isAccessDetailSuccess
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAccessScreen)
