export const INIT_ACCESS = 'INIT_ACCESS'

export const FETCH_ACCESS_LIST = {
  START: 'FETCH_ACCESS_START',
  SUCCESS: 'FETCH_ACCESS_SUCCESS',
  ERROR: 'FETCH_ACCESS_ERROR'
}

export const FETCH_ACCESS_RECORD = {
  START: 'FETCH_ACCESS_RECORD_START',
  SUCCESS: 'FETCH_ACCESS_RECORD_SUCCESS',
  ERROR: 'FETCH_ACCESS_RECORD_ERROR'
}

export const CREATE_ACCESS_RECORD = {
  START: 'CREATE_ACCESS_RECORD_START',
  SUCCESS: 'CREATE_ACCESS_RECORD_SUCCESS',
  ERROR: 'CREATE_ACCESS_RECORD_ERROR'
}

export const UPDATE_ACCESS_RECORD = {
  START: 'UPDATE_ACCESS_RECORD_START',
  SUCCESS: 'UPDATE_ACCESS_RECORD_SUCCESS',
  ERROR: 'UPDATE_ACCESS_RECORD_ERROR'
}

export const DELETE_ACCESS = {
  START: 'DELETE_ACCESS_START',
  SUCCESS: 'DELETE_ACCESS_SUCCESS',
  ERROR: 'DELETE_ACCESS_ERROR'
}

export const GET_COUNT_ACCESS = {
  START: 'GET_COUNT_ACCESS_START',
  SUCCESS: 'GET_COUNT_ACCESS_SUCCESS',
  ERROR: 'GET_COUNT_ACCESS_ERROR'
}
