import {
  INIT_SUB_DISTRICT,
  FETCH_SUB_DISTRICT_LIST,
  FETCH_SUB_DISTRICT_RECORD,
  CREATE_SUB_DISTRICT_RECORD,
  DELETE_SUB_DISTRICT,
  UPDATE_SUB_DISTRICT_RECORD,
  GET_SUB_DISTRICT_COUNT
} from '../../../constant/actionTypes/subDistrict'
import { subDistrictService } from '../../../services'

export const initSubDistrict = () => ({
  type: INIT_SUB_DISTRICT
})

export const fetchSubDistrictListStart = () => ({
  type: FETCH_SUB_DISTRICT_LIST.START
})

export const fetchSubDistrictListSuccess = (subDistricts) => ({
  type: FETCH_SUB_DISTRICT_LIST.SUCCESS,
  payload: subDistricts
})

export const fetchSubDistrictListError = (error) => ({
  type: FETCH_SUB_DISTRICT_LIST.ERROR,
  payload: { error }
})

export const fetchSubDistrictList = (payload) => {
  return (dispatch) => {
    dispatch(fetchSubDistrictListStart(payload))
    subDistrictService.fetchSubDistrictList(payload).then((subDistrictData) => {
      if (!subDistrictData.isError) {
        dispatch(fetchSubDistrictListSuccess(subDistrictData))
      } else {
        dispatch(fetchSubDistrictListError(subDistrictData))
      }
    })
  }
}

export const fetchSubDistrictRecordStart = () => ({
  type: FETCH_SUB_DISTRICT_RECORD.START
})

export const fetchSubDistrictRecordSuccess = (subDistrict) => ({
  type: FETCH_SUB_DISTRICT_RECORD.SUCCESS,
  payload: subDistrict
})

export const fetchSubDistrictRecordError = (error) => ({
  type: FETCH_SUB_DISTRICT_RECORD.ERROR,
  payload: { error }
})

export const fetchSubDistrictRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchSubDistrictRecordStart(payload))
    subDistrictService
      .fetchSubDistrictRecord(payload)
      .then((subDistrictData) => {
        if (!subDistrictData.isError) {
          dispatch(fetchSubDistrictRecordSuccess(subDistrictData))
        } else {
          dispatch(fetchSubDistrictRecordError(subDistrictData))
        }
      })
  }
}

export const createSubDistrictRecordStart = () => ({
  type: CREATE_SUB_DISTRICT_RECORD.START
})

export const createSubDistrictRecordSuccess = (newSubDistrict) => ({
  type: CREATE_SUB_DISTRICT_RECORD.SUCCESS,
  payload: newSubDistrict
})

export const createSubDistrictRecordError = (error) => ({
  type: CREATE_SUB_DISTRICT_RECORD.ERROR,
  payload: { error }
})

export const createSubDistrictRecord = (payload) => {
  return (dispatch) => {
    dispatch(createSubDistrictRecordStart(payload))
    subDistrictService
      .createSubDistrictRecord(payload)
      .then((subDistrictData) => {
        if (!subDistrictData.isError) {
          dispatch(createSubDistrictRecordSuccess(subDistrictData))
        } else {
          dispatch(createSubDistrictRecordError(subDistrictData))
        }
      })
  }
}

export const updateSubDistrictRecordStart = (payload, id) => ({
  type: UPDATE_SUB_DISTRICT_RECORD.START,
  payload,
  id
})

export const updateSubDistrictRecordSuccess = (newSubDistrict) => ({
  type: UPDATE_SUB_DISTRICT_RECORD.SUCCESS,
  payload: newSubDistrict
})

export const updateSubDistrictRecordError = (error) => ({
  type: UPDATE_SUB_DISTRICT_RECORD.ERROR,
  payload: { error }
})

export const updateSubDistrictRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateSubDistrictRecordStart(payload, id))
    subDistrictService
      .updateSubDistrictRecord(payload, id)
      .then((subDistrictData) => {
        if (!subDistrictData.isError) {
          dispatch(updateSubDistrictRecordSuccess(subDistrictData))
        } else {
          dispatch(updateSubDistrictRecordError(subDistrictData))
        }
      })
  }
}

export const deleteSubDistrictStart = () => ({
  type: DELETE_SUB_DISTRICT.START
})

export const deleteSubDistrictSuccess = (subDistrictId) => ({
  type: DELETE_SUB_DISTRICT.SUCCESS,
  payload: { subDistrictId }
})

export const deleteSubDistrictError = (error) => ({
  type: DELETE_SUB_DISTRICT.ERROR,
  payload: { error }
})

export const deleteSubDistrict = (subDistrictId) => {
  return (dispatch) => {
    dispatch(deleteSubDistrictStart())
    subDistrictService
      .deleteSubDistrict(subDistrictId)
      .then((subDistrictData) => {
        if (!subDistrictData.isError) {
          dispatch(deleteSubDistrictSuccess(subDistrictData))
        } else {
          dispatch(deleteSubDistrictError(subDistrictData))
        }
      })
  }
}

export const getSubDistrictCountStart = (role, payload) => {
  return {
    type: GET_SUB_DISTRICT_COUNT.START,
    role,
    payload
  }
}
export const getSubDistrictCountSuccess = (subDistrictData) => {
  return {
    type: GET_SUB_DISTRICT_COUNT.SUCCESS,
    payload: subDistrictData
  }
}
export const getSubDistrictCountError = (error) => {
  return {
    type: GET_SUB_DISTRICT_COUNT.ERROR,
    payload: error
  }
}

export const getSubDistrictCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getSubDistrictCountStart(role, payload))
    subDistrictService
      .getSubDistrictCount(role, payload)
      .then((subDistrictData) => {
        if (!subDistrictData.isError) {
          dispatch(getSubDistrictCountSuccess(subDistrictData))
        } else {
          dispatch(getSubDistrictCountError(subDistrictData))
        }
      })
  }
}
