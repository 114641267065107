export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'Tenant',
    type: 'dropdown',
    rules: {
      required: 'Tenant is required'
    },
    options: [],
    placeholder: 'Select tenant'
  },

  PROVINCE: {
    name: 'state',
    label: 'State',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'State is required'
    },
    options: [],
    placeholder: 'Select a state'
  },
  DISTRICT: {
    name: 'district',
    label: 'District',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'District is required'
    },
    options: [],
    placeholder: 'Select a district'
  },
  SUB_DISTRICT: {
    name: 'taluka',
    label: 'SubDistrict',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'SubDistrict is required'
    },
    options: [],
    placeholder: 'Select a subdistrict'
  },
  VILLAGE: {
    name: 'village',
    label: 'Village',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'Village is required'
    },
    options: [],
    placeholder: 'Select a Village'
  },
  FILE: {
    name: 'file',
    label: 'KML file',
    type: 'file',
    placeholder: 'Upload a KML file',
    rules: {
      required: 'KML file Photo is required'
    }
  }
}
