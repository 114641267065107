import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from '../constant'
import MzInput from '../../../common/MzForm/MzInput'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { useNavigate, useParams } from 'react-router-dom'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import MzPhoneInput from '../../../common/MzForm/MzPhoneInput'
import { Fieldset } from 'primereact/fieldset'
import { ROUTE_PATH } from '../../../constant/urlConstant'

const AddUserComponent = (props) => {
  const {
    createUserRecord,
    formFieldValueMap,
    isLoading,
    isCreateUserSuccess,
    isEditUserSuccess,
    isUserDetailSuccess,
    isPageLevelError,
    isEdit,
    userRole,
    fetchUserRecord,
    navigatetoUser,
    updateUserRecord
  } = props.addUserProps
 
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const history = useNavigate()
    
  const { id } = useParams()
 
  useEffect(() => {
    if (isCreateUserSuccess || isEditUserSuccess) {
      setTimeout(() => {
        reset()
        navigatetoUser()
      }, 1000)
    }
    // eslint-disable-next-line
  }, [isCreateUserSuccess, isEditUserSuccess])
   
  useEffect(() => {
    if (isUserDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
    // eslint-disable-next-line
  }, [isUserDetailSuccess])

 
  

  useEffect(() => {
    if (isEdit && id) {
      fetchUserRecord(id)
    } else {
      reset()
    }
    // eslint-disable-next-line
  }, [isEdit, id])
  // eslint-disable-next-line
  const [generatedPassword, setGeneratedPassword] = useState('')

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }

  const onSubmit = (data) => {
    let payload = {
      name: data?.name ?? '',
      email: data?.email ?? '',
      authorities: [{ name: data?.authorities ?? '' }],
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      mobile: data?.mobile ?? ''
    }
   

    if (isEdit) {
      payload.id = data.id
      payload.active = true
      // payload.authorities=[{name:''}]
      // payload.authorities= [{ name: data?.authorities ?? "" }];
    }

    if (!isEdit) {
      // payload.authorities= [{ name: data?.authorities ?? "" }];
      payload.password = data?.password ?? ''
    }

    if (isEdit) {
      updateUserRecord(payload, data.id)
    } else {
      createUserRecord(payload)
    }
  }
 
  const generateRandomPassword = () => {
    const length = 8
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      result += charset[randomIndex]
    }
    return result
  }

  const handleGeneratePassword = (e) => {
    e.preventDefault();
    const password = generateRandomPassword()
    setGeneratedPassword(password)
    setValue(FORM_FIELDS_NAME.PASSWORD.name, password)
  }

  useEffect(() => {
    const emailValue = watch(FORM_FIELDS_NAME.EMAIL.name)
    if (emailValue) {
      setValue(FORM_FIELDS_NAME.USERNAME.name, emailValue)
    }
  }, [setValue, watch])

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'User', command: () => history(ROUTE_PATH.USER.HOME) },
      {
        label: isEdit ? 'Update User' : 'Add User',
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.USER.EDIT.replace(':id', id)}`
              : ROUTE_PATH.USER.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }
      
    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb
          model={breadCrumbItems}
          home={home}
          className=" border-0 "
        />
      </div>
    )
  }
  
  const getAuthoritiesLabel = (value) => {
     const  selectedOption = FORM_FIELDS_NAME.AUTHORITIES.options.find(
      (option) => option.value === value
    )
   return selectedOption ? selectedOption.name : ''
  }
  
    
    
  return (
    <div className="">
      {renderPageHeaderSection()}

      <div className="flex w-full bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0  ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-2 w-full px-4 md:mt-5"
        >
          <div className="card mt-3">
            <Fieldset legend="Basic Details">
              <div className="grid">
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.FIRST_NAME.name}
                    label={FORM_FIELDS_NAME.FIRST_NAME.label}
                    placeholder={FORM_FIELDS_NAME.FIRST_NAME.placeholder}
                    type={FORM_FIELDS_NAME.FIRST_NAME.type}
                    isError={errors[FORM_FIELDS_NAME.FIRST_NAME.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.FIRST_NAME.name
                    )}
                    rules={FORM_FIELDS_NAME.FIRST_NAME.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.LAST_NAME.name}
                    label={FORM_FIELDS_NAME.LAST_NAME.label}
                    placeholder={FORM_FIELDS_NAME.LAST_NAME.placeholder}
                    type={FORM_FIELDS_NAME.LAST_NAME.type}
                    isError={errors[FORM_FIELDS_NAME.LAST_NAME.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.LAST_NAME.name
                    )}
                    rules={FORM_FIELDS_NAME.LAST_NAME.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzPhoneInput
                    control={control}
                    name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                    label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                    placeholder={FORM_FIELDS_NAME.PHONE_NUMBER.placeholder}
                    rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                    isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.PHONE_NUMBER.name
                    )}
                    country="in"
                  />
                </div>
                <div className="  col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.EMAIL.name}
                    label={FORM_FIELDS_NAME.EMAIL.label}
                    placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                    type={FORM_FIELDS_NAME.EMAIL.type}
                    isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.EMAIL.name)}
                    rules={FORM_FIELDS_NAME.EMAIL.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
              </div>
            </Fieldset>
          </div>

          <div className="card mt-3">
            <Fieldset legend="Login Details">
              <div className="grid">
                <div className="  col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.USERNAME.name}
                    label={FORM_FIELDS_NAME.USERNAME.label}
                    placeholder={watch(FORM_FIELDS_NAME.EMAIL.name)}
                    type={FORM_FIELDS_NAME.USERNAME.type}
                    isError={errors[FORM_FIELDS_NAME.USERNAME.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.USERNAME.name
                    )}
                    rules={FORM_FIELDS_NAME.USERNAME.rules}
                    wrapperClass={'p-float-label'}
                    disabled={true}
                  />
                </div>
                {!isEdit && (
                  <div className="  col-12  md:col-6">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.PASSWORD.name}
                      label={FORM_FIELDS_NAME.PASSWORD.label}
                      placeholder={FORM_FIELDS_NAME.PASSWORD.placeholder}
                      type={FORM_FIELDS_NAME.PASSWORD.type}
                      isError={errors[FORM_FIELDS_NAME.PASSWORD.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.PASSWORD.name
                      )}
                      rules={FORM_FIELDS_NAME.PASSWORD.rules}
                      wrapperClass={'p-float-label'}
                      disabled={isEdit}
                    />
                    <Button
                      label="Generate Password"
                      className="p-button-rounded p-button-outlined p-button-text"
                      onClick={(e) => handleGeneratePassword(e)}
                    />
                  </div>
                )}
                
                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.AUTHORITIES.name}
                    label={FORM_FIELDS_NAME.AUTHORITIES.label}
                    optionLabel={'name'}
                    optionValue={'value'}
                    placeholder={FORM_FIELDS_NAME.AUTHORITIES.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.AUTHORITIES.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.AUTHORITIES.name
                    )}
                    rules={FORM_FIELDS_NAME.AUTHORITIES.rules}
                    suggestions={
                      userRole === USER_ROLE.SUPER_ADMIN
                        ? FORM_FIELDS_NAME.AUTHORITIES.options
                        : FORM_FIELDS_NAME.AUTHORITIES.options1
                    }
                    value={getAuthoritiesLabel(
                      getValues(FORM_FIELDS_NAME.AUTHORITIES.name)
                    )}
                    dropdown
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          <div className="grid justify-content-center mt-3 mb-5">
            <div className=" col-12 md:col-4">
              <Button
                label="Cancel"
                className="w-full"
                severity="secondary"
                onClick={() => navigatetoUser()}
                disabled={isLoading || isPageLevelError}
              ></Button>
            </div>
            <div className=" col-12 md:col-4">
              <Button
                type="submit"
                label="Submit"
                className="w-full"
                disabled={isLoading || isPageLevelError}
              ></Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddUserComponent
