import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import GMapScreen from '../gMapScreen'
import { useDispatch } from 'react-redux'
import { fetchGatNoRecordByUrl } from '../../redux/action/gatNo'

const LocationScreen = () => {
  const { id } = useParams()
  const [previousId, setPreviousId] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (id && id !== previousId) {
      setPreviousId(id)
      dispatch(fetchGatNoRecordByUrl(id))
    }
    // eslint-disable-next-line
  }, [dispatch, id, previousId])

  return <GMapScreen />
}

export default LocationScreen
