import { INIT_LOGIN, LOGIN, LOGOUT } from '../../../constant/actionTypes/auth'

const formFieldValueMap = {
  email: '',
  password: ''
  // rememberMe: false,
}

const getInitialStateFromLocalStorage = () => {
  const isLoggedIn = localStorage.getItem('isLoggedIn')
  const userRole = localStorage.getItem('userRole')
  return {
    isLoggedIn: isLoggedIn === 'true' ? true : false,
    userRole: userRole ? userRole : '',
    formFieldValueMap,
    error: '',
    isLoading: false,
    isPageLevelError: false,
    isLoadingPage: false,
    isLoginSuccess: false,
    isLoginError: false,
    isLogoutSuccess: false,
    isLogoutError: false
  }
}

const initialState = getInitialStateFromLocalStorage()

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOGIN:
      return {
        ...state
      }
    case LOGIN.START:
    case LOGOUT.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        isLoginSuccess: false,
        isLoginError: false,
        isLoadingPage: false,
        isLogoutSuccess: false,
        isLogoutError: false
      }
    case LOGIN.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoginSuccess: true,
        isLoginError: false,
        isLoggedIn: true,
        userRole: action.payload,
        isLogoutSuccess: false,
        isLogoutError: false
      }
    case LOGIN.ERROR:
    case LOGOUT.ERROR:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        isLoginSuccess: false,
        isLoginError: true,
        error: action.payload.error
      }

    case LOGOUT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        isLogoutSuccess: true,
        iisLogoutError: false
      }

    default: {
      return state
    }
  }
}

export default loginReducer
