export const INIT_LINK = 'INIT_LINK'

export const FETCH_LINK_LIST = {
  START: 'FETCH_LINK_START',
  SUCCESS: 'FETCH_LINK_SUCCESS',
  ERROR: 'FETCH_LINK_ERROR'
}

export const FETCH_LINK_RECORD = {
  START: 'FETCH_LINK_RECORD_START',
  SUCCESS: 'FETCH_LINK_RECORD_SUCCESS',
  ERROR: 'FETCH_LINK_RECORD_ERROR'
}

export const FETCH_ALL_LINK_USER = {
  START: 'FETCH_ALL_LINK_USER_START',
  SUCCESS: 'FETCH_ALL_LINK_USER_SUCCESS',
  ERROR: 'FETCH_ALL_LINK_USER_ERROR'
}
export const CREATE_LINK_RECORD = {
  START: 'CREATE_LINK_RECORD_START',
  SUCCESS: 'CREATE_LINK_RECORD_SUCCESS',
  ERROR: 'CREATE_LINK_RECORD_ERROR'
}

export const UPDATE_LINK_RECORD = {
  START: 'UPDATE_LINK_RECORD_START',
  SUCCESS: 'UPDATE_LINK_RECORD_SUCCESS',
  ERROR: 'UPDATE_LINK_RECORD_ERROR'
}

export const DELETE_LINK = {
  START: 'DELETE_LINK_START',
  SUCCESS: 'DELETE_LINK_SUCCESS',
  ERROR: 'DELETE_LINK_ERROR'
}

export const GET_LINK_COUNT = {
  START: 'GET_LINK_COUNT_START',
  SUCCESS: 'GET_LINK_COUNT_SUCCESS',
  ERROR: 'GET_LINK_COUNT_ERROR'
}
