import {
  INIT_DISTRICT,
  FETCH_DISTRICT_LIST,
  FETCH_DISTRICT_RECORD,
  CREATE_DISTRICT_RECORD,
  DELETE_DISTRICT,
  UPDATE_DISTRICT_RECORD,
  GET_DISTRICT_COUNT
} from '../../../constant/actionTypes/district'
import { districtService } from '../../../services'

export const initDistrict = () => ({
  type: INIT_DISTRICT
})

export const fetchDistrictListStart = () => ({
  type: FETCH_DISTRICT_LIST.START
})

export const fetchDistrictListSuccess = (districts) => ({
  type: FETCH_DISTRICT_LIST.SUCCESS,
  payload: districts
})

export const fetchDistrictListError = (error) => ({
  type: FETCH_DISTRICT_LIST.ERROR,
  payload: { error }
})

export const fetchDistrictList = (payload) => {
  return (dispatch) => {
    dispatch(fetchDistrictListStart(payload))
    districtService.fetchDistrictList(payload).then((districtData) => {
      if (!districtData.isError) {
        dispatch(fetchDistrictListSuccess(districtData))
      } else {
        dispatch(fetchDistrictListError(districtData))
      }
    })
  }
}

export const fetchDistrictRecordStart = () => ({
  type: FETCH_DISTRICT_RECORD.START
})

export const fetchDistrictRecordSuccess = (district) => ({
  type: FETCH_DISTRICT_RECORD.SUCCESS,
  payload: district
})

export const fetchDistrictRecordError = (error) => ({
  type: FETCH_DISTRICT_RECORD.ERROR,
  payload: { error }
})

export const fetchDistrictRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchDistrictRecordStart(payload))
    districtService.fetchDistrictRecord(payload).then((districtData) => {
      if (!districtData.isError) {
        dispatch(fetchDistrictRecordSuccess(districtData))
      } else {
        dispatch(fetchDistrictRecordError(districtData))
      }
    })
  }
}

export const createDistrictRecordStart = () => ({
  type: CREATE_DISTRICT_RECORD.START
})

export const createDistrictRecordSuccess = (newDistrict) => ({
  type: CREATE_DISTRICT_RECORD.SUCCESS,
  payload: newDistrict
})

export const createDistrictRecordError = (error) => ({
  type: CREATE_DISTRICT_RECORD.ERROR,
  payload: { error }
})

export const createDistrictRecord = (payload) => {
  return (dispatch) => {
    dispatch(createDistrictRecordStart(payload))
    districtService.createDistrictRecord(payload).then((districtData) => {
      if (!districtData.isError) {
        dispatch(createDistrictRecordSuccess(districtData))
      } else {
        dispatch(createDistrictRecordError(districtData))
      }
    })
  }
}

export const updateDistrictRecordStart = (payload, id) => ({
  type: UPDATE_DISTRICT_RECORD.START,
  payload,
  id
})

export const updateDistrictRecordSuccess = (newDistrict) => ({
  type: UPDATE_DISTRICT_RECORD.SUCCESS,
  payload: newDistrict
})

export const updateDistrictRecordError = (error) => ({
  type: UPDATE_DISTRICT_RECORD.ERROR,
  payload: { error }
})

export const updateDistrictRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateDistrictRecordStart(payload, id))
    districtService.updateDistrictRecord(payload, id).then((districtData) => {
      if (!districtData.isError) {
        dispatch(updateDistrictRecordSuccess(districtData))
      } else {
        dispatch(updateDistrictRecordError(districtData))
      }
    })
  }
}

export const deleteDistrictStart = () => ({
  type: DELETE_DISTRICT.START
})

export const deleteDistrictSuccess = (districtId) => ({
  type: DELETE_DISTRICT.SUCCESS,
  payload: { districtId }
})

export const deleteDistrictError = (error) => ({
  type: DELETE_DISTRICT.ERROR,
  payload: { error }
})

export const deleteDistrict = (districtId) => {
  return (dispatch) => {
    dispatch(deleteDistrictStart())
    districtService.deleteDistrict(districtId).then((districtData) => {
      if (!districtData.isError) {
        dispatch(deleteDistrictSuccess(districtData))
      } else {
        dispatch(deleteDistrictError(districtData))
      }
    })
  }
}

export const getDistrictCountStart = (role, payload) => {
  return {
    type: GET_DISTRICT_COUNT.START,
    role,
    payload
  }
}
export const getDistrictCountSuccess = (districtData) => {
  return {
    type: GET_DISTRICT_COUNT.SUCCESS,
    payload: districtData
  }
}
export const getDistrictCountError = (error) => {
  return {
    type: GET_DISTRICT_COUNT.ERROR,
    payload: error
  }
}

export const getDistrictCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getDistrictCountStart(role, payload))
    districtService.getDistrictCount(role, payload).then((districtData) => {
      if (!districtData.isError) {
        dispatch(getDistrictCountSuccess(districtData))
      } else {
        dispatch(getDistrictCountError(districtData))
      }
    })
  }
}
