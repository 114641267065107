import {
  INIT_LINK,
  FETCH_LINK_LIST,
  FETCH_LINK_RECORD,
  FETCH_ALL_LINK_USER,
  CREATE_LINK_RECORD,
  DELETE_LINK,
  UPDATE_LINK_RECORD,
  GET_LINK_COUNT
} from '../../../constant/actionTypes/link'
import { linkService } from '../../../services'

export const initLink = () => ({
  type: INIT_LINK
})

export const fetchLinkListStart = () => ({
  type: FETCH_LINK_LIST.START
})

export const fetchLinkListSuccess = (links) => ({
  type: FETCH_LINK_LIST.SUCCESS,
  payload: links
})

export const fetchLinkListError = (error) => ({
  type: FETCH_LINK_LIST.ERROR,
  payload: { error }
})

export const fetchLinkList = (payload) => {
  return (dispatch) => {
    dispatch(fetchLinkListStart(payload))
    linkService.fetchLinkList(payload).then((linkData) => {
      if (!linkData.isError) {
        dispatch(fetchLinkListSuccess(linkData))
      } else {
        dispatch(fetchLinkListError(linkData))
      }
    })
  }
}


export const fetchAllLinkByUserStart = () => ({
  type: FETCH_ALL_LINK_USER.START
})

export const fetchAllLinkByUserSuccess = (link) => ({
  type: FETCH_ALL_LINK_USER.SUCCESS,
  payload: link
})

export const fetchAllLinkByUserError = (error) => ({
  type: FETCH_ALL_LINK_USER.ERROR,
  payload: { error }
})

export const fetchLinkRecordStart = () => ({
  type: FETCH_LINK_RECORD.START
})

export const fetchLinkRecordSuccess = (link) => ({
  type: FETCH_LINK_RECORD.SUCCESS,
  payload: link
})

export const fetchLinkRecordError = (error) => ({
  type: FETCH_LINK_RECORD.ERROR,
  payload: { error }
})

export const fetchAllLinkByUser = (payload) => {
  return (dispatch) => {
    dispatch(fetchAllLinkByUserStart(payload))
    linkService.fetchAllLinkByUser(payload).then((linkData) => {
      if (!linkData.isError) {
        dispatch(fetchAllLinkByUserSuccess(linkData))
      } else {
        dispatch(fetchAllLinkByUserError(linkData))
      }
    })
  }
}

export const fetchLinkRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchLinkRecordStart(payload))
    linkService.fetchLinkRecord(payload).then((linkData) => {
      if (!linkData.isError) {
        dispatch(fetchLinkRecordSuccess(linkData))
      } else {
        dispatch(fetchLinkRecordError(linkData))
      }
    })
  }
}

export const createLinkRecordStart = () => ({
  type: CREATE_LINK_RECORD.START
})

export const createLinkRecordSuccess = (newLink) => ({
  type: CREATE_LINK_RECORD.SUCCESS,
  payload: newLink
})

export const createLinkRecordError = (error) => ({
  type: CREATE_LINK_RECORD.ERROR,
  payload: { error }
})

export const createLinkRecord = (payload) => {
  return (dispatch) => {
    dispatch(createLinkRecordStart(payload))
    linkService.createLinkRecord(payload).then((linkData) => {
      if (!linkData.isError) {
        dispatch(createLinkRecordSuccess(linkData))
      } else {
        dispatch(createLinkRecordError(linkData))
      }
    })
  }
}

export const updateLinkRecordStart = (payload, id) => ({
  type: UPDATE_LINK_RECORD.START,
  payload,
  id
})

export const updateLinkRecordSuccess = (newLink) => ({
  type: UPDATE_LINK_RECORD.SUCCESS,
  payload: newLink
})

export const updateLinkRecordError = (error) => ({
  type: UPDATE_LINK_RECORD.ERROR,
  payload: { error }
})

export const updateLinkRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateLinkRecordStart(payload, id))
    linkService.updateLinkRecord(payload, id).then((linkData) => {
      if (!linkData.isError) {
        dispatch(updateLinkRecordSuccess(linkData))
      } else {
        dispatch(updateLinkRecordError(linkData))
      }
    })
  }
}


export const updatePlotRecordStart = (payload, id) => ({
  type: UPDATE_LINK_RECORD.START,
  payload,
  id
})

export const updatePlotRecordSuccess = (newLink) => ({
  type: UPDATE_LINK_RECORD.SUCCESS,
  payload: newLink
})

export const updatePlotRecordError = (error) => ({
  type: UPDATE_LINK_RECORD.ERROR,
  payload: { error }
})

export const updatePlotRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updatePlotRecordStart(payload, id))
    linkService.updatePlotRecord(payload, id).then((linkData) => {
      if (!linkData.isError) {
        dispatch(updatePlotRecordSuccess(linkData))
      } else {
        dispatch(updatePlotRecordError(linkData))
      }
    })
  }
}

export const deleteLinkStart = () => ({
  type: DELETE_LINK.START
})

export const deleteLinkSuccess = (linkId) => ({
  type: DELETE_LINK.SUCCESS,
  payload: { linkId }
})

export const deleteLinkError = (error) => ({
  type: DELETE_LINK.ERROR,
  payload: { error }
})

export const deleteLink = (linkId) => {
  return (dispatch) => {
    dispatch(deleteLinkStart())
    linkService.deleteLink(linkId).then((linkData) => {
      if (!linkData.isError) {
        dispatch(deleteLinkSuccess(linkData))
      } else {
        dispatch(deleteLinkError(linkData))
      }
    })
  }
}

export const getLinkCountStart = (role, payload) => {
  return {
    type: GET_LINK_COUNT.START,
    role,
    payload
  }
}
export const getLinkCountSuccess = (linkData) => {
  return {
    type: GET_LINK_COUNT.SUCCESS,
    payload: linkData
  }
}
export const getLinkCountError = (error) => {
  return {
    type: GET_LINK_COUNT.ERROR,
    payload: error
  }
}

export const getLinkCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getLinkCountStart(role, payload))
    linkService.getLinkCount(role, payload).then((linkData) => {
      if (!linkData.isError) {
        dispatch(getLinkCountSuccess(linkData))
      } else {
        dispatch(getLinkCountError(linkData))
      }
    })
  }
}
