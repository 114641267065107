import {
  INIT_ACCESS,
  FETCH_ACCESS_LIST,
  FETCH_ACCESS_RECORD,
  CREATE_ACCESS_RECORD,
  DELETE_ACCESS,
  UPDATE_ACCESS_RECORD,
  GET_COUNT_ACCESS
} from '../../../constant/actionTypes/access'
import { accessService } from '../../../services'

export const initAccess = () => ({
  type: INIT_ACCESS
})

export const fetchAccessListStart = () => ({
  type: FETCH_ACCESS_LIST.START
})

export const fetchAccessListSuccess = (access) => ({
  type: FETCH_ACCESS_LIST.SUCCESS,
  payload: access
})

export const fetchAccessListError = (error) => ({
  type: FETCH_ACCESS_LIST.ERROR,
  payload: { error }
})

export const fetchAccessList = (payload) => {
  return (dispatch) => {
    dispatch(fetchAccessListStart(payload))
    accessService.fetchAccessList(payload).then((accessData) => {
      if (!accessData.isError) {
        dispatch(fetchAccessListSuccess(accessData))
      } else {
        dispatch(fetchAccessListError(accessData))
      }
    })
  }
}

export const fetchAccessRecordStart = () => ({
  type: FETCH_ACCESS_RECORD.START
})

export const fetchAccessRecordSuccess = (access) => ({
  type: FETCH_ACCESS_RECORD.SUCCESS,
  payload: access
})

export const fetchAccessRecordError = (error) => ({
  type: FETCH_ACCESS_RECORD.ERROR,
  payload: { error }
})

export const fetchAccessRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchAccessRecordStart(payload))
    accessService.fetchAccessRecord(payload).then((accessData) => {
      if (!accessData.isError) {
        dispatch(fetchAccessRecordSuccess(accessData))
      } else {
        dispatch(fetchAccessRecordError(accessData))
      }
    })
  }
}

export const createAccessRecordStart = () => ({
  type: CREATE_ACCESS_RECORD.START
})

export const createAccessRecordSuccess = (newAccess) => ({
  type: CREATE_ACCESS_RECORD.SUCCESS,
  payload: newAccess
})

export const createAccessRecordError = (error) => ({
  type: CREATE_ACCESS_RECORD.ERROR,
  payload: { error }
})

export const createAccessRecord = (payload) => {
  return (dispatch) => {
    dispatch(createAccessRecordStart(payload))
    accessService.createAccessRecord(payload).then((accessData) => {
      if (!accessData.isError) {
        dispatch(createAccessRecordSuccess(accessData))
      } else {
        dispatch(createAccessRecordError(accessData))
      }
    })
  }
}

export const updateAccessRecordStart = (payload, id) => ({
  type: UPDATE_ACCESS_RECORD.START,
  payload,
  id
})

export const updateAccessRecordSuccess = (newAccess) => ({
  type: UPDATE_ACCESS_RECORD.SUCCESS,
  payload: newAccess
})

export const updateAccessRecordError = (error) => ({
  type: UPDATE_ACCESS_RECORD.ERROR,
  payload: { error }
})

export const updateAccessRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateAccessRecordStart(payload, id))
    accessService.updateAccessRecord(payload, id).then((accessData) => {
      if (!accessData.isError) {
        dispatch(updateAccessRecordSuccess(accessData))
      } else {
        dispatch(updateAccessRecordError(accessData))
      }
    })
  }
}

export const deleteAccessStart = () => ({
  type: DELETE_ACCESS.START
})

export const deleteAccessSuccess = (accessId) => ({
  type: DELETE_ACCESS.SUCCESS,
  payload: { accessId }
})

export const deleteAccessError = (error) => ({
  type: DELETE_ACCESS.ERROR,
  payload: { error }
})

export const deleteAccess = (accessId) => {
  return (dispatch) => {
    dispatch(deleteAccessStart())
    accessService.deleteAccess(accessId).then((accessData) => {
      if (!accessData.isError) {
        dispatch(deleteAccessSuccess(accessData))
      } else {
        dispatch(deleteAccessError(accessData))
      }
    })
  }
}

export const getAccessCountStart = (role, payload) => {
  return {
    type: GET_COUNT_ACCESS.START,
    role,
    payload
  }
}
export const getAccessCountSuccess = (accessData) => {
  return {
    type: GET_COUNT_ACCESS.SUCCESS,
    payload: accessData
  }
}
export const getAccessCountError = (error) => {
  return {
    type: GET_COUNT_ACCESS.ERROR,
    payload: error
  }
}

export const getAccessCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getAccessCountStart(role, payload))
    accessService.getAccessCount(role, payload).then((accessData) => {
      if (!accessData.isError) {
        dispatch(getAccessCountSuccess(accessData))
      } else {
        dispatch(getAccessCountError(accessData))
      }
    })
  }
}
