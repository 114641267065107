import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
// import { USER_ROLE } from "../../constant/actionTypes/role";

export const fetchPlanList = async () => {
  const url = `${baseUrl}${API_PATH.PAYMENT.FETCH_PLAN}`
  //   const token = localStorage.getItem("token");
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${token}`,
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data ?? []
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}
