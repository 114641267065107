export const INIT_CONTACTUS = 'INIT_CONTACTUS'

export const FETCH_CONTACTUS_LIST = {
  START: 'FETCH_CONTACTUS_START',
  SUCCESS: 'FETCH_CONTACTUS_SUCCESS',
  ERROR: 'FETCH_CONTACTUS_ERROR'
}

export const CREATE_CONTACTUS_RECORD = {
  START: 'CREATE_CONTACTUS_RECORD_START',
  SUCCESS: 'CREATE_CONTACTUS_RECORD_SUCCESS',
  ERROR: 'CREATE_CONTACTUS_RECORD_ERROR'
}
