import { combineReducers } from 'redux'
import userProfileReducer from './userProfile'
import loginReducer from './auth/login'
import registerReducer from './auth/register'
import kmlUploadReducer from './kmlUpload'
import toastReducer from './toast'
import userReducer from './user'
import provinceReducer from './province'
import districtReducer from './district'
import subDistrictReducer from './subDistrict'
import villageReducer from './village'
import accessReducer from './access'
import linkReducer from './link'
import gatNoReducer from './gatNo'
import paymentReducer from './payment'
import contactUsReducer from './contactUs'
const rootReducers = combineReducers({
  userProfileReducer,
  loginReducer,
  registerReducer,
  toastReducer,
  kmlUploadReducer,
  userReducer,
  provinceReducer,
  districtReducer,
  subDistrictReducer,
  villageReducer,
  accessReducer,
  linkReducer,
  gatNoReducer,
  paymentReducer,
  contactUsReducer
})
export default rootReducers
