import { Dialog } from 'primereact/dialog'

const MzDialog = (props) => {
  const {
    children,
    header,
    width,
    height,
    onHide,
    visible,
    isLoading,
    footer
  } = props

  const splitLength = (length) => {
    const index = length.split('').findIndex((char) => isNaN(char))
    return [length.substring(0, index), length.substring(index)]
  }

  const calculateHeightAndWidth = (dialogHeight, dialogWidth) => {
    const [heightNumber, heightUnit] = splitLength(dialogHeight)
    const [widthNumber, widthUnit] = splitLength(dialogWidth)
    return {
      dialogChildHeight: (heightNumber - 40).toLocaleString() + heightUnit,
      dialogChildWidth: (widthNumber - 10).toLocaleString() + widthUnit
    }
  }

  const { dialogChildHeight, dialogChildWidth } = calculateHeightAndWidth(
    height,
    width
  )

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={header}
      contentStyle={{ height }}
      // style={{ backgroundColor:"" }}
      // maximizable={maximizable}
      footer={footer}
      draggable={false}
    >
      {isLoading && (
        <div
          style={{
            display: 'flex',
            height: dialogChildHeight,
            width: dialogChildWidth,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <EzProgressBar /> */}
        </div>
      )}
      <div> {children}</div>
    </Dialog>
  )
}

MzDialog.defaultProps = {
  height: '700px',
  width: '50vw',
  maximizable: true,
  visible: true,
  isLoading: false,
  footer: (
    <div className="text-xs  text-center py-2">
      &#169; Bhoomi22.com 2024
    </div>
  )
}

export default MzDialog
