import { useForm } from 'react-hook-form'
import MzInput from '../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constant'
import { baseUrl } from '../../services/PostAPI'
import { Button } from 'primereact/button'
import { useRef } from 'react'
import { Toast } from 'primereact/toast';
import axios from 'axios'
import { LOGO } from '../../assets/images'
const ForgotPasswordComponent = (props) => {
    const {
        openForgotPasswordDialog,
        setVisibleLogin
      } = props.forgotpasswordProps
    const toast = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({

        mode: 'onChange',
        reValidateMode: 'onChange'
    })
    const onSubmit = async (data) => {
        try {
            const response = await axios.post(`${baseUrl}/forgot-password`, {
                email: data.email
            })
            if (response?.status === 200) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Password reset link has been sent to your email.',
                    life: 3000
                });
                reset();
                setTimeout(() => {
                    openForgotPasswordDialog(false);
                    setVisibleLogin(false);
                }, 2000);
            } else {
                toast?.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to send reset link',
                    life: 3000
                });
            }
        } catch (error) {
            if (error?.response && error?.response?.data) {
                const errorMessage = error?.response?.data?.message || 'There was an error sending the reset link. Please try again.';
                  toast?.current?.show({
                   severity: 'error',
                   summary: 'Error',
                   detail: errorMessage,  
                   life: 3000,
                 });
               }
            else{
            toast?.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'There was an error sending the reset link. Please try again.',
                life: 3000
            });
            }
        }

    }
    const getFormErrorMessage = (name) => {
        return (
            errors[name] && <small className="p-error">{errors[name].message}</small>
        )
    }
    return (
        <>
            <Toast ref={toast} />

            <div className="surface-grund flex align-items-center justify-content-center max-h-screen  max-w-screen overflow-hidden">
                <div className="flex flex-column align-items-center justify-content-center p-3">
                    <div
                        className="flex justify-content-center align-items-center bg-green-400 border-circle"
                        style={{ height: '3.2rem', width: '3.2rem' }}
                    >
                        <img src={LOGO} alt="Logo" style={{ height: '4rem', cursor: 'pointer' }} />
                    </div>
                    <h1 className="text-900 font-bold text-3xl md:text-lg mb-2 ">
                        Forgot Password
                    </h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-column text-center  border-round-sm mt-4">
                            <div className="mb-3 w-17rem md:w-20rem">
                                <MzInput
                                    control={control}
                                    name={FORM_FIELDS_NAME.EMAIL.name}
                                    label={FORM_FIELDS_NAME.EMAIL.label}
                                    placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                                    type={FORM_FIELDS_NAME.EMAIL.type}
                                    isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.EMAIL.name)}
                                    rules={FORM_FIELDS_NAME.EMAIL.rules}
                                    wrapperClass={'p-float-label'}
                                />
                            </div>
                            <div className="mb-3">
                                <Button
                                    type="submit"
                                    label="Submit"
                                    className="w-full"
                                ></Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default ForgotPasswordComponent