import React from 'react'
const ShippingPolicy = () => {
  return (
    <div className="page-information-container  md:px-7 mt-8 mb-3 lg:mb-7  mr-4 ml-4  md:mr-6 md:ml-6 lg:mr-7 lg:ml-7">
      <div className="mb-3">
        <h1 className="font-medium text-2xl mt-5">
          
            Shipping and Delivery Policy

        </h1> 

      </div> 

      <p className="line-height-2">
        Effective Date: January 1, 2024 <br />
        At Bhoomi22, we strive to provide timely access to our products and services. This Shipping and Delivery Policy outlines our access procedures.
      </p>

      <h2 className=" page-info-subheading font-medium text-lg">
         1. Access to Services
      </h2> 
      <p className="line-height-2">
         Upon successful completion of payment, access to the purchased digital product or service will be granted immediately.
      </p>   

      <h2 className=" page-info-subheading font-medium text-lg">
         2. Payment Confirmation
      </h2>
      <p className="line-height-2">
        You will receive a confirmation email once your payment has been successfully processed.
        Access details and instructions for using the product or service will be provided in the confirmation email.


      </p> 

      <h2 className=" page-info-subheading font-medium text-lg">
          3. Technical Support
      </h2>
      <p className="line-height-2">
          For any issues related to access, please contact our support team at 
          {'   '}{' '}
              <a href="mailto:support@bhoomi22.com." className="text-blue-500">
              support@bhoomi22.com.
              </a> {' '} 
            We are committed to resolving any access-related queries promptly.


      </p>

      <h2 className=" page-info-subheading font-medium text-lg">4. Contact Us</h2>
      <p className="line-height-2">
      For questions about access, payment confirmation, or technical support, please reach out to us at 
      {'   '}{' '}
          <a href="mailto:support@bhoomi22.com." className="text-blue-500">
          support@bhoomi22.com.
          </a>
      </p>

      

    </div>
  )
}

export default ShippingPolicy
