import React, { useEffect } from 'react'
import HomeComponent from '../../components/home'
import { connect } from 'react-redux'
import { fetchProvinceList } from '../../redux/action/province'
import { fetchDistrictList } from '../../redux/action/district'
import { fetchSubDistrictList } from '../../redux/action/subDistrict'
import { fetchVillageList } from '../../redux/action/village'
import { fetchGatNoList, initGatNo } from '../../redux/action/gatNo'

const HomeScreen = (props) => {
  const {
    isEdit,
    isLoading,
    formFieldValueMap,
    isPageLevelError,
    user,
    userRole,
    error,
    fetchProvinceList,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchGatNoList,
    gatNosList,
    selectedGatNo,
    isLoggedIn,
    isGatNoDetailError,
    initGatNo
  } = props

  const homeProps = {
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    error,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchGatNoList,
    gatNosList,
    selectedGatNo,
    isLoggedIn,
    isGatNoDetailError,
    initGatNo
  }
  useEffect(() => {
    initGatNo()
    fetchProvinceList()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <HomeComponent homeProps={homeProps} />
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    initGatNo: () => dispatch(initGatNo()),
    fetchProvinceList: (payload) => dispatch(fetchProvinceList(payload)),
    fetchDistrictList: (payload) => dispatch(fetchDistrictList(payload)),
    fetchSubDistrictList: (payload) => dispatch(fetchSubDistrictList(payload)),
    fetchVillageList: (payload) => dispatch(fetchVillageList(payload)),
    fetchGatNoList: (payload) => dispatch(fetchGatNoList(payload))

    // fetch list of state,dis to.... village
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,

    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    provincesList: state.provinceReducer.provincesList,
    districtsList: state.districtReducer.districtsList,
    subDistrictsList: state.subDistrictReducer.subDistrictsList,
    villagesList: state.villageReducer.villagesList,
    gatNosList: state.gatNoReducer.gatNosList,
    isLoggedIn: state.loginReducer.isLoggedIn,
    selectedGatNo: state.gatNoReducer.selectedGatNo,
    isGatNoDetailError: state.gatNoReducer.isGatNoDetailError
    // error:state.gatNoReducer.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
