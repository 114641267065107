import {
  INIT_VILLAGE,
  FETCH_VILLAGE_LIST,
  FETCH_VILLAGE_RECORD,
  CREATE_VILLAGE_RECORD,
  DELETE_VILLAGE,
  UPDATE_VILLAGE_RECORD,
  GET_VILLAGE_COUNT
} from '../../../constant/actionTypes/village'

const formFieldValueMap = {
  tenant: '',
  username: '',
  email: '',
  password: '',
  authorities: '',
  department: '',
  profilePicture: null
}

const initialState = {
  villagesList: [],
  villageCount: 0,
  selectedVillage: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateVillageSuccess: false,
  isEditVillageSuccess: false,
  isVillageDetailSuccess: false,
  isDeleteVillageSuccess: false,
  isDeleteVillageError: false,
  isVillageDetailError: false,
  isCreateVillageError: false,
  isEditVillageError: false
}

const villageReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_VILLAGE:
      return {
        ...state,
        formFieldValueMap: null,
        villagesList: [],
        selectedVillage: null,
        isLoading: false,
        error: null
      }
    case FETCH_VILLAGE_LIST.START:
    case FETCH_VILLAGE_RECORD.START:
    case CREATE_VILLAGE_RECORD.START:
    case DELETE_VILLAGE.START:
    case UPDATE_VILLAGE_RECORD.START:
    case GET_VILLAGE_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateVillageSuccess: false,
        isEditVillageSuccess: false,
        isVillageDetailSuccess: false,
        isDeleteVillageSuccess: false,
        isDeleteVillageError: false,
        isVillageDetailError: false,
        isCreateVillageError: false,
        isEditVillageError: false
      }
    case FETCH_VILLAGE_LIST.SUCCESS:
      return {
        ...state,
        villagesList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_VILLAGE_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        villageCount: action.payload
      }
    }
    case GET_VILLAGE_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        villageCount: 0
      }
    }
    case FETCH_VILLAGE_RECORD.SUCCESS:
      return {
        ...state,
        selectedVillage: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          tenant: action?.payload?.tenant?.id ?? '',
          username: action?.payload?.username ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          authorities: action?.payload?.authorities[0]?.name ?? '',
          department: action?.payload?.department?.id ?? '',
          profilePicture: action?.payload?.profilePicture ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobileNumber: action?.payload?.mobileNumber ?? ''
        },
        isLoading: false,
        error: null,
        isVillageDetailSuccess: true
      }
    case CREATE_VILLAGE_RECORD.SUCCESS:
      return {
        ...state,
        villagesList: [...state.villagesList, action.payload],
        isLoading: false,
        error: null,
        isCreateVillageSuccess: true
      }
    case UPDATE_VILLAGE_RECORD.SUCCESS:
      return {
        ...state,
        villagesList: state.villagesList.map((village) =>
          village.id === action.payload.id ? action.payload : village
        ),
        isLoading: false,
        error: null,
        isEditVillageSuccess: true
      }
    case DELETE_VILLAGE.SUCCESS:
      return {
        ...state,
        villagesList: state.villagesList.filter(
          (village) => village.id !== action.payload.villageId
        ),
        isLoading: false,
        error: null,
        isDeleteVillageSuccess: true
      }
    case FETCH_VILLAGE_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_VILLAGE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isVillageDetailError: true
      }
    case CREATE_VILLAGE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateVillageError: true
      }

    case UPDATE_VILLAGE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditVillageError: true
      }
    case DELETE_VILLAGE.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteVillageError: true
      }

    default:
      return state
  }
}

export default villageReducer
