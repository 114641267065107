import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import MzDropDown from '../../../common/MzForm/MzDropDown/WithFloatLabel'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { Fieldset } from 'primereact/fieldset'
import { useState } from 'react'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete/index'
import MzInput from '../../../common/MzForm/MzInput'
const AddLinkComponent = (props) => {
  const {
    createLinkRecord,
    formFieldValueMap,
    isLoading,
    isCreateLinkSuccess,
    isEditLinkSuccess,
    isLinkDetailSuccess,
    isPageLevelError,
    // user,
    isEdit,
    userRole,
    fetchLinkRecord,
    fetchUserList,
    navigatetoLink,
    updateLinkRecord,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchGatNoList,
    gatNosList,
    userList,
  } = props.addLinkProps
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const [query, setQuery] = useState('');
  const history = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query.length > 3 || query === '') {
        const payload = {
          query,
          pageNumber: 0,
          pageSize: 2000,
        };
        fetchUserList(payload);
      }
    }, 300); 
    return () => clearTimeout(delayDebounceFn);
  }, [query, fetchUserList]);


  useEffect(() => {
    if (isCreateLinkSuccess || isEditLinkSuccess) {
      setTimeout(() => {
        reset()
        navigatetoLink()
      }, 1000)
    }
    // eslint-disable-next-line
  }, [isCreateLinkSuccess, isEditLinkSuccess])

  useEffect(() => {
    if (isLinkDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
    // eslint-disable-next-line
  }, [isLinkDetailSuccess])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.PROVINCE.name)) {
      fetchDistrictList(watch(FORM_FIELDS_NAME.PROVINCE.name))
      setValue(FORM_FIELDS_NAME.DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.PROVINCE.name), fetchDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.DISTRICT.name)) {
      fetchSubDistrictList(watch(FORM_FIELDS_NAME.DISTRICT.name))
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.DISTRICT.name), fetchSubDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.SUB_DISTRICT.name)) {
      fetchVillageList(watch(FORM_FIELDS_NAME.SUB_DISTRICT.name))
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.SUB_DISTRICT.name), fetchVillageList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.VILLAGE.name)) {
      fetchGatNoList(watch(FORM_FIELDS_NAME.VILLAGE.name))
      setValue(FORM_FIELDS_NAME.GATNO.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.VILLAGE.name), fetchGatNoList, setValue])

  useEffect(() => {
    if (isEdit && id) {
      fetchLinkRecord(id)
    } else {
      reset(formFieldValueMap)
    }
    // eslint-disable-next-line
  }, [isEdit, id])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }

  const handleDropdownChange = (e) => {
    setQuery(e.value); 
  };
  const onSubmit = (data) => {
    const commonPayload = {
      usageLimit: data?.usageLimit,
      user: {
        id: data.user
      },
      plot: {
        id: data.gatNo
      },
      accessType: 'PLOT_FULLACCESS',
      active: true
    };


    if (isEdit && id) {
      const updatePayload = {
        id: data.id,
        usageLimit: data?.usageLimit
      };
      updateLinkRecord(updatePayload);
    } else {
      createLinkRecord(commonPayload);
    }
  };

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'Link', command: () => history(ROUTE_PATH.LINK.HOME) },
      {
        label: isEdit ? 'Update Link' : 'Add Link',
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.LINK.EDIT.replace(':id', id)}`
              : ROUTE_PATH.LINK.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
      </div>
    )
  }
  return (
    <div>
      {renderPageHeaderSection()}

      <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-2 w-full px-4 md:mt-5"
        >
          <div className="card ">
            <Fieldset legend="Location Details">
              <div className="grid">
                {userRole === USER_ROLE.SUPER_ADMIN && (
                  <div className=" col-12 md:col-6">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.PROVINCE.name}
                      label={FORM_FIELDS_NAME.PROVINCE.label}
                      optionLabel={FORM_FIELDS_NAME.PROVINCE.optionLabel}
                      optionValue={FORM_FIELDS_NAME.PROVINCE.optionValue}
                      placeholder={FORM_FIELDS_NAME.PROVINCE.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.PROVINCE.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.PROVINCE.name
                      )}
                      rules={!isEdit ? FORM_FIELDS_NAME.PROVINCE.rules : {}}
                      suggestions={provincesList ?? []}
                      dropdown
                      value={formFieldValueMap?.state ?? ''}
                      disabled={isEdit}
                    />
                  </div>
                )}
                {userRole === USER_ROLE.SUPER_ADMIN && (
                  <div className=" col-12 md:col-6">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.DISTRICT.name}
                      label={FORM_FIELDS_NAME.DISTRICT.label}
                      optionLabel={FORM_FIELDS_NAME.DISTRICT.optionLabel}
                      optionValue={FORM_FIELDS_NAME.DISTRICT.optionValue}
                      placeholder={FORM_FIELDS_NAME.DISTRICT.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.DISTRICT.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.DISTRICT.name
                      )}
                      rules={!isEdit ? FORM_FIELDS_NAME.DISTRICT.rules : {}}
                      suggestions={districtsList ?? []}
                      dropdown
                      value={formFieldValueMap?.district ?? ''}
                      disabled={isEdit}
                    />
                  </div>
                )}
                {userRole === USER_ROLE.SUPER_ADMIN && (
                  <div className=" col-12 md:col-6">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.SUB_DISTRICT.name}
                      label={FORM_FIELDS_NAME.SUB_DISTRICT.label}
                      optionLabel={FORM_FIELDS_NAME.SUB_DISTRICT.optionLabel}
                      optionValue={FORM_FIELDS_NAME.SUB_DISTRICT.optionValue}
                      placeholder={FORM_FIELDS_NAME.SUB_DISTRICT.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.SUB_DISTRICT.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.SUB_DISTRICT.name
                      )}
                      rules={!isEdit ? FORM_FIELDS_NAME.SUB_DISTRICT.rules : {}}
                      suggestions={subDistrictsList ?? []}
                      dropdown
                      value={formFieldValueMap?.taluka ?? ''}
                      disabled={isEdit}
                    />
                  </div>
                )}

                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.VILLAGE.name}
                    label={FORM_FIELDS_NAME.VILLAGE.label}
                    optionLabel={FORM_FIELDS_NAME.VILLAGE.optionLabel}
                    optionValue={FORM_FIELDS_NAME.VILLAGE.optionValue}
                    placeholder={FORM_FIELDS_NAME.VILLAGE.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.VILLAGE.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.VILLAGE.name
                    )}
                    rules={!isEdit ? FORM_FIELDS_NAME.VILLAGE.rules : {}}
                    suggestions={villagesList ?? []}
                    dropdown
                    value={formFieldValueMap?.village ?? ''}
                    disabled={isEdit}
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.GATNO.name}
                    label={FORM_FIELDS_NAME.GATNO.label}
                    optionLabel={FORM_FIELDS_NAME.GATNO.optionLabel}
                    optionValue={FORM_FIELDS_NAME.GATNO.optionValue}
                    placeholder={FORM_FIELDS_NAME.GATNO.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.GATNO.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.GATNO.name)}
                    rules={!isEdit ? FORM_FIELDS_NAME.GATNO.rules : {}}
                    suggestions={gatNosList ?? []}
                    dropdown
                    value={formFieldValueMap?.plotNumber ?? ''}
                    disabled={isEdit}
                  />
                </div>
              </div>
            </Fieldset>
          </div>

          <div className="card mt-3">
            <Fieldset legend="User Details">
              <div className="grid">
                <div className="col-12 md:col-6">
                 {
                  isEdit ?(
                    <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.USER.name}
                    label={FORM_FIELDS_NAME.USER.label}
                    optionLabel={FORM_FIELDS_NAME.USER.optionLabel}
                    optionValue={FORM_FIELDS_NAME.USER.optionValue}
                    placeholder={FORM_FIELDS_NAME.USER.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.USER.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.USER.name)}
                    rules={!isEdit ? FORM_FIELDS_NAME.USER.rules : {}}
                    suggestions={userList?.content ?? []}
                    dropdown
                    value={formFieldValueMap?.name ?? ''}
                    disabled={isEdit}
                  />
                  ) : (
                    <MzDropDown
                    control={control}
                    filter={true}
                    name={FORM_FIELDS_NAME.USER.name}
                    label={FORM_FIELDS_NAME.USER.label}
                    optionLabel={FORM_FIELDS_NAME.USER.optionLabel}
                    optionValue={FORM_FIELDS_NAME.USER.optionValue}
                    placeholder={FORM_FIELDS_NAME.USER.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.USER.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.USER.name)}
                    rules={!isEdit ? FORM_FIELDS_NAME.USER.rules : {}}
                    options={userList?.content ?? []}  
                    dropdown
                    value={query} 
                    onChange={handleDropdownChange}
                  />
                  )
                 }
                  

                </div>
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.LIMIT.name}
                    label={FORM_FIELDS_NAME.LIMIT.label}
                    placeholder={FORM_FIELDS_NAME.LIMIT.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.LIMIT.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.LIMIT.name
                    )}
                    rules={FORM_FIELDS_NAME.LIMIT.rules}

                  />
                </div>
              </div>
            </Fieldset>
          </div>

          <div className="grid justify-content-center mt-3 mb-5">
            <div className="col-12 md:col-4">
              <Button
                label="Cancel"
                className="w-full"
                severity="secondary"
                onClick={() => navigatetoLink()}
                disabled={isLoading || isPageLevelError}
              />
            </div>
            <div className="col-12 md:col-4">
              <Button
                type="submit"
                label={isEdit ? "Update" : "Submit"}
                className="w-full"
                disabled={isLoading || isPageLevelError}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default AddLinkComponent
