import React, { useEffect } from 'react'
import { ProgressBar } from 'primereact/progressbar'
import RegisterComponent from '../../components/register'
import { initRegisterScreen, register } from '../../redux/action/auth/register'
import { connect } from 'react-redux'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'

const RegisterScreen = (props) => {
  const {
    initRegisterScreen,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isRegisterSuccess,
    isRegisterError,
    error,
    register,
    toggleChange
  } = props

  useEffect(() => {
    initRegisterScreen()
    // eslint-disable-next-line
  }, [])

  const registerProps = {
    formFieldValueMap,
    isPageLevelError,
    isRegisterSuccess,
    isLoading,
    register,
    toggleChange
  }

  const getToastProps = () => {
    if (isRegisterSuccess) {
      const toastTitle = 'Registered Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }
    if (isRegisterError) {
      const toastTitle = error ? error : 'Error during registration'
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  return (
    <div>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <RegisterComponent registerProps={registerProps} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initRegisterScreen: () => dispatch(initRegisterScreen()),
    register: (registerData) => dispatch(register(registerData))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.registerReducer),
    isPageLevelError: state.registerReducer.isPageLevelError,
    isLoading: state.registerReducer.isLoading,
    isRegisterSuccess: state.registerReducer.isRegisterSuccess,
    isRegisterError: state.registerReducer.isRegisterError,
    error: state.registerReducer.error
  }
}

const selectFormFieldValueMap = (registerReducer) => {
  return registerReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen)
