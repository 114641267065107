import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import KmlUploadComponent from '../../components/kmlUpload/index'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  createKmlUploadRecord,
  initKmlUpload
} from '../../redux/action/kmlUpload'
import { USER_ROLE } from '../../constant/actionTypes/role'

import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { fetchProvinceList } from '../../redux/action/province'
import { fetchDistrictList } from '../../redux/action/district'
import { fetchSubDistrictList } from '../../redux/action/subDistrict'
import { fetchVillageList } from '../../redux/action/village'

const KmlUploadScreen = (props) => {
  const {
    isCreateKmlUploadSuccess,
    isCreateKmlUploadError,
    initKmlUpload,
    isEdit,
    isLoading,
    createKmlUploadRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    userRole,
    error,
    fetchProvinceList,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList
  } = props
  useEffect(() => {
    initKmlUpload()
    fetchProvinceList()
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      // state to village....
    } else {
      //   if seller then fecth record then call by user  details  incase
    }
    // eslint-disable-next-line
  }, [userRole])

  const history = useNavigate()

  const getToastProps = () => {
    if (isCreateKmlUploadSuccess) {
      const toastTitle = 'KmlUpload Created Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateKmlUploadError) {
      let toastTitle = 'Error while Creating KmlUpload'
      // if (isEditKmlUploadError) {
      //   toastTitle = "Error while updating KmlUpload";
      // } else if (isKmlUploadDetailError) {
      //   toastTitle =
      //     "Error while performing operation. Please refresh your browser";
      // }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const navigatetoKmlUpload = () => {
    history(ROUTE_PATH.FILE_UPLOAD.HOME)
  }
  const kmlUploadProps = {
    createKmlUploadRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    error,
    isCreateKmlUploadSuccess,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    navigatetoKmlUpload
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <KmlUploadComponent kmlUploadProps={kmlUploadProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initKmlUpload: () => dispatch(initKmlUpload()),
    createKmlUploadRecord: (payload, id) =>
      dispatch(createKmlUploadRecord(payload, id)),
    fetchProvinceList: (payload) => dispatch(fetchProvinceList(payload)),
    fetchDistrictList: (payload) => dispatch(fetchDistrictList(payload)),
    fetchSubDistrictList: (payload) => dispatch(fetchSubDistrictList(payload)),
    fetchVillageList: (payload) => dispatch(fetchVillageList(payload))

    // fetch list of state,dis to.... village
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.kmlUploadReducer),
    isPageLevelError: state.kmlUploadReducer.isPageLevelError,
    isLoading: state.kmlUploadReducer.isLoading,
    error: state.kmlUploadReducer.error,
    isCreateKmlUploadSuccess: state.kmlUploadReducer.isCreateKmlUploadSuccess,
    isCreateKmlUploadError: state.kmlUploadReducer.isCreateKmlUploadError,
    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    provincesList: state.provinceReducer.provincesList,
    districtsList: state.districtReducer.districtsList,
    subDistrictsList: state.subDistrictReducer.subDistrictsList,
    villagesList: state.villageReducer.villagesList
  }
}

const selectFormFieldValueMap = (kmlUploadReducer) => {
  return kmlUploadReducer?.formFieldValueMap ?? null
}

export default connect(mapStateToProps, mapDispatchToProps)(KmlUploadScreen)
