export const FORM_FIELDS_NAME = {
  PROVINCE: {
    name: 'state',
    label: 'State',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: "State is required",
    },
    options: [],
    placeholder: 'Select a state'
  },
  DISTRICT: {
    name: 'district',
    label: 'District',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: "District is required",
    },
    options: [],
    placeholder: 'Select a district'
  },
  SUB_DISTRICT: {
    name: 'taluka',
    label: 'Sub District',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: "SubDistrict is required",
    },
    options: [],
    placeholder: 'Select a sub district'
  },
  VILLAGE: {
    name: 'village',
    label: 'Village',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: "Village is required",
    },
    options: [],
    placeholder: 'Select a village'
  },
  FILE: {
    name: 'file',
    label: 'KML file',
    type: 'file',
    placeholder: 'Upload a KML file',
    rules: {
      // required: "KML file Photo is required",
    }
  },
  GATNO: {
    name: 'gatNo',
    label: 'Plot Number',
    type: 'dropdown',
    optionLabel: 'plotNumber',
    optionValue: 'id',
    rules: {
      required: "Plot Number is required",
    },
    options: [],
    placeholder: 'Select a plot number'
  }
}
