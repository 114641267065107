export const INIT_VILLAGE = 'INIT_VILLAGE'

export const FETCH_VILLAGE_LIST = {
  START: 'FETCH_VILLAGE_START',
  SUCCESS: 'FETCH_VILLAGE_SUCCESS',
  ERROR: 'FETCH_VILLAGE_ERROR'
}

export const FETCH_VILLAGE_RECORD = {
  START: 'FETCH_VILLAGE_RECORD_START',
  SUCCESS: 'FETCH_VILLAGE_RECORD_SUCCESS',
  ERROR: 'FETCH_VILLAGE_RECORD_ERROR'
}

export const CREATE_VILLAGE_RECORD = {
  START: 'CREATE_VILLAGE_RECORD_START',
  SUCCESS: 'CREATE_VILLAGE_RECORD_SUCCESS',
  ERROR: 'CREATE_VILLAGE_RECORD_ERROR'
}

export const UPDATE_VILLAGE_RECORD = {
  START: 'UPDATE_VILLAGE_RECORD_START',
  SUCCESS: 'UPDATE_VILLAGE_RECORD_SUCCESS',
  ERROR: 'UPDATE_VILLAGE_RECORD_ERROR'
}

export const DELETE_VILLAGE = {
  START: 'DELETE_VILLAGE_START',
  SUCCESS: 'DELETE_VILLAGE_SUCCESS',
  ERROR: 'DELETE_VILLAGE_ERROR'
}

export const GET_VILLAGE_COUNT = {
  START: 'GET_VILLAGE_COUNT_START',
  SUCCESS: 'GET_VILLAGE_COUNT_SUCCESS',
  ERROR: 'GET_VILLAGE_COUNT_ERROR'
}
