import { FORM_FIELDS_NAME } from './constant'
import MzInput from '../../common/MzForm/MzInput'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import axios from 'axios'
import { baseUrl } from '../../services/PostAPI'
import { LOGO } from '../../assets/images'
import { Button } from 'primereact/button'
import { useLocation } from 'react-router-dom';
const ResetPasswordComponent = () => {
  const location = useLocation();
  const toast = useRef(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resetKey = queryParams.get('key');
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({

    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const newPasswordValue = watch('newPassword', '');
  const handleClose = () => {
    navigate('/');
  }
  const onSubmit = async (data) => {

    try {
      const response = await axios.post(`${baseUrl}/reset-password`, {
        resetKey: resetKey,
        newPassword: data.newPassword
      })

      if (response?.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Password Reset successfully',
          life: 3000
        });
        reset();
        setTimeout(() => {
          navigate('/');
        }, 2000);

      } else {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to reset password',
          life: 3000
        });
      }
    } catch (error) {

      if (error?.response && error?.response?.data) {
        const errorMessage = error?.response?.data?.message || 'There was a error reset the password. Please try again.';
          toast?.current?.show({
           severity: 'error',
           summary: 'Error',
           detail: errorMessage,  
           life: 3000,
         });
       }
      else{
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'There was a error reset the password. Please try again.',
        life: 3000
      });
    }
    }
  }
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }
  return (
    <>


      <Toast ref={toast} />

      <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div className="flex flex-column align-items-center justify-content-center p-2">

          <div
            style={{
              borderRadius: '56px',
              padding: '0.3rem',
              background:
              'linear-gradient(180deg, rgba(58, 180, 77, 0.4) 10%, rgba(255, 95, 27, 0.3) 60%)'
            }}
          >
            <div
              className="w-full text-center surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center"
              style={{ borderRadius: '53px' }}

            >

              <div
                className="flex justify-content-center align-items-center border-circle"
                style={{ height: '3.2rem', width: '3.2rem' }}
              >
                <img src={LOGO} alt="Logo" className="mb-5 w-4rem flex-shrink-0" />
              </div>


              <h2 className='mt-3'>Reset Password</h2>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-2 w-full px-4 md:mt-5"
              >
                <div className="card mt-3">

                  <div className="grid">
                    <div className="col-12 ">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.NEW_PASSWORD.name}
                        label={FORM_FIELDS_NAME.NEW_PASSWORD.label}
                        placeholder={FORM_FIELDS_NAME.NEW_PASSWORD.placeholder}
                        type={FORM_FIELDS_NAME.NEW_PASSWORD.type}
                        isError={errors[FORM_FIELDS_NAME.NEW_PASSWORD.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.NEW_PASSWORD.name
                        )}
                        rules={FORM_FIELDS_NAME.NEW_PASSWORD.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.CONFIRM_PASSWORD.name}
                        label={FORM_FIELDS_NAME.CONFIRM_PASSWORD.label}
                        placeholder={FORM_FIELDS_NAME.CONFIRM_PASSWORD.placeholder}
                        type={FORM_FIELDS_NAME.CONFIRM_PASSWORD.type}
                        isError={errors[FORM_FIELDS_NAME.CONFIRM_PASSWORD.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.CONFIRM_PASSWORD.name
                        )}
                        // rules={FORM_FIELDS_NAME.CONFIRM_PASSWORD.rules}
                        rules={{
                          ...FORM_FIELDS_NAME.CONFIRM_PASSWORD.rules,
                          validate: value =>
                            value === newPasswordValue || 'Passwords do not match'
                        }}
                        wrapperClass={'p-float-label'}
                      />
                    </div>

                  </div>

                </div>
                <div className="grid justify-content-center mt-3 mb-5">
                  <div className=" col-12 md:col-6">
                    <Button
                      label="Cancel"
                      className="w-full"
                      severity="secondary"
                      onClick={handleClose}
                    ></Button>
                  </div>
                  <div className=" col-12 md:col-6">
                    <Button
                      type="submit"
                      label="Submit"
                      className="w-full"

                    ></Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  )

}
export default ResetPasswordComponent