import React from 'react'
import RefundPolicyComponent from '../../components/refundPolicy'

function RefundPolicyScreen() {
  return (
    <div>
      <RefundPolicyComponent />
    </div>
  )
}

export default RefundPolicyScreen
