import React from 'react'
import { connect } from 'react-redux'
import UpdateMapComponent from '../../../components/link/updateMap'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
    fetchLinkRecord,
    updatePlotRecord
} from '../../../redux/action/link'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../../constant/urlConstant'

const UpdateMapScreen = (props) => {
    const {
        isLinkDetailSuccess,
        isLinkDetailError,
        isEditLinkSuccess,
        isEditLinkError,
        isEdit,
        isLoading,
        error,
        isPageLevelError,
        fetchLinkRecord,
        updatePlotRecord,
        selectedLink

    } = props

    // const { plot = null } = selectedLink

    const plotCoordinates = selectedLink
        ? JSON.parse(selectedLink?.plot?.plotCoordinates?.replace(/(\w+):/g, '"$1":'))
        : []

    const pinCoordinates = selectedLink
        ? JSON.parse(selectedLink?.plot?.pinCoordinates?.replace(/(\w+):/g, '"$1":'))
        : []
    const history = useNavigate()
    const getToastProps = () => {
        if (isEditLinkSuccess) {
            const toastTitle = isEdit
                ? 'Plot Updated Successfully'
                : 'Plot Updated Successfully'
            return {
                severity: TOAST_SEVERITY.SUCCESS,
                toastTitle,
                shouldShowToast: true
            }
        }
        if (isLinkDetailError || isEditLinkError) {
            let toastTitle = error ?? 'Error while Creating Plot'
            if (isEditLinkError) {
                toastTitle = 'Error while updating Plot'
            } else if (isLinkDetailError) {
                toastTitle =
                    'Error while performing operation. Please refresh your browser'
            }
            return {
                severity: TOAST_SEVERITY.ERROR,
                toastTitle,
                shouldShowToast: true
            }
        }
    }

      const plotNumber = selectedLink ? selectedLink?.plot?.plotNumber : ''
    const renderProgressBar = () => {
        return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
    }
    const navigatetoLink = () => {
        history(ROUTE_PATH.LINK.HOME)
    }
    const updateMapProps = {
        selectedLink,
        isPageLevelError,
        isLoading,
        isEdit,
        isEditLinkSuccess,
        isLinkDetailSuccess,
        fetchLinkRecord,
        updatePlotRecord,
        navigatetoLink,
        plotCoordinates,
        pinCoordinates,
        plotNumber
    }


    return (
        <>
            {isLoading && renderProgressBar()}
            <MzToast {...getToastProps()} />
            <UpdateMapComponent updateMapProps={updateMapProps} />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLinkRecord: (payload) => dispatch(fetchLinkRecord(payload)),
        updatePlotRecord: (payload) => dispatch(updatePlotRecord(payload)),
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        selectedLink: selectFormFieldValueMap(state.linkReducer),
        isPageLevelError: state.linkReducer.isPageLevelError,
        isLoading: state.linkReducer.isLoading,
        error: state.linkReducer.error,
        isEditLinkSuccess: state.linkReducer.isEditLinkSuccess,
        isEditLinkError: state.linkReducer.isEditLinkError,
        isLinkDetailError: state.linkReducer.isLinkDetailError,
        isLinkDetailSuccess: state.linkReducer.isLinkDetailSuccess,
    }
}

const selectFormFieldValueMap = (linkReducer) => {
    return linkReducer.selectedLink
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMapScreen)
