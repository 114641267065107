export const INIT_USER = 'INIT_USER'

export const FETCH_USER_LIST = {
  START: 'FETCH_USER_START',
  SUCCESS: 'FETCH_USER_SUCCESS',
  ERROR: 'FETCH_USER_ERROR'
}

export const FETCH_USER_RECORD = {
  START: 'FETCH_USER_RECORD_START',
  SUCCESS: 'FETCH_USER_RECORD_SUCCESS',
  ERROR: 'FETCH_USER_RECORD_ERROR'
}

export const CREATE_USER_RECORD = {
  START: 'CREATE_USER_RECORD_START',
  SUCCESS: 'CREATE_USER_RECORD_SUCCESS',
  ERROR: 'CREATE_USER_RECORD_ERROR'
}

export const UPDATE_USER_RECORD = {
  START: 'UPDATE_USER_RECORD_START',
  SUCCESS: 'UPDATE_USER_RECORD_SUCCESS',
  ERROR: 'UPDATE_USER_RECORD_ERROR'
}

export const DELETE_USER = {
  START: 'DELETE_USER_START',
  SUCCESS: 'DELETE_USER_SUCCESS',
  ERROR: 'DELETE_USER_ERROR'
}

export const GET_USER_COUNT = {
  START: 'GET_USER_COUNT_START',
  SUCCESS: 'GET_USER_COUNT_SUCCESS',
  ERROR: 'GET_USER_COUNT_ERROR'
}
