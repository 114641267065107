import { INIT_REGISTER, REGISTER } from '../../../constant/actionTypes/auth'

const formFieldValueMap = {}

const initialState = {
  formFieldValueMap,
  error: '',
  isLoading: false,
  isPageLevelError: false,
  isLoadingPage: false,
  isRegisterSuccess: false,
  isRegisterError: false
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_REGISTER:
      return {
        ...state,
        error: null,
        isRegisterSuccess: false,
        isRegisterError: false
      }
    case REGISTER.START:
      return {
        ...state,
        isLoading: true,
        isRegisterSuccess: false,
        isRegisterError: false
      }
    case REGISTER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRegisterSuccess: true,
        isRegisterError: false
      }
    case REGISTER.ERROR:
      return {
        ...state,
        isLoading: false,
        isRegisterError: true,
        error: action.payload?.error
      }

    default: {
      return state
    }
  }
}

export default registerReducer
