import {
  INIT_SUB_DISTRICT,
  FETCH_SUB_DISTRICT_LIST,
  FETCH_SUB_DISTRICT_RECORD,
  CREATE_SUB_DISTRICT_RECORD,
  DELETE_SUB_DISTRICT,
  UPDATE_SUB_DISTRICT_RECORD,
  GET_SUB_DISTRICT_COUNT
} from '../../../constant/actionTypes/subDistrict'

const formFieldValueMap = {
  tenant: '',
  username: '',
  email: '',
  password: '',
  authorities: '',
  department: '',
  profilePicture: null
}

const initialState = {
  subDistrictsList: [],
  subDistrictCount: 0,
  selectedSubDistrict: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateSubDistrictSuccess: false,
  isEditSubDistrictSuccess: false,
  isSubDistrictDetailSuccess: false,
  isDeleteSubDistrictSuccess: false,
  isDeleteSubDistrictError: false,
  isSubDistrictDetailError: false,
  isCreateSubDistrictError: false,
  isEditSubDistrictError: false
}

const subDistrictReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SUB_DISTRICT:
      return {
        ...state,
        formFieldValueMap: null,
        subDistrictsList: [],
        selectedSubDistrict: null,
        isLoading: false,
        error: null
      }
    case FETCH_SUB_DISTRICT_LIST.START:
    case FETCH_SUB_DISTRICT_RECORD.START:
    case CREATE_SUB_DISTRICT_RECORD.START:
    case DELETE_SUB_DISTRICT.START:
    case UPDATE_SUB_DISTRICT_RECORD.START:
    case GET_SUB_DISTRICT_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateSubDistrictSuccess: false,
        isEditSubDistrictSuccess: false,
        isSubDistrictDetailSuccess: false,
        isDeleteSubDistrictSuccess: false,
        isDeleteSubDistrictError: false,
        isSubDistrictDetailError: false,
        isCreateSubDistrictError: false,
        isEditSubDistrictError: false
      }
    case FETCH_SUB_DISTRICT_LIST.SUCCESS:
      return {
        ...state,
        subDistrictsList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_SUB_DISTRICT_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        subDistrictCount: action.payload
      }
    }
    case GET_SUB_DISTRICT_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        subDistrictCount: 0
      }
    }
    case FETCH_SUB_DISTRICT_RECORD.SUCCESS:
      return {
        ...state,
        selectedSubDistrict: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          tenant: action?.payload?.tenant?.id ?? '',
          username: action?.payload?.username ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          authorities: action?.payload?.authorities[0]?.name ?? '',
          department: action?.payload?.department?.id ?? '',
          profilePicture: action?.payload?.profilePicture ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobileNumber: action?.payload?.mobileNumber ?? ''
        },
        isLoading: false,
        error: null,
        isSubDistrictDetailSuccess: true
      }
    case CREATE_SUB_DISTRICT_RECORD.SUCCESS:
      return {
        ...state,
        subDistrictsList: [...state.subDistrictsList, action.payload],
        isLoading: false,
        error: null,
        isCreateSubDistrictSuccess: true
      }
    case UPDATE_SUB_DISTRICT_RECORD.SUCCESS:
      return {
        ...state,
        subDistrictsList: state.subDistrictsList.map((subDistrict) =>
          subDistrict.id === action.payload.id ? action.payload : subDistrict
        ),
        isLoading: false,
        error: null,
        isEditSubDistrictSuccess: true
      }
    case DELETE_SUB_DISTRICT.SUCCESS:
      return {
        ...state,
        subDistrictsList: state.subDistrictsList.filter(
          (subDistrict) => subDistrict.id !== action.payload.subDistrictId
        ),
        isLoading: false,
        error: null,
        isDeleteSubDistrictSuccess: true
      }
    case FETCH_SUB_DISTRICT_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_SUB_DISTRICT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isSubDistrictDetailError: true
      }
    case CREATE_SUB_DISTRICT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateSubDistrictError: true
      }

    case UPDATE_SUB_DISTRICT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditSubDistrictError: true
      }
    case DELETE_SUB_DISTRICT.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteSubDistrictError: true
      }

    default:
      return state
  }
}

export default subDistrictReducer
