import {
  INIT_VILLAGE,
  FETCH_VILLAGE_LIST,
  FETCH_VILLAGE_RECORD,
  CREATE_VILLAGE_RECORD,
  DELETE_VILLAGE,
  UPDATE_VILLAGE_RECORD,
  GET_VILLAGE_COUNT
} from '../../../constant/actionTypes/village'
import { villageService } from '../../../services'

export const initVillage = () => ({
  type: INIT_VILLAGE
})

export const fetchVillageListStart = () => ({
  type: FETCH_VILLAGE_LIST.START
})

export const fetchVillageListSuccess = (villages) => ({
  type: FETCH_VILLAGE_LIST.SUCCESS,
  payload: villages
})

export const fetchVillageListError = (error) => ({
  type: FETCH_VILLAGE_LIST.ERROR,
  payload: { error }
})

export const fetchVillageList = (payload) => {
  return (dispatch) => {
    dispatch(fetchVillageListStart(payload))
    villageService.fetchVillageList(payload).then((villageData) => {
      if (!villageData.isError) {
        dispatch(fetchVillageListSuccess(villageData))
      } else {
        dispatch(fetchVillageListError(villageData))
      }
    })
  }
}

export const fetchVillageListBySalesPerson = () => {
  return (dispatch) => {
    dispatch(fetchVillageListStart())
    villageService.fetchVillageListBySalesPerson().then((villageData) => {
      if (!villageData.isError) {
        dispatch(fetchVillageListSuccess(villageData))
      } else {
        dispatch(fetchVillageListError(villageData))
      }
    })
  }
}

export const fetchVillageRecordStart = () => ({
  type: FETCH_VILLAGE_RECORD.START
})

export const fetchVillageRecordSuccess = (village) => ({
  type: FETCH_VILLAGE_RECORD.SUCCESS,
  payload: village
})

export const fetchVillageRecordError = (error) => ({
  type: FETCH_VILLAGE_RECORD.ERROR,
  payload: { error }
})

export const fetchVillageRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchVillageRecordStart(payload))
    villageService.fetchVillageRecord(payload).then((villageData) => {
      if (!villageData.isError) {
        dispatch(fetchVillageRecordSuccess(villageData))
      } else {
        dispatch(fetchVillageRecordError(villageData))
      }
    })
  }
}

export const createVillageRecordStart = () => ({
  type: CREATE_VILLAGE_RECORD.START
})

export const createVillageRecordSuccess = (newVillage) => ({
  type: CREATE_VILLAGE_RECORD.SUCCESS,
  payload: newVillage
})

export const createVillageRecordError = (error) => ({
  type: CREATE_VILLAGE_RECORD.ERROR,
  payload: { error }
})

export const createVillageRecord = (payload) => {
  return (dispatch) => {
    dispatch(createVillageRecordStart(payload))
    villageService.createVillageRecord(payload).then((villageData) => {
      if (!villageData.isError) {
        dispatch(createVillageRecordSuccess(villageData))
      } else {
        dispatch(createVillageRecordError(villageData))
      }
    })
  }
}

export const updateVillageRecordStart = (payload, id) => ({
  type: UPDATE_VILLAGE_RECORD.START,
  payload,
  id
})

export const updateVillageRecordSuccess = (newVillage) => ({
  type: UPDATE_VILLAGE_RECORD.SUCCESS,
  payload: newVillage
})

export const updateVillageRecordError = (error) => ({
  type: UPDATE_VILLAGE_RECORD.ERROR,
  payload: { error }
})

export const updateVillageRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateVillageRecordStart(payload, id))
    villageService.updateVillageRecord(payload, id).then((villageData) => {
      if (!villageData.isError) {
        dispatch(updateVillageRecordSuccess(villageData))
      } else {
        dispatch(updateVillageRecordError(villageData))
      }
    })
  }
}

export const deleteVillageStart = () => ({
  type: DELETE_VILLAGE.START
})

export const deleteVillageSuccess = (villageId) => ({
  type: DELETE_VILLAGE.SUCCESS,
  payload: { villageId }
})

export const deleteVillageError = (error) => ({
  type: DELETE_VILLAGE.ERROR,
  payload: { error }
})

export const deleteVillage = (villageId) => {
  return (dispatch) => {
    dispatch(deleteVillageStart())
    villageService.deleteVillage(villageId).then((villageData) => {
      if (!villageData.isError) {
        dispatch(deleteVillageSuccess(villageData))
      } else {
        dispatch(deleteVillageError(villageData))
      }
    })
  }
}

export const getVillageCountStart = (role, payload) => {
  return {
    type: GET_VILLAGE_COUNT.START,
    role,
    payload
  }
}
export const getVillageCountSuccess = (villageData) => {
  return {
    type: GET_VILLAGE_COUNT.SUCCESS,
    payload: villageData
  }
}
export const getVillageCountError = (error) => {
  return {
    type: GET_VILLAGE_COUNT.ERROR,
    payload: error
  }
}

export const getVillageCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getVillageCountStart(role, payload))
    villageService.getVillageCount(role, payload).then((villageData) => {
      if (!villageData.isError) {
        dispatch(getVillageCountSuccess(villageData))
      } else {
        dispatch(getVillageCountError(villageData))
      }
    })
  }
}
