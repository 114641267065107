import React from 'react'

const RefundPolicyComponent = () => {
  return (
    <div className="page-information-container md:px-7 mt-8 mb-3 lg:mb-7 mr-4 ml-4  md:mr-6 md:ml-6 lg:mr-7 lg:ml-7">
      <div className="mb-3">
        <h1 className="font-medium text-2xl mt-5">
           Cancellation and Refund Policy
        </h1>
      </div>

      <p className="line-height-2">
          Effective Date: January 1, 2024 <br />
          At Clearcole Solution, we strive to ensure that you have a seamless experience using our product, Bhoomi22. Please read our Cancellation and Refund Policy carefully.
      </p>

      <h2 className="page-info-subheading font-medium text-lg">1. Cancellation Policy</h2>
      <p className="line-height-2">
          You may cancel your order for any product or service through our website within 24 hours of placing the order.
          After 24 hours, orders are processed, and cancellations will not be possible.
          To cancel an order, contact our support team at 
          {'   '}{' '}
          <a href="mailto:support@bhoomi22.com." className="text-blue-500">
          support@bhoomi22.com.
          </a>

      </p>

      <h2 className="page-info-subheading font-medium text-lg">2. Refund Policy</h2>

      <p className="line-height-2">
        Refunds will only be issued if access to the product or service has not been granted due to payment failure or technical issues on our end.
        In the event of an authorized cancellation within the specified period, we will refund the full amount minus any applicable transaction fees.
        Refunds will be processed within 7-10 business days to the original method of payment.
        For digital products or services, no refund will be issued once access has been granted to the customer.

      </p>


      <h3 className="page-info-subheading font-medium text-lg">3.Contact Us</h3>
      <p className="line-height-2">
          For any questions or concerns about our Cancellation and Refund Policy, please contact us at 
          {'   '}{' '}
              <a href="mailto:support@bhoomi22.com." className="text-blue-500">
              support@bhoomi22.com.
              </a>
      </p>
      
    </div>
  )
}

export default RefundPolicyComponent
