import React from 'react'
import ContactUsComponent from '../../components/contactUs'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { connect } from 'react-redux'
import {
  createcontactUsRecord,
  fetchcontactUsList,
  initcontactUs
} from '../../redux/action/contactUs'

function ContactUsScreen(props) {
  const {
    isCreatecontactUsSuccess,
    isCreatecontactUsError,
    formFieldValueMap
  } = props

  const contactusProps = {
    isCreatecontactUsSuccess,
    isCreatecontactUsError,
    formFieldValueMap
  }

  const getToastProps = () => {
    if (isCreatecontactUsSuccess) {
      const toastTitle = 'Contact Us request send Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreatecontactUsError) {
      let toastTitle = 'Error while Sending Contact Us Request Status'
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <div>
      <MzToast {...getToastProps()} />
      <ContactUsComponent contactusProps={contactusProps} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initcontactUs: () => dispatch(initcontactUs()),
    createcontactUsRecord: (payload) =>
      dispatch(createcontactUsRecord(payload)),
    fetchcontactUsList: (payload) => dispatch(fetchcontactUsList(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.contactUsReducer),
    isLoading: state.contactUsReducer.isLoading,
    error: state.contactUsReducer.error,
    isCreatecontactUsSuccess: state.contactUsReducer.isCreatecontactUsSuccess,
    isCreatecontactUsError: state.contactUsReducer.isCreatecontactUsError,
    iscontactUsDetailError: state.contactUsReducer.iscontactUsDetailError,
    iscontactUsDetailSuccess: state.contactUsReducer.iscontactUsDetailSuccess,
    userRole: state.loginReducer.userRole
  }
}

const selectFormFieldValueMap = (contactUsReducer) => {
  return contactUsReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsScreen)
