export const INIT_PLAN = 'INIT_PLAN'
export const INIT_PAYMENT = 'INIT_PAYMENT'

export const FETCH_PLAN_LIST = {
  START: 'FETCH_PLAN_START',
  SUCCESS: 'FETCH_PLAN_SUCCESS',
  ERROR: 'FETCH_PLAN_ERROR'
}

export const CREATE_ORDER = {
  START: 'CREATE_ORDER_START',
  SUCCESS: 'CREATE_ORDER_SUCCESS',
  ERROR: 'CREATE_ORDER_ERROR'
}
export const CREATE_PAYEMNT = {
  START: 'CREATE_PAYEMNT_START',
  SUCCESS: 'CREATE_PAYEMNT_SUCCESS',
  ERROR: 'CREATE_PAYEMNT_ERROR'
}
