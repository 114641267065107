import React, { useState } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { Controller } from 'react-hook-form'
import { Message } from 'primereact/message'
import { classNames } from 'primereact/utils'

const MzMultiSelect = ({
  control,
  name,
  disabled,
  rules,
  labelClassName,
  label,
  onChange,
  errorMsg,
  placeholder,
  suggestions,
  optionLabel,
  optionValue,
  isError
}) => {
  const [selectedValues, setSelectedValues] = useState([])

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      [labelClassName]: labelClassName
    })
  }

  const handleMultiSelectChange = (selectedItems) => {
    setSelectedValues(selectedItems)
    onChange && onChange(selectedItems.map((item) => item[optionValue]))
  }

  return (
    <div className="field" style={{ textAlign: 'left' }}>
      <label htmlFor={name} className={getLabelClassName()}>
        {label}
        {rules?.required ? <span className="p-error">*</span> : null}
      </label>
      <span className="">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => (
            <MultiSelect
              id={field.name}
              filter
              display="chip"
              name={field.name}
              value={selectedValues}
              options={suggestions}
              onChange={(e) => {
                handleMultiSelectChange(e.value)
                field.onChange(e.value)
              }}
              placeholder={placeholder}
              optionLabel={optionLabel}
              optionValue={optionValue}
              disabled={disabled}
              className={`text-600 ${
                !field.value && fieldState?.invalid ? 'p-invalid' : ''
              }`}
              style={{
                textAlign: 'center',
                width: '100%'
              }}
            />
          )}
        />
      </span>
      {errorMsg && (
        <Message
          className="mt-1 flex"
          style={{
            borderWidth: '0 0 0 1px'
          }}
          severity="error"
          content={errorMsg}
        />
      )}
    </div>
  )
}

export default MzMultiSelect
