import {
  INIT_GATNO,
  FETCH_GATNO_LIST,
  FETCH_GATNO_RECORD,
  CREATE_GATNO_RECORD,
  DELETE_GATNO,
  UPDATE_GATNO_RECORD,
  GET_GATNO_COUNT
} from '../../../constant/actionTypes/gatNo'

const formFieldValueMap = {
  tenant: '',
  username: '',
  email: '',
  password: '',
  authorities: '',
  department: '',
  profilePicture: null
}

const initialState = {
  gatNosList: [],
  gatNoCount: 0,
  selectedGatNo: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateGatNoSuccess: false,
  isEditGatNoSuccess: false,
  isGatNoDetailSuccess: false,
  isDeleteGatNoSuccess: false,
  isDeleteGatNoError: false,
  isGatNoDetailError: false,
  isCreateGatNoError: false,
  isEditGatNoError: false
}

const gatNoReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_GATNO:
      return {
        ...state,
        isCreateGatNoSuccess: false,
        isEditGatNoSuccess: false,
        isGatNoDetailSuccess: false,
        isDeleteGatNoSuccess: false,
        isDeleteGatNoError: false,
        isGatNoDetailError: false,
        isCreateGatNoError: false,
        isEditGatNoError: false,
        formFieldValueMap: null,
        gatNosList: [],
        selectedGatNo: null,
        isLoading: false,
        error: null
      }
    case FETCH_GATNO_LIST.START:
    case FETCH_GATNO_RECORD.START:
    case CREATE_GATNO_RECORD.START:
    case DELETE_GATNO.START:
    case UPDATE_GATNO_RECORD.START:
    case GET_GATNO_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateGatNoSuccess: false,
        isEditGatNoSuccess: false,
        isGatNoDetailSuccess: false,
        isDeleteGatNoSuccess: false,
        isDeleteGatNoError: false,
        isGatNoDetailError: false,
        isCreateGatNoError: false,
        isEditGatNoError: false
      }
    case FETCH_GATNO_LIST.SUCCESS:
      return {
        ...state,
        gatNosList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_GATNO_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        gatNoCount: action.payload
      }
    }
    case GET_GATNO_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        gatNoCount: 0
      }
    }
    case FETCH_GATNO_RECORD.SUCCESS:
      return {
        ...state,
        selectedGatNo: action.payload,
        isLoading: false,
        error: null,
        isGatNoDetailSuccess: true
      }
    case CREATE_GATNO_RECORD.SUCCESS:
      return {
        ...state,
        gatNosList: [...state.gatNosList, action.payload],
        isLoading: false,
        error: null,
        isCreateGatNoSuccess: true
      }
    case UPDATE_GATNO_RECORD.SUCCESS:
      return {
        ...state,
        gatNosList: state.gatNosList.map((gatNo) =>
          gatNo.id === action.payload.id ? action.payload : gatNo
        ),
        isLoading: false,
        error: null,
        isEditGatNoSuccess: true
      }
    case DELETE_GATNO.SUCCESS:
      return {
        ...state,
        gatNosList: state.gatNosList.filter(
          (gatNo) => gatNo.id !== action.payload.gatNoId
        ),
        isLoading: false,
        error: null,
        isDeleteGatNoSuccess: true
      }
    case FETCH_GATNO_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_GATNO_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        selectedGatNo: null,
        isGatNoDetailError: true
      }
    case CREATE_GATNO_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateGatNoError: true
      }

    case UPDATE_GATNO_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditGatNoError: true
      }
    case DELETE_GATNO.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteGatNoError: true
      }

    case 'SET_GTNO_ERROR':
      return {
        ...state,
        isGatNoDetailError: false
      }

    default:
      return state
  }
}

export default gatNoReducer
