import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import MzInput from '../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constant'
import { Button } from 'primereact/button'
import { NavLink } from 'react-router-dom'
import MzCheckbox from '../../common/MzForm/MzCheckbox'
import { LOGO } from '../../assets/images'
import { useState } from 'react'
import MzDialog from '../../common/MzDialog'
import ForgotPasswordComponent from '../forgotPassword'
const LoginComponent = (props) => {
  const {
    formFieldValueMap,
    login,
    isLoading,
    isPageLevelError,
    isLoginSuccess,
    toggleChange,
    setVisibleLogin,
    toggleBothChange
  } = props.loginProps
  const [forgotPasswordDialog, openForgotPasswordDialog] = useState(false)
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: { ...formFieldValueMap },
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (isLoginSuccess) {
      reset()
      toggleBothChange()
      // if navigate then navigate
    }
  }, [isLoginSuccess, reset, toggleBothChange])
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }
  const forgotpasswordProps = {
    openForgotPasswordDialog,
    setVisibleLogin
  }
  const onSubmit = (data) => {
    login(data)
  }
  const forgotPassword=()=>{
    openForgotPasswordDialog(true);
  }
  const handleClose=()=>{
    openForgotPasswordDialog(false);
  }
  return (
    <>
      <div className="surface-grund flex align-items-center justify-content-center max-h-screen    max-w-screen overflow-hidden">
        <div className="flex flex-column align-items-center justify-content-center p-3">
          {/* <img
                  src={LOGO}
                  alt="Wingrow logo"
                  className="mb-1 w-6rem flex-shrink-0"
                /> */}
          {/* <div
            style={{
              borderRadius: "56px",
              padding: "0.3rem",
              background:
                "linear-gradient(180deg,rgba(7,120,84, 0.4) 30%, rgba(38, 242, 177, 0.2) 70%)",
            }}
          >
            <div
              className="w-full text-center surface-card py-5 md:py-8 px-3 md:px-8 flex flex-column align-items-center "
              style={{ borderRadius: "53px" }}
            > */}
          <div
            className="flex justify-content-center align-items-center bg-green-700 border-circle"
            style={{ height: '3.2rem', width: '3.2rem' }}
          >
            {/* <i className="pi pi-sign-in text-2xl text-white"></i> */}
              <img src={LOGO} alt="Logo" style={{ height: '4rem', cursor: 'pointer' }} />
          </div>
          <h1 className="text-900 font-bold text-3xl md:text-5xl mb-2">
            Sign In
          </h1>
          <div className="text-600 mb-5">Welcome Back to Bhoomi 22.</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column text-center  border-round-sm ">
              <div className="mb-3 w-17rem md:w-20rem">
                <MzInput
                  control={control}
                  name={FORM_FIELDS_NAME.USERNAME.name}
                  label={FORM_FIELDS_NAME.USERNAME.label}
                  placeholder={FORM_FIELDS_NAME.USERNAME.placeholder}
                  type={FORM_FIELDS_NAME.USERNAME.type}
                  isError={errors[FORM_FIELDS_NAME.USERNAME.name]}
                  errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.USERNAME.name)}
                  rules={FORM_FIELDS_NAME.USERNAME.rules}
                  wrapperClass={'p-float-label'}
                />
              </div>

              <div className="mb-3">
                <MzInput
                  control={control}
                  name={FORM_FIELDS_NAME.PASSWORD.name}
                  label={FORM_FIELDS_NAME.PASSWORD.label}
                  placeholder={FORM_FIELDS_NAME.PASSWORD.placeholder}
                  type={FORM_FIELDS_NAME.PASSWORD.type}
                  isError={errors[FORM_FIELDS_NAME.PASSWORD.name]}
                  errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.PASSWORD.name)}
                  rules={FORM_FIELDS_NAME.PASSWORD.rules}
                  wrapperClass={'p-float-label'}
                />
              </div>
              <div className="mb-3 text-sm  flex align-items-center justify-content-between ">
                <MzCheckbox
                  control={control}
                  name={FORM_FIELDS_NAME.REMBERME.name}
                  label={FORM_FIELDS_NAME.REMBERME.label}
                  isError={errors[FORM_FIELDS_NAME.REMBERME.name]}
                  la
                  errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.REMBERME.name)}
                />
                <NavLink
                  disabled={true}
                  // to="/"
                  onClick={forgotPassword}
                  style={{ textDecoration: 'none' }}
                >
                  Forgot Password?
                </NavLink>
              </div>

              <div className="mb-3 mt-2">
                <Button
                  type="submit"
                  label="Submit"
                  className="w-full"
                  disabled={isLoading || isPageLevelError}
                ></Button>
              </div>
              <div className='text-sm '>
              Don't have an account ? &nbsp;
              <NavLink
                onClick={() => toggleChange(true)}
                style={{ textDecoration: 'none' }}
              >
                Sign up
              </NavLink>
            </div>
            </div>
     
          </form>
        <MzDialog
        visible={forgotPasswordDialog}
        onHide={handleClose}

        children={
          <>
            <ForgotPasswordComponent forgotpasswordProps={forgotpasswordProps}/>
          </>
        }
      ></MzDialog>
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  )
}

export default LoginComponent
