export const FORM_FIELDS_NAME = {
    OLD_PASSWORD: {
        name: 'oldPassword',
        label: 'Old Password',
        type: 'password',
        placeholder: 'Enter old password',
        rules: {
          required: 'password is required',
        }
      },
    NEW_PASSWORD:{
        name: 'newPassword',
        label: 'New Password',
        type: 'password',
        placeholder: 'Enter new password',
        rules: {
          required: 'password is required',
        }
    },
    CONFIRM_PASSWORD:{
        name: 'confirmPassword',
        label: 'Confirm Password',
        type: 'password',
        placeholder: 'Enter confirm password',
        rules: {
          required: 'password is required',
        }
    }


}