import { useEffect, useRef } from 'react'
import crypto from 'crypto-js'
import PropTypes from 'prop-types'
import Axios from 'axios'
import { baseUrl } from '../../services/PostAPI'

// Function to load Razorpay script
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })

const RenderRazorpay = ({
  orderId,
  keyId,
  keySecret = process.env.REACT_APP_RAZORPAY_KEY_SECRET,
  currency,
  amount,
  handlePayments,
  plot
}) => {
  const paymentId = useRef(null)
  const paymentMethod = useRef(null)

  const displayRazorpay = async (options) => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?')
      return
    }

    const rzp1 = new window.Razorpay(options)

    rzp1.on('payment.submit', (response) => {
      paymentMethod.current = response.method
    })

    rzp1.on('payment.failed', (response) => {
      paymentId.current = response.error.metadata.payment_id
    })

    rzp1.open()
  }

  const handlePayment = async (status, orderDetails) => {
    const token = localStorage.getItem('token');
    const payStatus = status === 'succeeded' ? 'SUCCESS' : 'FAILED';
    if (orderDetails?.orderId && orderDetails?.paymentId) {
      try {
        const response = await Axios.post(
          `${baseUrl}/payment/verify`,
          {
            orderId: orderDetails.orderId,
            paymentId: orderDetails.paymentId,
            paymentStatus: payStatus,
            plot: {
              id: plot,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          handlePayments(false, plot);
        } else {
          handlePayments(true, plot);
        }
      } catch (error) {
        console.error('Payment verification error:', error);
        handlePayments(true, plot);
      }
    } else {
      console.warn('Invalid order details:', orderDetails);
      handlePayments(true, plot);
    }
  }

  const options = {
    key: keyId,
    amount,
    currency,
    name: 'Clearcole Solution',
    order_id: orderId,
    handler: (response) => {
      paymentId.current = response.razorpay_payment_id
      const succeeded =
        crypto
          .HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret)
          .toString() === response.razorpay_signature
      if (succeeded) {
        handlePayment('succeeded', {
          orderId,
          paymentId: paymentId.current,
          signature: response.razorpay_signature
        })
      } else {
        handlePayment('failed', {
          orderId,
          paymentId: paymentId.current
        })
      }
    },
    modal: {
      confirm_close: true,
      ondismiss: async (reason) => {
        if (!reason) {

          handlePayment('Cancelled')
        } else if (reason === 'timeout') {

          handlePayment('timedout')
        } else {

          handlePayment('failed', {
            orderId: reason.error?.metadata.order_id ?? '',
            paymentId: reason.error?.metadata.payment_id ?? ''
          })
        }
      }
    },
    retry: {
      enabled: false
    },
    timeout: 900,
    theme: {
      color: '#3399cc'
    }
  }

  useEffect(() => {
    displayRazorpay(options)
    // eslint-disable-next-line
  }, [])

  return null
}

RenderRazorpay.propTypes = {
  orderId: PropTypes.string.isRequired,
  keyId: PropTypes.string.isRequired,
  keySecret: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired
}

export default RenderRazorpay
