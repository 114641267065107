import React, { useState ,useRef, useEffect} from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Controller } from 'react-hook-form';
import { Message } from 'primereact/message';
import { classNames } from 'primereact/utils';

const MzAutoComplete = ({
  control,
  name,
  disabled,
  rules,
  labelClassName,
  label,
  onChange,
  errorMsg,
  placeholder,
  suggestions,
  optionLabel,
  optionValue,
  isError,
  value,
  ...rest
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const autoCompleteRef = useRef(null);

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };
   useEffect(()=>{
       setInputValue(value || '');
   },[value])
  const search = (event) => {
    const query = event.query;
    setInputValue(query); 

    if (!query.trim().length) {
      setFilteredSuggestions([...suggestions]);
    } else {
      const _filteredSuggestions = suggestions.filter((item) =>
        item[optionLabel].toLowerCase().includes(query.toLowerCase())
      );
      setFilteredSuggestions(_filteredSuggestions);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      return; 
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (filteredSuggestions.length > 0) {
        const selectedItem = filteredSuggestions[0];
        setInputValue(selectedItem[optionLabel]); 
        onChange && onChange(selectedItem[optionValue]);
      }
    }
  };

  const handleFocus = () => {
    setFilteredSuggestions([...suggestions]);
    autoCompleteRef.current.show();
  };
  return (
    <div className="field" style={{ textAlign: 'left' }}>
      <label htmlFor={name} className={getLabelClassName()}>
        {label}
        {rules?.required ? <span className="p-error">*</span> : null}
      </label>
      <span>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => (
            <AutoComplete
            ref={autoCompleteRef}
              id={field.name}
              name={field.name}
              value={inputValue}
              suggestions={filteredSuggestions}
              loading={ suggestions?.length === 0}
              placeholder={!inputValue ? placeholder : ''}
              completeMethod={search}
              field={optionLabel}
              onChange={(e) => {
                const selectedValue = e.value ? e.value[optionValue] : null;
                setInputValue(e.value ? e.value : e.originalEvent.target.value);
                field.onChange(selectedValue);
                onChange && onChange(selectedValue);
              }}
              disabled={disabled || suggestions?.length === 0}
              className={`text-600 ${!field.value && fieldState?.invalid ? 'p-invalid' : ''}`}
              style={{
                textAlign: 'center',
                width: '100%',
              }}
              dropdown
              onKeyDown={handleKeyDown} 
              onFocus={handleFocus}
             
            />
          )}
        />
      </span>
      {errorMsg && (
        <Message
          className="mt-1 flex"
          style={{
            borderWidth: '0 0 0 1px',
          }}
          severity="error"
          content={errorMsg}
        />
      )}
    </div>
  );
};

export default MzAutoComplete;
// import React, { useState, useEffect, useRef } from 'react';
// import { AutoComplete } from 'primereact/autocomplete';
// import { Controller } from 'react-hook-form';
// import { Message } from 'primereact/message';
// import { classNames } from 'primereact/utils';

// const MzAutoComplete = ({
//   control,
//   name,
//   disabled,
//   rules,
//   labelClassName,
//   label,
//   onChange,
//   errorMsg,
//   placeholder,
//   suggestions,
//   optionLabel,
//   optionValue,
//   isError,
//   value,
//   ...rest
// }) => {
//   const [filteredSuggestions, setFilteredSuggestions] = useState([]);
//   const [inputValue, setInputValue] = useState(value || '');

//   const autoCompleteRef = useRef(null);

//   useEffect(() => {
//     setInputValue(value || '');
//   }, [value]);

//   const getLabelClassName = () => {
//     return classNames({
//       'p-error': isError,
//       labelClassName,
//     });
//   };

//   const search = (event) => {
//     const query = event.query;

//     if (!query.trim().length) {
//       setFilteredSuggestions([...suggestions]);
//     } else {
//       const _filteredSuggestions = suggestions.filter((item) =>
//         item[optionLabel].toLowerCase().includes(query.toLowerCase())
//       );
//       setFilteredSuggestions(_filteredSuggestions);
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Tab') {
//       return;
//     }

//     if (e.key === 'ArrowDown') {
//       e.preventDefault();
//       if (filteredSuggestions.length > 0) {
//         const selectedItem = filteredSuggestions[0];
//         setInputValue(selectedItem[optionLabel]);
//         onChange && onChange(selectedItem[optionValue]);
//       }
//     }
//   };

//   const handleFocus = () => {
//     setFilteredSuggestions([...suggestions]);
//     autoCompleteRef.current.show();
//   };

//   return (
//     <div className="field" style={{ textAlign: 'left' }}>
//       <label htmlFor={name} className={getLabelClassName()}>
//         {label}
//         {rules?.required ? <span className="p-error">*</span> : null}
//       </label>
//       <span>
//         <Controller
//           name={name}
//           control={control}
//           rules={rules}
//           render={({ field, fieldState }) => (
//             <AutoComplete
//               ref={autoCompleteRef}
//               id={field.name}
//               name={field.name}
//               value={inputValue} 
//               suggestions={filteredSuggestions}
//               loading={suggestions?.length === 0}
//               placeholder={!inputValue ? placeholder : ''}
//               completeMethod={search}
//               field={optionLabel}
//               onChange={(e) => {
//                 const selectedValue = e.value ? e.value[optionValue] : null;
//                 setInputValue(e.value || '');
//                 field.onChange(selectedValue);
//                 onChange && onChange(selectedValue);
//               }}
//               disabled={disabled || suggestions?.length === 0}
//               className={`text-600 ${!field.value && fieldState?.invalid ? 'p-invalid' : ''}`}
//               style={{
//                 textAlign: 'center',
//                 width: '100%',
//               }}
//               dropdown
//               onKeyDown={handleKeyDown}
//               onFocus={handleFocus}
//               {...rest}
//             />
//           )}
//         />
//       </span>
//       {errorMsg && (
//         <Message
//           className="mt-1 flex"
//           style={{
//             borderWidth: '0 0 0 1px',
//           }}
//           severity="error"
//           content={errorMsg}
//         />
//       )}
//     </div>
//   );
// };

// export default MzAutoComplete;