import {
  INIT_USER,
  FETCH_USER_LIST,
  FETCH_USER_RECORD,
  CREATE_USER_RECORD,
  DELETE_USER,
  UPDATE_USER_RECORD
  // GET_USER_COUNT,
} from '../../../constant/actionTypes/user'

import { userService } from '../../../services'

export const initUser = () => ({
  type: INIT_USER
})

export const fetchUserListStart = (payload) => ({
  type: FETCH_USER_LIST.START,
  payload
})

export const fetchUserListSuccess = (users) => ({
  type: FETCH_USER_LIST.SUCCESS,
  payload: users
})

export const fetchUserListError = (error) => ({
  type: FETCH_USER_LIST.ERROR,
  payload: { error }
})

export const fetchUserList = (payload) => {
  return (dispatch) => {
    dispatch(fetchUserListStart(payload))
    userService.fetchUserList(payload).then((userData) => {
      if (!userData.isError) {
        dispatch(fetchUserListSuccess(userData))
      } else {
        dispatch(fetchUserListError(userData))
      }
    })
  }
}

export const createUserRecordStart = () => ({
  type: CREATE_USER_RECORD.START
})

export const createUserRecordSuccess = (newUser) => ({
  type: CREATE_USER_RECORD.SUCCESS,
  payload: newUser
})

export const createUserRecordError = (error) => ({
  type: CREATE_USER_RECORD.ERROR,
  payload: { error }
})

export const createUserRecord = (payload) => {
  return (dispatch) => {
    dispatch(createUserRecordStart(payload))
    userService.createUser(payload).then((userData) => {
      if (!userData.isError) {
        dispatch(createUserRecordSuccess(userData))
      } else {
        dispatch(createUserRecordError(userData))
      }
    })
  }
}

export const updateUserRecordStart = (payload, id) => ({
  type: UPDATE_USER_RECORD.START,
  payload,
  id
})

export const updateUserRecordSuccess = (newUser) => ({
  type: UPDATE_USER_RECORD.SUCCESS,
  payload: newUser
})

export const updateUserRecordError = (error) => ({
  type: UPDATE_USER_RECORD.ERROR,
  payload: { error }
})

export const updateUserRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateUserRecordStart(payload, id))
    userService.updateUserRecord(payload, id).then((userData) => {
      if (!userData.isError) {
        dispatch(updateUserRecordSuccess(userData))
      } else {
        dispatch(updateUserRecordError(userData))
      }
    })
  }
}

export const deleteUserStart = () => ({
  type: DELETE_USER.START
})

export const deleteUserSuccess = (userId) => ({
  type: DELETE_USER.SUCCESS,
  payload: { userId }
})

export const deleteUserError = (error) => ({
  type: DELETE_USER.ERROR,
  payload: { error }
})

export const deleteUser = (userId) => {
  return (dispatch) => {
    dispatch(deleteUserStart())
    userService.deleteUser(userId).then((userData) => {
      if (!userData.isError) {
        dispatch(deleteUserSuccess(userData))
      } else {
        dispatch(deleteUserError(userData))
      }
    })
  }
}

export const fetchUserRecordStart = () => ({
  type: FETCH_USER_RECORD.START
})

export const fetchUserRecordSuccess = (user) => ({
  type: FETCH_USER_RECORD.SUCCESS,
  payload: user
})

export const fetchUserRecordError = (error) => ({
  type: FETCH_USER_RECORD.ERROR,
  payload: { error }
})

export const fetchUserRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchUserRecordStart(payload))
    userService.fetchUserRecord(payload).then((userData) => {
      if (!userData.isError) {
        dispatch(fetchUserRecordSuccess(userData))
      } else {
        dispatch(fetchUserRecordError(userData))
      }
    })
  }
}
