import { INIT_LOGIN, LOGIN, LOGOUT } from '../../../constant/actionTypes/auth'
import { authService } from '../../../services'
import { decodeToken } from '../../../utils/decodeToken'
// import { ToastSuccess, toastSuccess } from "../toast";
import { fetchUserProfile, initialUserProfileScreen } from '../userProfile'

export const initLoginScreen = () => {
  return {
    type: INIT_LOGIN
  }
}

export const loginStart = (payload) => {
  return {
    type: LOGIN.START,
    payload
  }
}

export const loginSuccess = (payload) => {
  return {
    type: LOGIN.SUCCESS,
    payload
  }
}

export const loginError = (payload) => {
  return {
    type: LOGIN.ERROR,
    payload
  }
}

export const login = (payload) => {
  return (dispatch) => {
    dispatch(loginStart(payload))
    authService.postLogin(payload).then((userData) => {
      if (!userData.isError) {
        localStorage.setItem('isLoggedIn', true)
        localStorage.setItem('token', userData.id_token)
        const decodeT = decodeToken(userData?.id_token ?? '')
        const userRole = decodeT?.auth ?? ''
        localStorage.setItem('userRole', userRole)
        dispatch(loginSuccess(userRole))
        dispatch(fetchUserProfile())
      } else {
        dispatch(loginError(userData))
      }
    })
  }
}

export const logoutStart = () => {
  return {
    type: LOGOUT.START
  }
}

export const logoutSuccess = () => {
  return {
    type: LOGOUT.SUCCESS
  }
}

export const logoutError = (payload) => {
  return {
    type: LOGOUT.ERROR,
    payload
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutStart())
    authService
      .postLogout()
      .then((response) => {
        if (!response.isError) {
          localStorage.clear()
          dispatch(logoutSuccess())
          dispatch(initialUserProfileScreen())
          window.location.reload()
          window.location.origin()
        } else {
          dispatch(logoutError(response))
        }
      })
      .catch((error) => {
        dispatch(logoutError(error))
      })
  }
}
