import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
// import { USER_ROLE } from "../../constant/actionTypes/role";

export const fetchDistrictList = async (payload) => {
  let url
  if (!payload) {
    url = `${baseUrl}${API_PATH.DISTRICT.FETCH}`
  } else {
    url = `${baseUrl}${API_PATH.DISTRICT.FETCH_BY_STATE}/${payload}`
  }
  if (payload && typeof payload === 'number') {
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${token}`,
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}
else{
  return handleAPIError("error")
}
}

export const fetchDistrictRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.DISTRICT.FETCH}/${payload}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const createDistrictRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.DISTRICT.ADD}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const updateDistrictRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.DISTRICT.EDIT}/${id}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const deleteDistrict = async (districtId) => {
  const url = `${baseUrl}${API_PATH.DISTRICT.DELETE}/${districtId}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const getDistrictCount = async (role, payload) => {
  let url = ''
  // if(USER_ROLE.ADMIN === role){
  url = `${baseUrl}${API_PATH.DISTRICT.COUNT}?tenantId.equals=${payload}`
  // }else{
  //   url = `${baseUrl}${API_PATH.DISTRICT.COUNT}?userId.equals=${payload}`;
  // }

  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}
