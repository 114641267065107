import React from 'react'
import PrivacyPolicyComponent from '../../components/privacyPolicy'

function PrivacyPolicyScreen() {
  return (
    <div>
      <PrivacyPolicyComponent />
    </div>
  )
}

export default PrivacyPolicyScreen
