export const INIT_SUB_DISTRICT = 'INIT_SUB_DISTRICT'

export const FETCH_SUB_DISTRICT_LIST = {
  START: 'FETCH_SUB_DISTRICT_START',
  SUCCESS: 'FETCH_SUB_DISTRICT_SUCCESS',
  ERROR: 'FETCH_SUB_DISTRICT_ERROR'
}

export const FETCH_SUB_DISTRICT_RECORD = {
  START: 'FETCH_SUB_DISTRICT_RECORD_START',
  SUCCESS: 'FETCH_SUB_DISTRICT_RECORD_SUCCESS',
  ERROR: 'FETCH_SUB_DISTRICT_RECORD_ERROR'
}

export const CREATE_SUB_DISTRICT_RECORD = {
  START: 'CREATE_SUB_DISTRICT_RECORD_START',
  SUCCESS: 'CREATE_SUB_DISTRICT_RECORD_SUCCESS',
  ERROR: 'CREATE_SUB_DISTRICT_RECORD_ERROR'
}

export const UPDATE_SUB_DISTRICT_RECORD = {
  START: 'UPDATE_SUB_DISTRICT_RECORD_START',
  SUCCESS: 'UPDATE_SUB_DISTRICT_RECORD_SUCCESS',
  ERROR: 'UPDATE_SUB_DISTRICT_RECORD_ERROR'
}

export const DELETE_SUB_DISTRICT = {
  START: 'DELETE_SUB_DISTRICT_START',
  SUCCESS: 'DELETE_SUB_DISTRICT_SUCCESS',
  ERROR: 'DELETE_SUB_DISTRICT_ERROR'
}

export const GET_SUB_DISTRICT_COUNT = {
  START: 'GET_SUB_DISTRICT_COUNT_START',
  SUCCESS: 'GET_SUB_DISTRICT_COUNT_SUCCESS',
  ERROR: 'GET_SUB_DISTRICT_COUNT_ERROR'
}
