export const FORM_FIELDS_NAME = {
  USERNAME: {
    name: 'email',
    label: 'Username',
    type: 'text',
    placeholder: 'Enter your username',
    rules: {
      required: 'Username is required',
      minLength: {
        value: 3,
        message: 'Username must be at least 3 characters'
      },
      maxLength: {
        value: 50,
        message: 'Username cannot exceed 50 characters'
      }
    }
  },
  PASSWORD: {
    name: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Enter your password',
    rules: {
      required: 'Password is required',
      minLength: {
        value: 4,
        message: 'Password must be at least 4 characters'
      },
      maxLength: {
        value: 40,
        message: 'Password cannot exceed 40 characters'
      }
    }
  },
  REMBERME: {
    name: 'rememberMe',
    label: 'Remember me',
    type: 'checkbox'
  }
}
