import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ROUTE_PATH } from '../constant/urlConstant'
import { useSelector } from 'react-redux'
import LayoutScreen from '../containers/layout'
import KmlUploadScreen from '../containers/kmlUpload'
import UserScreen from '../containers/userScreen'
import AddUserScreen from '../containers/userScreen/addUserScreen'
import LinkScreen from '../containers/linkScreen'
import AddLinkScreen from '../containers/linkScreen/addLinkScreen'
import { USER_ROLE } from '../constant/actionTypes/role'
import LocationScreen from '../containers/locationScreen'
import AccessScreen from '../containers/accessScreen'
import AddAccessScreen from '../containers/accessScreen/addAccessScreen'
import HomeScreen from '../containers/homesScreen'
import GmapScreen from '../containers/gMapScreen'
import AboutUsScreen from '../containers/aboutUSScreen'
import ContactUsScreen from '../containers/contactUsScreen'
import PrivacyPolicyScreen from '../containers/privacyPolicyScreen'
import RefundPolicyScreen from '../containers/refundPolicyScreen'
import TermsConditionScreen from '../containers/termsConditionScreen'
import EnquiryScreen from '../containers/enquiryScreen'
import ShippingPolicyScreen from '../containers/shippingPolicy'
import ResetPasswordComponent from '../components/resetPassword'
import ChangePassword from '../components/changePassword'
import UpdateMapScreen from '../containers/linkScreen/updateMapScreen'

const Routing = () => {
  const isLoggedIn = useSelector((state) => state.loginReducer?.isLoggedIn)
  const userRole = useSelector((state) => state.loginReducer?.userRole)
  const commonRoutes = (
    <>
      <Route path={ROUTE_PATH.BASE.HOME} element={<GmapScreen />} />

      <Route path={ROUTE_PATH.BASE.ABOUTUS} element={<AboutUsScreen />} />
      <Route path={ROUTE_PATH.BASE.CONTACTUS} element={<ContactUsScreen />} />
      <Route
        path={ROUTE_PATH.BASE.PRIVACYPOLICY}
        element={<PrivacyPolicyScreen />}
      />
      <Route
        path={ROUTE_PATH.BASE.REFUNDPOLICY}
        element={<RefundPolicyScreen />}
      />
      <Route
        path={ROUTE_PATH.BASE.TERMSCONDITION}
        element={<TermsConditionScreen />}
      />
      <Route
        path={ROUTE_PATH.BASE.SHIPPINGPOLICY}
        element={<ShippingPolicyScreen />}
      />
    </>
  )
  const getRoleBasedRoutes = () => {
    switch (userRole) {
      case USER_ROLE.SUPER_ADMIN:
        return (
          <>
            <Route
              path={ROUTE_PATH.FILE_UPLOAD.HOME}
              element={<KmlUploadScreen />}
            />
            <Route path={ROUTE_PATH.USER.HOME} element={<UserScreen />} />
            <Route path={ROUTE_PATH.USER.ADD} element={<AddUserScreen />} />
            <Route
              path={ROUTE_PATH.USER.EDIT}
              element={<AddUserScreen isEdit={true} />}
            />
            <Route path={ROUTE_PATH.LINK.HOME} element={<LinkScreen />} />
            <Route path={ROUTE_PATH.LINK.ADD} element={<AddLinkScreen />} />
            <Route
              path={ROUTE_PATH.LINK.EDIT}
              element={<AddLinkScreen isEdit={true} />}
            />
            <Route
              path={ROUTE_PATH.LINK.UPDATE}
              element={<UpdateMapScreen isEdit={true} />}
            />
            <Route path={ROUTE_PATH.ACCESS.HOME} element={<AccessScreen />} />
            <Route path={ROUTE_PATH.ACCESS.ADD} element={<AddAccessScreen />} />
            <Route
              path={ROUTE_PATH.ACCESS.EDIT}
              element={<AddAccessScreen isEdit={true} />}
            />
            <Route path={ROUTE_PATH.ENQUIRY.HOME} element={<EnquiryScreen />} />
          </>
        )
      case USER_ROLE.ADMIN:
      case USER_ROLE.SELLER:
        return (
          <>
            <Route path={ROUTE_PATH.USER.HOME} element={<UserScreen />} />
            <Route path={ROUTE_PATH.USER.ADD} element={<AddUserScreen />} />
            <Route
              path={ROUTE_PATH.USER.EDIT}
              element={<AddUserScreen isEdit={true} />}
            />
            <Route path={ROUTE_PATH.LINK.HOME} element={<LinkScreen />} />
            <Route path={ROUTE_PATH.LINK.ADD} element={<AddLinkScreen />} />
            <Route
              path={ROUTE_PATH.LINK.EDIT}
              element={<AddLinkScreen isEdit={true} />}
            />
          </>
        )
      case USER_ROLE.USER:
        return (
          <>
            <Route path={ROUTE_PATH.LINK.HOME} element={<LinkScreen />} />
            <Route path={ROUTE_PATH.LINK.ADD} element={<AddLinkScreen />} />
          </>
        )
      default:
        return null
    }
  }
  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path={ROUTE_PATH.BASE.HOME} element={<HomeScreen />}>
          {commonRoutes}
        </Route>
        <Route
          path={ROUTE_PATH.BASE.RESET_PASSWORD}
          element={<ResetPasswordComponent />}
        />

        <Route path="*" element={<Navigate to={ROUTE_PATH.BASE.HOME} />} />
      </Routes>
    )
  }
  return (
    <Routes>
      <Route
        path={ROUTE_PATH.BASE.CHANGE_PASSWORD}
        element={<ChangePassword />}
      />
      <Route path={ROUTE_PATH.BASE.HOME} element={<HomeScreen />}>
        {commonRoutes}
      </Route>
      <Route path={ROUTE_PATH.DASHBOARD.HOME} element={<LayoutScreen />}>
        <Route
          index
          element={
            userRole === USER_ROLE.USER ? (
              <Navigate to={ROUTE_PATH.LINK.HOME} />
            ) : (
              <Navigate to={ROUTE_PATH.USER.HOME} />
            )
          }
        />
        <Route path={ROUTE_PATH.LOCATION.HOME} element={<LocationScreen />} />
        <Route path={ROUTE_PATH.LOCATION.HOME1} element={<LocationScreen />} />
        {getRoleBasedRoutes()}
        <Route path="*" element={<Navigate to={ROUTE_PATH.DASHBOARD.HOME} />} />
      </Route>

      <Route path="*" element={<Navigate to={ROUTE_PATH.BASE.HOME} />} />
    </Routes>
  )
}
export default Routing
