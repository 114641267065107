import React, { useEffect } from 'react'
import PaymentComponent from '../../components/payment'
import { fetchPlanList, initPayment } from '../../redux/action/payment'
import { connect } from 'react-redux'
import { ProgressBar } from 'primereact/progressbar'

const PaymentScreen = (props) => {
  const {
    fetchPlanList,
    initPayment,
    plansList,
    isLoading,
    isPageLevelError,
    userRole,
    user,
    error,
    plot,
    handlePayment
  } = props
  useEffect(() => {
    initPayment()
    fetchPlanList()
    // eslint-disable-next-line
  }, [])

  const paymentProps = {
    plansList,
    isPageLevelError,
    isLoading,
    userRole,
    user,
    error,
    plot,
    handlePayment
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <PaymentComponent paymentProps={paymentProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initPayment: () => dispatch(initPayment()),
    fetchPlanList: (payload) => dispatch(fetchPlanList(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.paymentReducer.isPageLevelError,
    isLoading: state.paymentReducer.isLoading,
    error: state.paymentReducer.error,
    plansList: state.paymentReducer.plansList,
    userRole: state.loginReducer.userRole,
    user: state.userProfileReducer.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen)
