import {
  INIT_ACCESS,
  FETCH_ACCESS_LIST,
  FETCH_ACCESS_RECORD,
  CREATE_ACCESS_RECORD,
  DELETE_ACCESS,
  UPDATE_ACCESS_RECORD,
  GET_COUNT_ACCESS
} from '../../../constant/actionTypes/access'

const formFieldValueMap = {
  name: '',
  description: '',
  image: null,
  video: null
}

const initialState = {
  accessList: null,
  accessCount: 0,
  selectedAccess: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateAccessSuccess: false,
  isEditAccessSuccess: false,
  isAccessDetailSuccess: false,
  isDeleteAccessSuccess: false,
  isDeleteAccessError: false,
  isAccessDetailError: false,
  isCreateAccessError: false,
  isEditAccessError: false
}

const accessReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_ACCESS:
      return {
        ...state,
        formFieldValueMap: null,
        accessList: [],
        selectedAccess: null,
        isLoading: false,
        error: null
      }
    case FETCH_ACCESS_LIST.START:
    case FETCH_ACCESS_RECORD.START:
    case CREATE_ACCESS_RECORD.START:
    case DELETE_ACCESS.START:
    case UPDATE_ACCESS_RECORD.START:
    case GET_COUNT_ACCESS.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateAccessSuccess: false,
        isEditAccessSuccess: false,
        isAccessDetailSuccess: false,
        isDeleteAccessSuccess: false,
        isDeleteAccessError: false,
        isAccessDetailError: false,
        isCreateAccessError: false,
        isEditAccessError: false
      }
    case FETCH_ACCESS_LIST.SUCCESS:
      return {
        ...state,
        accessList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_COUNT_ACCESS.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        accessCount: action.payload
      }
    }
    case GET_COUNT_ACCESS.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        accessCount: 0
      }
    }
    case FETCH_ACCESS_RECORD.SUCCESS:
      return {
        ...state,
        selectedAccess: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          tenant: action?.payload?.tenant?.id ?? '',
          username: action?.payload?.username ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          // authorities: action?.payload?.authorities?[0]?.name ?? "",
          department: action?.payload?.department?.id ?? '',
          profilePicture: action?.payload?.profilePicture ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobileNumber: action?.payload?.mobileNumber ?? ''
        },
        isLoading: false,
        error: null,
        isAccessDetailSuccess: true
      }
    case CREATE_ACCESS_RECORD.SUCCESS:
      return {
        ...state,
        accessList: [...state.accessList, action.payload],
        isLoading: false,
        error: null,
        isCreateAccessSuccess: true
      }
    case UPDATE_ACCESS_RECORD.SUCCESS:
      return {
        ...state,
        accessList: state.accessList.map((access) =>
          access.id === action.payload.id ? action.payload : access
        ),
        isLoading: false,
        error: null,
        isEditAccessSuccess: true
      }
    case DELETE_ACCESS.SUCCESS:
      return {
        ...state,
        accessList: state.accessList.filter(
          (access) => access.id !== action.payload.accessId
        ),
        isLoading: false,
        error: null,
        isDeleteAccessSuccess: true
      }
    case FETCH_ACCESS_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_ACCESS_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isAccessDetailError: true
      }
    case CREATE_ACCESS_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateAccessError: true
      }

    case UPDATE_ACCESS_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditAccessError: true
      }
    case DELETE_ACCESS.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteAccessError: true
      }

    default:
      return state
  }
}
export default accessReducer
