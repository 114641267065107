import React from 'react'
import './index.css'
const TermsConditionComponent = () => {
  return (
    <div className="page-information-container  md:px-7 mt-8  mb-3 lg:mb-7  mr-4 ml-4  md:mr-6 md:ml-6 lg:mr-7 lg:ml-7">
      <div className="mb-3">
        <h1 className="font-medium text-2xl mt-5" >
          Terms and Conditions
        </h1> 

      </div> 

      <p className="line-height-2">
          Effective Date: January 1, 2024 <br />
          Welcome to Bhoomi22, operated by Clearcole Solution. These Terms and Conditions govern your use of our website 
          {'   '}{' '}
            <a href="https://bhoomi22.com/" className="text-blue-500">
            www.bhoomi22.com
            </a>{' '}      
          and the services provided.
      </p>

      <h2 className=" page-info-subheading font-medium text-lg">
        1.Acceptance of Terms
      </h2> 
      <p className="line-height-2">
        By accessing or using our website and services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our services.
      </p>

      <h2 className=" page-info-subheading font-medium text-lg">
        2.User Responsibilities
      </h2>
      <p className="line-height-2">
        You must provide accurate information when creating an account or placing an order.
        You agree not to misuse the Site by introducing viruses or attempting to access restricted areas.
        You are responsible for maintaining the confidentiality of your account and password.

      </p> 

      <h2 className=" page-info-subheading font-medium text-lg">
          3. Payment Terms
      </h2>
      <p className="line-height-2">
          All purchases made through Bhoomi22 are subject to our payment terms.
          We accept payments through credit/debit cards and other authorized payment gateways. Prices are listed in INR (Indian Rupees) and are subject to change without notice.
          Access to products or services will be granted only upon successful completion of payment.


      </p>

      <h2 className=" page-info-subheading font-medium text-lg">
        4. Intellectual Property
      </h2>
      <p className="line-height-2">
        All content on the Site, including text, images, and software, is the intellectual property of Clearcole Solution. Unauthorized use, reproduction, or distribution of our content is prohibited.
      </p>

      

      <h2 className=" page-info-subheading font-medium text-lg">
         5. Limitation of Liability
      </h2>

      <p className="line-height-2">
     
        Clearcole Solution will not be held liable for any direct, indirect, incidental, or consequential damages arising from your use of our services or inability to access our website.

      </p>

      
      <h2 className=" page-info-subheading font-medium text-lg">
          6. Changes to Terms
      </h2>
      <p className="line-height-2">
     
         We reserve the right to amend these Terms and Conditions at any time. It is your responsibility to review this page periodically for updates.

      </p>

      <h2 className=" page-info-subheading font-medium text-lg">
         7. Governing Law
      </h2>
      <p className="line-height-2">
     
          These Terms shall be governed by the laws of India, and any disputes will be subject to the exclusive jurisdiction of the courts of [your city/state].

      </p>

    </div>
  )
}

export default TermsConditionComponent
