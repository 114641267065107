import {
  INIT_LINK,
  FETCH_LINK_LIST,
  FETCH_LINK_RECORD,
  CREATE_LINK_RECORD,
  DELETE_LINK,
  UPDATE_LINK_RECORD,
  GET_LINK_COUNT
} from '../../../constant/actionTypes/link'

const formFieldValueMap = {
  id: '',
  state: '',
  district: '',
  taluka: '',
  village:  '',
  plotNumber:  '',
  name: '',
  usageLimit: '',
}

const initialState = {
  linksList: null,
  linkCount: 0,
  selectedLink: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isPageLevelError: false,
  isCreateLinkSuccess: false,
  isEditLinkSuccess: false,
  isLinkDetailSuccess: false,
  isDeleteLinkSuccess: false,
  isDeleteLinkError: false,
  isLinkDetailError: false,
  isCreateLinkError: false,
  isEditLinkError: false
}

const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LINK:
      return {
        ...state,
        formFieldValueMap: null,
        linksList: [],
        selectedLink: null,
        isLoading: false,
        error: null
      }
    case FETCH_LINK_LIST.START:
    case FETCH_LINK_RECORD.START:
    case CREATE_LINK_RECORD.START:
    case DELETE_LINK.START:
    case UPDATE_LINK_RECORD.START:
    case GET_LINK_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateLinkSuccess: false,
        isEditLinkSuccess: false,
        isLinkDetailSuccess: false,
        isDeleteLinkSuccess: false,
        isDeleteLinkError: false,
        isLinkDetailError: false,
        isCreateLinkError: false,
        isEditLinkError: false
      }
    case FETCH_LINK_LIST.SUCCESS:
      return {
        ...state,
        linksList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_LINK_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        linkCount: action.payload
      }
    }
    case GET_LINK_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        linkCount: 0
      }
    }
    case FETCH_LINK_RECORD.SUCCESS:
      return {
        ...state,
        selectedLink: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          state: action?.payload?.plot?.village?.taluka?.district?.state.name ?? '',
          district: action?.payload?.plot?.village?.taluka?.district?.name ?? '',
          taluka: action?.payload?.plot?.village?.taluka?.name ?? '',
          village: action?.payload?.plot?.village?.name ?? '',
          plotNumber: action?.payload?.plot?.plotNumber ?? '',
          name: action?.payload?.user?.name ?? '',
          usageLimit: action?.payload?.usageLimit ?? '',
        },
        isLoading: false,
        error: null,
        isLinkDetailSuccess: true
      }
    case CREATE_LINK_RECORD.SUCCESS:
      return {
        ...state,
        linksList: [...state.linksList, action.payload],
        isLoading: false,
        error: null,
        isCreateLinkSuccess: true
      }
    case UPDATE_LINK_RECORD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isEditLinkSuccess: true
      }
    case DELETE_LINK.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isDeleteLinkSuccess: true
      }
    case FETCH_LINK_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_LINK_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isLinkDetailError: true
      }
    case CREATE_LINK_RECORD.ERROR:  
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateLinkError: true
      }

    case UPDATE_LINK_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditLinkError: true
      }
    case DELETE_LINK.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteLinkError: true
      }

    default:
      return state
  }
}

export default linkReducer
