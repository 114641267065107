import {
  INIT_CONTACTUS,
  FETCH_CONTACTUS_LIST,
  CREATE_CONTACTUS_RECORD
} from '../../../constant/actionTypes/contactUs'

import { contactUsService } from '../../../services'

export const initcontactUs = () => ({
  type: INIT_CONTACTUS
})

export const fetchcontactUsListStart = () => ({
  type: FETCH_CONTACTUS_LIST.START
})

export const fetchcontactUsListSuccess = (contactUs) => ({
  type: FETCH_CONTACTUS_LIST.SUCCESS,
  payload: contactUs
})

export const fetchcontactUsListError = (error) => ({
  type: FETCH_CONTACTUS_LIST.ERROR,
  payload: { error }
})

export const fetchcontactUsList = (payload) => {
 
  return (dispatch) => {
    dispatch(fetchcontactUsListStart(payload))
    contactUsService.fetchContactUsList(payload).then((contactUsData) => {
      if (!contactUsData.isError) {
        dispatch(fetchcontactUsListSuccess(contactUsData))
      } else {
        dispatch(fetchcontactUsListError(contactUsData))
      }
    })
  }
}

export const createcontactUsRecordStart = () => ({
  type: CREATE_CONTACTUS_RECORD.START
})

export const createcontactUsRecordSuccess = (newcontactUs) => ({
  type: CREATE_CONTACTUS_RECORD.SUCCESS,
  payload: newcontactUs
})

export const createcontactUsRecordError = (error) => ({
  type: CREATE_CONTACTUS_RECORD.ERROR,
  payload: { error }
})

export const createcontactUsRecord = (payload) => {
  return (dispatch) => {
    dispatch(createcontactUsRecordStart(payload))
    contactUsService.createContactUsRecord(payload).then((contactUsData) => {
      if (!contactUsData.isError) {
        dispatch(createcontactUsRecordSuccess(contactUsData))
      } else {
        dispatch(createcontactUsRecordError(contactUsData))
      }
    })
  }
}
