import { API_PATH } from '../../constant/urlConstant'
import { baseUrl } from '../PostAPI'
// import { APIError, handleAPIError } from "../common/errorHandler";
import { decodeToken } from '../../utils/decodeToken/index.js'
import axios from 'axios'

export const getDecodedToken = () => {
  const token = localStorage.getItem('token')
  if (!token) return null
  const decodedToken = decodeToken(token)
  return decodedToken
}

export const fetchUserProfile = async () => {
  // const decodedToken = getDecodedToken();
  // if (!decodedToken) {
  //   return {
  //     isError: true,
  //     message: "No token found",
  //   };
  // }
  // const username = decodedToken.sub;
  // if (!username) {
  //   return {
  //     isError: true,
  //     message: "Username not found in token",
  //   };
  // }

  try {
    const token = localStorage.getItem('token')
    const result = await axios.get(`${baseUrl}${API_PATH.USER_PROFILE.FETCH}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
      // params: {
      //   "username.equals": username,
      // },
    })
    if (result.status === 200) {
      return result.data
    } else {
      return {
        isError: true,
        message: 'Error fetching user data'
      }
    }
  } catch (error) {
    return {
      isError: true,
      message: error.message
    }
  }
}
