import {
  INIT_USER,
  FETCH_USER_LIST,
  FETCH_USER_RECORD,
  CREATE_USER_RECORD,
  DELETE_USER,
  UPDATE_USER_RECORD,
  GET_USER_COUNT
} from '../../../constant/actionTypes/user'

const formFieldValueMap = {
  name: '',
  email: '',
  password: '',
  authorities: '',
  mobile: '',
  firstName: '',
  lastName: ''
}

const initialState = {
  userList:null,
  userCount: 0,
  selectedUser: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateUserSuccess: false,
  isEditUserSuccess: false,
  isUserDetailSuccess: false,
  isDeleteUserSuccess: false,
  isDeleteUserError: false,
  isUserDetailError: false,
  isCreateUserError: false,
  isEditUserError: false
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_USER:
      return {
        ...state,
        formFieldValueMap: null,
        userList: [],
        selectedUser: null,
        isLoading: false,
        error: null
      }
    case FETCH_USER_LIST.START:
    case FETCH_USER_RECORD.START:
    case CREATE_USER_RECORD.START:
    case DELETE_USER.START:
    case UPDATE_USER_RECORD.START:
    case GET_USER_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateUserSuccess: false,
        isEditUserSuccess: false,
        isUserDetailSuccess: false,
        isDeleteUserSuccess: false,
        isDeleteUserError: false,
        isUserDetailError: false,
        isCreateUserError: false,
        isEditUserError: false
      }
    case FETCH_USER_LIST.SUCCESS:
      return {
        ...state,
        userList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_USER_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        userCount: action.payload
      }
    }
    case GET_USER_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        userCount: 0
      }
    }
    case FETCH_USER_RECORD.SUCCESS:
      return {
        ...state,
        selectedUser: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          name: action?.payload?.name ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          authorities: action?.payload?.authorities[0]?.name ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobile: action?.payload?.mobile ?? ''
        },
        isLoading: false,
        error: null,
        isUserDetailSuccess: true
      }
    case CREATE_USER_RECORD.SUCCESS:
      return {
        ...state,
        userList: [...state.userList, action.payload],
        isLoading: false,
        error: null,
        isCreateUserSuccess: true
      }
    case UPDATE_USER_RECORD.SUCCESS:
      return {
        ...state,
        userList: state.userList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
        isLoading: false,
        error: null,
        isEditUserSuccess: true
      }
    case DELETE_USER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isDeleteUserSuccess: true
      }
    case FETCH_USER_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_USER_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isUserDetailError: true
      }
    case CREATE_USER_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateUserError: true
      }

    case UPDATE_USER_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditUserError: true
      }
    case DELETE_USER.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteUserError: true
      }

    default:
      return state
  }
}

export default userReducer
