import {
  INIT_PROVINCE,
  FETCH_PROVINCE_LIST,
  FETCH_PROVINCE_RECORD,
  CREATE_PROVINCE_RECORD,
  DELETE_PROVINCE,
  UPDATE_PROVINCE_RECORD,
  GET_PROVINCE_COUNT
} from '../../../constant/actionTypes/province'

const formFieldValueMap = {
  tenant: '',
  username: '',
  email: '',
  password: '',
  authorities: '',
  department: '',
  profilePicture: null
}

const initialState = {
  provincesList: [],
  provinceCount: 0,
  selectedProvince: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateProvinceSuccess: false,
  isEditProvinceSuccess: false,
  isProvinceDetailSuccess: false,
  isDeleteProvinceSuccess: false,
  isDeleteProvinceError: false,
  isProvinceDetailError: false,
  isCreateProvinceError: false,
  isEditProvinceError: false
}

const provinceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_PROVINCE:
      return {
        ...state,
        formFieldValueMap: null,
        provincesList: [],
        selectedProvince: null,
        isLoading: false,
        error: null
      }
    case FETCH_PROVINCE_LIST.START:
    case FETCH_PROVINCE_RECORD.START:
    case CREATE_PROVINCE_RECORD.START:
    case DELETE_PROVINCE.START:
    case UPDATE_PROVINCE_RECORD.START:
    case GET_PROVINCE_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateProvinceSuccess: false,
        isEditProvinceSuccess: false,
        isProvinceDetailSuccess: false,
        isDeleteProvinceSuccess: false,
        isDeleteProvinceError: false,
        isProvinceDetailError: false,
        isCreateProvinceError: false,
        isEditProvinceError: false
      }
    case FETCH_PROVINCE_LIST.SUCCESS:
      return {
        ...state,
        provincesList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_PROVINCE_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        provinceCount: action.payload
      }
    }
    case GET_PROVINCE_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        provinceCount: 0
      }
    }
    case FETCH_PROVINCE_RECORD.SUCCESS:
      return {
        ...state,
        selectedProvince: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          tenant: action?.payload?.tenant?.id ?? '',
          username: action?.payload?.username ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          authorities: action?.payload?.authorities[0]?.name ?? '',
          department: action?.payload?.department?.id ?? '',
          profilePicture: action?.payload?.profilePicture ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobileNumber: action?.payload?.mobileNumber ?? ''
        },
        isLoading: false,
        error: null,
        isProvinceDetailSuccess: true
      }
    case CREATE_PROVINCE_RECORD.SUCCESS:
      return {
        ...state,
        provincesList: [...state.provincesList, action.payload],
        isLoading: false,
        error: null,
        isCreateProvinceSuccess: true
      }
    case UPDATE_PROVINCE_RECORD.SUCCESS:
      return {
        ...state,
        provincesList: state.provincesList.map((province) =>
          province.id === action.payload.id ? action.payload : province
        ),
        isLoading: false,
        error: null,
        isEditProvinceSuccess: true
      }
    case DELETE_PROVINCE.SUCCESS:
      return {
        ...state,
        provincesList: state.provincesList.filter(
          (province) => province.id !== action.payload.provinceId
        ),
        isLoading: false,
        error: null,
        isDeleteProvinceSuccess: true
      }
    case FETCH_PROVINCE_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_PROVINCE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isProvinceDetailError: true
      }
    case CREATE_PROVINCE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateProvinceError: true
      }

    case UPDATE_PROVINCE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditProvinceError: true
      }
    case DELETE_PROVINCE.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteProvinceError: true
      }

    default:
      return state
  }
}

export default provinceReducer
