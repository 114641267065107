import React from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import { Message } from 'primereact/message'

const MzCheckbox = ({
  control,
  name,
  rules,
  label,
  isError,
  errorMsg,
  wrapperClass,
  checkboxStyle
}) => {
  return (
    <div className={wrapperClass}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <div className='flex  justify-content-between align-items-center'>
            <Checkbox
              inputId={field.name}
              checked={field.value}
              onChange={(e) => field.onChange(e.checked)}
              className={classNames({ 'p-invalid': fieldState.invalid })}
              style={checkboxStyle}
            />
            <label
              htmlFor={field.name}
              className={classNames('ml-2', { 'p-error': isError })}
            >
              {label}
            </label>
          </div>
        )}
      />
      {errorMsg && (
        <Message
          className="mt-1 flex"
          style={{
            borderWidth: '0 0 0 2px'
          }}
          severity="error"
          content={errorMsg}
        />
      )}
    </div>
  )
}

// MzCheckbox.defaultProps = {
//   wrapperClass: "",
//   checkboxStyle: {},
// };

export default MzCheckbox
