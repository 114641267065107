import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from '../constant'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import { Fieldset } from 'primereact/fieldset'
import MzMultiSelect from '../../../common/MzForm/MzMultiSelect'

const AddAccessComponent = (props) => {
  const {
    createAccessRecord,
    formFieldValueMap,
    isLoading,
    isPageLevelError,
    isEdit,
    isCreateAccessSuccess,
    isEditAccessSuccess,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    userList,
    fetchAccessRecord
  } = props.accessProps

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const history = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    if (isCreateAccessSuccess || isEditAccessSuccess) {
      setTimeout(() => {
        reset()
        navigatetoAccess()
      }, 1000)
    }
    // eslint-disable-next-line
  }, [isCreateAccessSuccess, isEditAccessSuccess])

  useEffect(() => {
    if (isEdit && id) {
      fetchAccessRecord(id)
    } else {
      reset(formFieldValueMap)
    }
    // eslint-disable-next-line
  }, [isEdit, id])
  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.PROVINCE.name)) {
      fetchDistrictList(watch(FORM_FIELDS_NAME.PROVINCE.name))
      setValue(FORM_FIELDS_NAME.DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.PROVINCE.name), fetchDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.SALESPERSON.name)) {
      fetchDistrictList(watch(FORM_FIELDS_NAME.PROVINCE.name))
      setValue(FORM_FIELDS_NAME.DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.PROVINCE.name), fetchDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.DISTRICT.name)) {
      fetchSubDistrictList(watch(FORM_FIELDS_NAME.DISTRICT.name))
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.DISTRICT.name), fetchSubDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.SUB_DISTRICT.name)) {
      fetchVillageList(watch(FORM_FIELDS_NAME.SUB_DISTRICT.name))
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.SUB_DISTRICT.name), fetchVillageList, setValue])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }

  const navigatetoAccess = () => {
    history(ROUTE_PATH.ACCESS.HOME)
  }
  const onSubmit = (data) => {
    let payload

    payload = {
      //   state: {
      //     id: data.state,
      //   },
      //   district: {
      //     id: data.district,
      //   },
      //   taluka: {
      //     id: data.taluka,
      //   },
      village: {
        id: data.village
      },
      user: {
        id: data.salesPerson
      },
      accessType: 'VILLAGE_FULLACCESS'
    }
    // payload.active = true;
    createAccessRecord(payload)
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'Access', command: () => history(ROUTE_PATH.ACCESS.HOME) },
      {
        label: isEdit ? 'Update Access' : 'Add Access',
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.ACCESS.EDIT.replace(':id', id)}`
              : ROUTE_PATH.ACCESS.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb
          model={breadCrumbItems}
          home={home}
          className=" border-0 "
        />
      </div>
    )
  }

  return (
    <div className="">
      {renderPageHeaderSection()}

      <div className="flex w-full bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0  ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-2 w-full px-4 md:mt-5"
        >
          <div className="card">
            <Fieldset legend="Location Details">
              <div className="grid">
                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.PROVINCE.name}
                    label={FORM_FIELDS_NAME.PROVINCE.label}
                    optionLabel={FORM_FIELDS_NAME.PROVINCE.optionLabel}
                    optionValue={FORM_FIELDS_NAME.PROVINCE.optionValue}
                    placeholder={FORM_FIELDS_NAME.PROVINCE.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.PROVINCE.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.PROVINCE.name
                    )}
                    rules={FORM_FIELDS_NAME.PROVINCE.rules}
                    suggestions={provincesList ?? []}
                    dropdown
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.DISTRICT.name}
                    label={FORM_FIELDS_NAME.DISTRICT.label}
                    optionLabel={FORM_FIELDS_NAME.DISTRICT.optionLabel}
                    optionValue={FORM_FIELDS_NAME.DISTRICT.optionValue}
                    placeholder={FORM_FIELDS_NAME.DISTRICT.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.DISTRICT.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.DISTRICT.name
                    )}
                    rules={FORM_FIELDS_NAME.DISTRICT.rules}
                    suggestions={districtsList ?? []}
                    dropdown
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.SUB_DISTRICT.name}
                    label={FORM_FIELDS_NAME.SUB_DISTRICT.label}
                    optionLabel={FORM_FIELDS_NAME.SUB_DISTRICT.optionLabel}
                    optionValue={FORM_FIELDS_NAME.SUB_DISTRICT.optionValue}
                    placeholder={FORM_FIELDS_NAME.SUB_DISTRICT.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.SUB_DISTRICT.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.SUB_DISTRICT.name
                    )}
                    rules={FORM_FIELDS_NAME.SUB_DISTRICT.rules}
                    suggestions={subDistrictsList ?? []}
                    dropdown
                  />
                </div>
                {/* <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.VILLAGE.name}
                    label={FORM_FIELDS_NAME.VILLAGE.label}
                    optionLabel={FORM_FIELDS_NAME.VILLAGE.optionLabel}
                    optionValue={FORM_FIELDS_NAME.VILLAGE.optionValue}
                    placeholder={FORM_FIELDS_NAME.VILLAGE.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.VILLAGE.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.VILLAGE.name
                    )}
                    rules={FORM_FIELDS_NAME.VILLAGE.rules}
                    suggestions={villagesList ?? []}
                    dropdown
                  />
                </div> */}
                <div className="col-12 md:col-6">
                  <MzMultiSelect
                    control={control}
                    name={FORM_FIELDS_NAME.VILLAGE.name}
                    label={FORM_FIELDS_NAME.VILLAGE.label}
                    optionLabel="name"
                    optionValue="id"
                    placeholder={FORM_FIELDS_NAME.VILLAGE.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.VILLAGE.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.VILLAGE.name
                    )}
                    rules={FORM_FIELDS_NAME.VILLAGE.rules}
                    suggestions={villagesList ?? []}
                  />
                </div>
              </div>
            </Fieldset>
          </div>

          <div className="card mt-3">
            <Fieldset legend="User Details">
              <div className="grid">
                <div className="col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.SALESPERSON.name}
                    label={FORM_FIELDS_NAME.SALESPERSON.label}
                    optionLabel={FORM_FIELDS_NAME.SALESPERSON.optionLabel}
                    optionValue={FORM_FIELDS_NAME.SALESPERSON.optionValue}
                    placeholder={FORM_FIELDS_NAME.SALESPERSON.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.SALESPERSON.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.SALESPERSON.name
                    )}
                    rules={FORM_FIELDS_NAME.SALESPERSON.rules}
                    suggestions={userList ?? []}
                    dropdown
                  />
                </div>
              </div>
            </Fieldset>
          </div>

          <div className="grid justify-content-center mt-3 mb-5">
            <div className=" col-12 md:col-4">
              <Button
                label="Cancel"
                className="w-full"
                severity="secondary"
                onClick={() => navigatetoAccess()}
                disabled={isLoading || isPageLevelError}
              ></Button>
            </div>
            <div className=" col-12 md:col-4">
              <Button
                type="submit"
                label="Submit"
                className="w-full"
                disabled={isLoading || isPageLevelError}
              ></Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddAccessComponent
