export const INIT_KML_UPLOAD = 'INIT_KML_UPLOAD'

export const FETCH_KML_UPLOAD_LIST = {
  START: 'FETCH_KML_UPLOAD_START',
  SUCCESS: 'FETCH_KML_UPLOAD_SUCCESS',
  ERROR: 'FETCH_KML_UPLOAD_ERROR'
}

export const FETCH_KML_UPLOAD_RECORD = {
  START: 'FETCH_KML_UPLOAD_RECORD_START',
  SUCCESS: 'FETCH_KML_UPLOAD_RECORD_SUCCESS',
  ERROR: 'FETCH_KML_UPLOAD_RECORD_ERROR'
}

export const CREATE_KML_UPLOAD_RECORD = {
  START: 'CREATE_KML_UPLOAD_RECORD_START',
  SUCCESS: 'CREATE_KML_UPLOAD_RECORD_SUCCESS',
  ERROR: 'CREATE_KML_UPLOAD_RECORD_ERROR'
}

export const UPDATE_KML_UPLOAD_RECORD = {
  START: 'UPDATE_KML_UPLOAD_RECORD_START',
  SUCCESS: 'UPDATE_KML_UPLOAD_RECORD_SUCCESS',
  ERROR: 'UPDATE_KML_UPLOAD_RECORD_ERROR'
}

export const DELETE_KML_UPLOAD = {
  START: 'DELETE_KML_UPLOAD_START',
  SUCCESS: 'DELETE_KML_UPLOAD_SUCCESS',
  ERROR: 'DELETE_KML_UPLOAD_ERROR'
}

export const GET_KML_UPLOAD_COUNT = {
  START: 'GET_KML_UPLOAD_COUNT_START',
  SUCCESS: 'GET_KML_UPLOAD_COUNT_SUCCESS',
  ERROR: 'GET_KML_UPLOAD_COUNT_ERROR'
}
