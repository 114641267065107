import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'
export const createContactUsRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CONTACTUS.ADD}`
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const fetchContactUsList = async (payload) => {
  let url
  const {pageSize, pageNumber } = payload ?? {}
   url = `${baseUrl}${API_PATH.CONTACTUS.FETCH}`
   let finalUrl= getDecoratedUrl({
     url,
     payload: { pageSize, pageNumber },
     shouldSort: false,
     shouldFilter: false
  })
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}