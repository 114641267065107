import React from 'react'
import AboutComponent from '../../components/aboutUs'

function AboutUsScreen() {
  return (
    <div>
      <AboutComponent />
    </div>
  )
}

export default AboutUsScreen
