import React from 'react'
import ShippingPolicy from '../../components/shippingPolicy'


function ShippingPolicyScreen() {
  return (
    <div>
      <ShippingPolicy/>
    </div>
  )
}

export default ShippingPolicyScreen
