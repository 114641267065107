import {
  INIT_PROVINCE,
  FETCH_PROVINCE_LIST,
  FETCH_PROVINCE_RECORD,
  CREATE_PROVINCE_RECORD,
  DELETE_PROVINCE,
  UPDATE_PROVINCE_RECORD,
  GET_PROVINCE_COUNT
} from '../../../constant/actionTypes/province'
import { provinceService } from '../../../services'

export const initProvince = () => ({
  type: INIT_PROVINCE
})

export const fetchProvinceListStart = () => ({
  type: FETCH_PROVINCE_LIST.START
})

export const fetchProvinceListSuccess = (provinces) => ({
  type: FETCH_PROVINCE_LIST.SUCCESS,
  payload: provinces
})

export const fetchProvinceListError = (error) => ({
  type: FETCH_PROVINCE_LIST.ERROR,
  payload: { error }
})

export const fetchProvinceList = (payload) => {
  return (dispatch) => {
    dispatch(fetchProvinceListStart(payload))
    provinceService.fetchProvinceList(payload).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(fetchProvinceListSuccess(provinceData))
      } else {
        dispatch(fetchProvinceListError(provinceData))
      }
    })
  }
}

export const fetchProvinceRecordStart = () => ({
  type: FETCH_PROVINCE_RECORD.START
})

export const fetchProvinceRecordSuccess = (province) => ({
  type: FETCH_PROVINCE_RECORD.SUCCESS,
  payload: province
})

export const fetchProvinceRecordError = (error) => ({
  type: FETCH_PROVINCE_RECORD.ERROR,
  payload: { error }
})

export const fetchProvinceRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchProvinceRecordStart(payload))
    provinceService.fetchProvinceRecord(payload).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(fetchProvinceRecordSuccess(provinceData))
      } else {
        dispatch(fetchProvinceRecordError(provinceData))
      }
    })
  }
}

export const createProvinceRecordStart = () => ({
  type: CREATE_PROVINCE_RECORD.START
})

export const createProvinceRecordSuccess = (newProvince) => ({
  type: CREATE_PROVINCE_RECORD.SUCCESS,
  payload: newProvince
})

export const createProvinceRecordError = (error) => ({
  type: CREATE_PROVINCE_RECORD.ERROR,
  payload: { error }
})

export const createProvinceRecord = (payload) => {
  return (dispatch) => {
    dispatch(createProvinceRecordStart(payload))
    provinceService.createProvinceRecord(payload).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(createProvinceRecordSuccess(provinceData))
      } else {
        dispatch(createProvinceRecordError(provinceData))
      }
    })
  }
}

export const updateProvinceRecordStart = (payload, id) => ({
  type: UPDATE_PROVINCE_RECORD.START,
  payload,
  id
})

export const updateProvinceRecordSuccess = (newProvince) => ({
  type: UPDATE_PROVINCE_RECORD.SUCCESS,
  payload: newProvince
})

export const updateProvinceRecordError = (error) => ({
  type: UPDATE_PROVINCE_RECORD.ERROR,
  payload: { error }
})

export const updateProvinceRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateProvinceRecordStart(payload, id))
    provinceService.updateProvinceRecord(payload, id).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(updateProvinceRecordSuccess(provinceData))
      } else {
        dispatch(updateProvinceRecordError(provinceData))
      }
    })
  }
}

export const deleteProvinceStart = () => ({
  type: DELETE_PROVINCE.START
})

export const deleteProvinceSuccess = (provinceId) => ({
  type: DELETE_PROVINCE.SUCCESS,
  payload: { provinceId }
})

export const deleteProvinceError = (error) => ({
  type: DELETE_PROVINCE.ERROR,
  payload: { error }
})

export const deleteProvince = (provinceId) => {
  return (dispatch) => {
    dispatch(deleteProvinceStart())
    provinceService.deleteProvince(provinceId).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(deleteProvinceSuccess(provinceData))
      } else {
        dispatch(deleteProvinceError(provinceData))
      }
    })
  }
}

export const getProvinceCountStart = (role, payload) => {
  return {
    type: GET_PROVINCE_COUNT.START,
    role,
    payload
  }
}
export const getProvinceCountSuccess = (provinceData) => {
  return {
    type: GET_PROVINCE_COUNT.SUCCESS,
    payload: provinceData
  }
}
export const getProvinceCountError = (error) => {
  return {
    type: GET_PROVINCE_COUNT.ERROR,
    payload: error
  }
}

export const getProvinceCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getProvinceCountStart(role, payload))
    provinceService.getProvinceCount(role, payload).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(getProvinceCountSuccess(provinceData))
      } else {
        dispatch(getProvinceCountError(provinceData))
      }
    })
  }
}
