import React from 'react'

const PrivacyPolicyComponent = () => {
  return (
    <div className=" page-information-container mt-8 md:px-7  mb-3 lg:mb-7 mr-4 ml-4 md:mr-6 md:ml-6 lg:mr-7 lg:ml-7">
      <div className="mb-3">
        <h1 className="font-medium text-2xl mt-5">
          Privacy Policy
        </h1>
      </div>

      <p className="  line-height-2">
          Effective Date: January 1, 2024 <br />
          Clearcole Solution, operating through its product Bhoomi22 ("we," "us," or "our"), is committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website 
          {'   '}{' '}
          <a href="https://bhoomi22.com/" className="text-blue-500">
          www.bhoomi22.com
          </a>{' '}
          {'   '}
          (the "Site").
      </p>

      <div className="mt-3">
        <h2 className=" page-info-subheading font-medium text-lg">
        1. Information We Collect
        </h2>
        <p className="  line-height-2 mt-2">
        We collect personal information you voluntarily provide when using the Site, including:

        Contact Information: Name, email address, phone number.
        Payment Information: Billing details such as your credit/debit card number and payment details when you make a purchase.
        Usage Data: Information about your interaction with our Site such as IP address, browser type, and browsing behavior.

        </p>
      </div> 

      <div className="mt-3">
        <h2 className=" page-info-subheading font-medium text-lg">
        2. How We Use Your Information
        </h2>
        <p className="  line-height-2 mt-2">
        We use the information we collect for various purposes, including:

        To process your orders and manage transactions.
        To communicate with you regarding your purchase, updates, and customer service.
        To improve our website, services, and products.
        To send marketing and promotional materials, where applicable.


        </p>
      </div> 

      <div className="mt-3">
        <h2 className=" page-info-subheading font-medium text-lg">
        3. Sharing Your Information
        </h2>
        <p className="  line-height-2 mt-2">
        We do not sell or rent your personal information to third parties. We may share your data with:

        Service Providers: For processing transactions, providing customer support, or enhancing user experience.
        Legal Obligations: When required by law or in response to valid requests by public authorities.



        </p>
      </div>

      

      <div className="mt-3">
        <h2 className="page-info-subheading font-medium text-lg">
        4. Data Security
        </h2>
        <p className="  line-height-2 mt-2">
        We use industry-standard encryption to protect your data. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
        </p>
      </div>

      <div className="mt-3">
        <h2 className="page-info-subheading font-medium text-lg">5. Your Rights</h2>
        <p className="  line-height-2 mt-2">
        You have the right to access, correct, update, or delete your personal information. If you have any concerns regarding your privacy, please contact us at  
        {'   '}{' '}
          <a href="mailto:support@bhoomi22.com." className="text-blue-500">
          support@bhoomi22.com.
          </a>
        
        </p>
      </div>

      <div className="mt-3">
        <h2 className="page-info-subheading font-medium text-lg">6. Changes to This Policy</h2>
        <p className="  line-height-2 mt-2">
        We may update this Privacy Policy as necessary to reflect changes in our practices or for other operational, legal, or regulatory reasons.
        </p>
      </div>

    </div>
  )
}

export default PrivacyPolicyComponent
