import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
// import { USER_ROLE } from "../../constant/actionTypes/role";

export const fetchAccessList = async (payload) => {
  const url = `${baseUrl}${API_PATH.USER_ACCESS.FETCH}`

  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data ?? []
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchAccessRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.USER_ACCESS.FETCH_BY_ID}/${payload}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const createAccessRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.USER_ACCESS.ADD}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const updateAccessRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.USER_ACCESS.EDIT}/${id}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const deleteAccess = async (accessId) => {
  const url = `${baseUrl}${API_PATH.USER_ACCESS.DELETE}/${accessId}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const getAccessCount = async (role, payload) => {
  let url = ''
  // if(USER_ROLE.ADMIN === role){
  url = `${baseUrl}${API_PATH.USER_ACCESS.COUNT}?tenantId.equals=${payload}`
  // }else{
  //   url = `${baseUrl}${API_PATH.USER_ACCESS.COUNT}?userId.equals=${payload}`;
  // }

  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}
