import {
  INIT_PLAN,
  FETCH_PLAN_LIST
} from '../../../constant/actionTypes/payment'

const initialState = {
  plansList: [],
  selectedPlan: null,
  isLoading: false,
  error: null,
  isPageLevelError: false
}

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_PLAN:
      return {
        ...state,
        formFieldValueMap: null,
        plansList: [],
        selectedPlan: null,
        isLoading: false,
        error: null
      }
    case FETCH_PLAN_LIST.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case FETCH_PLAN_LIST.SUCCESS:
      return {
        ...state,
        plansList: action.payload,
        isLoading: false,
        error: null
      }

    case FETCH_PLAN_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }

    default:
      return state
  }
}

export default paymentReducer
