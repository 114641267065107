import React, { useState } from 'react'
import GmapComponent from '../../components/gMap'
import { connect } from 'react-redux'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { setGtNoError } from '../../redux/action/gatNo'

const GmapScreen = (props) => {
  const { user, userRole, isLoggedIn, selectedGatNo } =
    props

  const [loading, setLoading] = useState(false)

  const plotCoordinates = selectedGatNo
    ? JSON.parse(selectedGatNo?.plotCoordinates?.replace(/(\w+):/g, '"$1":'))
    : []

  const pinCoordinates = selectedGatNo
    ? JSON.parse(selectedGatNo?.pinCoordinates?.replace(/(\w+):/g, '"$1":'))
    : []

  //   const vCoordinates = vBound
  //     ? JSON.parse(vBound.coordinates.replace(/(\w+):/g, '"$1":'))
  //     : [];

  const villageName = selectedGatNo?.village?.name ?? ''
  const stateName = selectedGatNo?.village.taluka.district.state.name ?? ''
  const districtName = selectedGatNo?.village.taluka.district.name ?? ''
  const talukaName = selectedGatNo?.village.taluka.name ?? ''
  const plotNumber = selectedGatNo ? selectedGatNo.plotNumber : ''
  const plotArea = selectedGatNo ? selectedGatNo.area : ''
  const shapeArea = selectedGatNo ? selectedGatNo.shapeArea : ''
  const shapeLength = selectedGatNo ? selectedGatNo.shapeLength : ''
  const govArea = selectedGatNo ? selectedGatNo.area : ''

  const gMapProps = {
    plotCoordinates,
    pinCoordinates,
    plotNumber,
    // vCoordinates,
    villageName,
    stateName,
    districtName,
    talukaName,
    user,
    userRole,
    isLoggedIn,
    plotArea,
    shapeArea,
    shapeLength,
    govArea,
    setLoading
  }

  const getToastProps = () => {
    // if (isGatNoDetailError && isLoggedIn) {
    //   // setGtNoError()
    //   const toastTitle = "You don't have access to view this record."
    //   return {
    //     severity: TOAST_SEVERITY.ERROR,
    //     toastTitle,
    //     shouldShowToast: true
    //   }
    // }
    if (loading) {
      const toastTitle =
        'Location services are disabled. Please enable them to continue.'
      return {
        severity: TOAST_SEVERITY.WARN,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  // const renderProgressBar = () => {
  //   return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  // };
  return (
    <div>
      {/* {isLoading && renderProgressBar()} */}
      <MzToast {...getToastProps()} />
      <GmapComponent gMapProps={gMapProps} />
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    setGtNoError: () => dispatch(setGtNoError())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,

    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    isLoggedIn: state.loginReducer.isLoggedIn,
    selectedGatNo: state.gatNoReducer.selectedGatNo,
    isGatNoDetailError: state.gatNoReducer.isGatNoDetailError
    // error:state.gatNoReducer.error
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GmapScreen)
