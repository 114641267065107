import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'
export const createUser = async (payload) => {
  const url = `${baseUrl}${API_PATH.USER.ADD}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const fetchUserList = async (payload) => {
  const {query} = payload
  let url = `${baseUrl}${API_PATH.USER.FETCH}`
  let finalUrl;
    finalUrl = getDecoratedUrl({ url, payload, shouldFilter: false });
    if (query && query !== '') {
      finalUrl += `&query=${encodeURIComponent(query)}`;
    }
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchUserRecord = async (payload) => {
  
  const url = `${baseUrl}${API_PATH.USER.FETCH}/${payload}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const updateUserRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.USER.EDIT}/${id}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const deleteUser = async (salesPersonId) => {
  const url = `${baseUrl}${API_PATH.USER.DELETE}/${salesPersonId}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const getUserCount = async (role, payload) => {
  let url = ''
  // if(USER_ROLE.ADMIN === role){
  url = `${baseUrl}${API_PATH.USER.COUNT}?tenantId.equals=${payload}`
  // }else{
  //   url = `${baseUrl}${API_PATH.EMPOLYEE.COUNT}?userId.equals=${payload}`;
  // }

  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}
