import {
  INIT_KML_UPLOAD,
  // FETCH_KML_UPLOAD_LIST,
  // FETCH_KML_UPLOAD_RECORD,
  CREATE_KML_UPLOAD_RECORD
  // DELETE_KML_UPLOAD,
  // UPDATE_KML_UPLOAD_RECORD,
  // GET_KML_UPLOAD_COUNT,
} from '../../../constant/actionTypes/kmlUpload'
import { kmlUploadService } from '../../../services'

export const initKmlUpload = () => ({
  type: INIT_KML_UPLOAD
})

// export const fetchKmlUploadListStart = () => ({
//   type: FETCH_KML_UPLOAD_LIST.START,
// });

// export const fetchKmlUploadListSuccess = (kmlUploads) => ({
//   type: FETCH_KML_UPLOAD_LIST.SUCCESS,
//   payload: kmlUploads,
// });

// export const fetchKmlUploadListError = (error) => ({
//   type: FETCH_KML_UPLOAD_LIST.ERROR,
//   payload: { error },
// });

// export const fetchKmlUploadList = (payload) => {
//   return (dispatch) => {
//     dispatch(fetchKmlUploadListStart(payload));
//     kmlUploadService.fetchKmlUploadList(payload).then((kmlUploadData) => {
//       if (!kmlUploadData.isError) {
//         dispatch(fetchKmlUploadListSuccess(kmlUploadData));
//       } else {
//         dispatch(fetchKmlUploadListError(kmlUploadData));
//       }
//     });
//   };
// };

// export const fetchKmlUploadRecordStart = () => ({
//   type: FETCH_KML_UPLOAD_RECORD.START,
// });

// export const fetchKmlUploadRecordSuccess = (kmlUpload) => ({
//   type: FETCH_KML_UPLOAD_RECORD.SUCCESS,
//   payload: kmlUpload,
// });

// export const fetchKmlUploadRecordError = (error) => ({
//   type: FETCH_KML_UPLOAD_RECORD.ERROR,
//   payload: { error },
// });

// export const fetchKmlUploadRecord = (payload) => {
//   return (dispatch) => {
//     dispatch(fetchKmlUploadRecordStart(payload));
//     kmlUploadService.fetchKmlUploadRecord(payload).then((kmlUploadData) => {
//       if (!kmlUploadData.isError) {
//         dispatch(fetchKmlUploadRecordSuccess(kmlUploadData));
//       } else {
//         dispatch(fetchKmlUploadRecordError(kmlUploadData));
//       }
//     });
//   };
// };

export const createKmlUploadRecordStart = (payload, id) => ({
  type: CREATE_KML_UPLOAD_RECORD.START,
  payload,
  id
})

export const createKmlUploadRecordSuccess = (newKmlUpload) => ({
  type: CREATE_KML_UPLOAD_RECORD.SUCCESS,
  payload: newKmlUpload
})

export const createKmlUploadRecordError = (error) => ({
  type: CREATE_KML_UPLOAD_RECORD.ERROR,
  payload: { error }
})

export const createKmlUploadRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(createKmlUploadRecordStart(payload, id))
    kmlUploadService
      .createKmlUploadRecord(payload, id)
      .then((kmlUploadData) => {
        if (!kmlUploadData.isError) {
          dispatch(createKmlUploadRecordSuccess(kmlUploadData))
        } else {
          dispatch(createKmlUploadRecordError(kmlUploadData))
        }
      })
  }
}

// export const updateKmlUploadRecordStart = (payload, id) => ({
//   type: UPDATE_KML_UPLOAD_RECORD.START,
//   payload,
//   id,
// });

// export const updateKmlUploadRecordSuccess = (newKmlUpload) => ({
//   type: UPDATE_KML_UPLOAD_RECORD.SUCCESS,
//   payload: newKmlUpload,
// });

// export const updateKmlUploadRecordError = (error) => ({
//   type: UPDATE_KML_UPLOAD_RECORD.ERROR,
//   payload: { error },
// });

// export const updateKmlUploadRecord = (payload, id) => {
//   return (dispatch) => {
//     dispatch(updateKmlUploadRecordStart(payload, id));
//     kmlUploadService.updateKmlUploadRecord(payload, id).then((kmlUploadData) => {
//       if (!kmlUploadData.isError) {
//         dispatch(updateKmlUploadRecordSuccess(kmlUploadData));
//       } else {
//         dispatch(updateKmlUploadRecordError(kmlUploadData));
//       }
//     });
//   };
// };

// export const deleteKmlUploadStart = () => ({
//   type: DELETE_KML_UPLOAD.START,
// });

// export const deleteKmlUploadSuccess = (kmlUploadId) => ({
//   type: DELETE_KML_UPLOAD.SUCCESS,
//   payload: { kmlUploadId },
// });

// export const deleteKmlUploadError = (error) => ({
//   type: DELETE_KML_UPLOAD.ERROR,
//   payload: { error },
// });

// export const deleteKmlUpload = (kmlUploadId) => {
//   return (dispatch) => {
//     dispatch(deleteKmlUploadStart());
//     kmlUploadService.deleteKmlUpload(kmlUploadId).then((kmlUploadData) => {
//       if (!kmlUploadData.isError) {
//         dispatch(deleteKmlUploadSuccess(kmlUploadData));
//       } else {
//         dispatch(deleteKmlUploadError(kmlUploadData));
//       }
//     });
//   };
// };

// export const getKmlUploadCountStart = (role,payload) => {
//   return {
//     type: GET_KML_UPLOAD_COUNT.START,
//     role,
//     payload,
//   };
// };
// export const getKmlUploadCountSuccess = (kmlUploadData) => {
//   return {
//     type: GET_KML_UPLOAD_COUNT.SUCCESS,
//     payload: kmlUploadData,
//   };
// };
// export const getKmlUploadCountError = (error) => {
//   return {
//     type: GET_KML_UPLOAD_COUNT.ERROR,
//     payload: error,
//   };
// };

// export const getKmlUploadCount = (role,payload) => {
//   return (dispatch) => {
//     dispatch(getKmlUploadCountStart(role,payload));
//     kmlUploadService.getKmlUploadCount(role,payload).then((kmlUploadData) => {
//       if (!kmlUploadData.isError) {
//         dispatch(getKmlUploadCountSuccess(kmlUploadData));
//       } else {
//         dispatch(getKmlUploadCountError(kmlUploadData));
//       }
//     });
//   };
// };
