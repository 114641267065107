import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import MzInput from '../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constant'
import { Button } from 'primereact/button'
import { NavLink } from 'react-router-dom'
import { LOGO } from '../../assets/images'
import MzPhoneInput from '../../common/MzForm/MzPhoneInput'
import MzCheckbox from '../../common/MzForm/MzCheckbox'

const RegisterComponent = (props) => {
  const {
    formFieldValueMap,
    register,
    isLoading,
    isPageLevelError,
    isRegisterSuccess,
    toggleChange
  } = props.registerProps
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue
  } = useForm({
    defaultValues: { ...formFieldValueMap },
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (isRegisterSuccess) {
      reset() 
      setTimeout(() => {
        toggleChange(true); 
      }, 1000);
      
    }
  }, [isRegisterSuccess, reset, toggleChange])
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }

  useEffect(() => {
    const emailValue = watch(FORM_FIELDS_NAME.EMAIL.name)
    if (emailValue) {
      setValue(FORM_FIELDS_NAME.USERNAME.name, emailValue)
    }
  }, [setValue, watch])

  const onSubmit = (data) => {
    let payload = {
      name: data?.name ?? '',
      email: data?.email ?? '',
      password: data?.password ?? '',
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      mobile: data?.mobile ?? ''
    }
    register(payload)
  }
  useEffect(() => {
    const emailValue = watch(FORM_FIELDS_NAME.EMAIL.name)
    if (emailValue) {
      setValue(FORM_FIELDS_NAME.USERNAME.name, emailValue)
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.EMAIL.name), setValue])
  return (
    <>
      <div className="surface-grund flex align-items-center justify-content-center">
        <div className="flex flex-column align-items-center justify-content-center p-3">
          <div
            className="flex justify-content-center align-items-center bg-green-700 border-circle"
            style={{ height: '3.2rem', width: '3.2rem' }}
          >
            {/* <i className="pi pi-sign-in text-2xl text-white"></i> */}
            <img src={LOGO} alt="Logo" style={{ height: '4rem', cursor: 'pointer' }} />
          </div>
          <h1 className="text-900 font-bold text-3xl md:text-5xl mb-2">
            Sign Up
          </h1>
          <div className="text-600 mb-5">Welcome Back to Bhoomi 22.</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column text-center  border-round-sm ">
              <div className="mb-3 w-17rem md:w-20rem">
                <MzInput
                  control={control}
                  name={FORM_FIELDS_NAME.FIRST_NAME.name}
                  label={FORM_FIELDS_NAME.FIRST_NAME.label}
                  placeholder={FORM_FIELDS_NAME.FIRST_NAME.placeholder}
                  type={FORM_FIELDS_NAME.FIRST_NAME.type}
                  isError={errors[FORM_FIELDS_NAME.FIRST_NAME.name]}
                  errorMsg={getFormErrorMessage(
                    FORM_FIELDS_NAME.FIRST_NAME.name
                  )}
                  rules={FORM_FIELDS_NAME.FIRST_NAME.rules}
                  wrapperClass={'p-float-label'}
                />
              </div>
              <div className="mb-3 w-17rem md:w-20rem">
                <MzInput
                  control={control}
                  name={FORM_FIELDS_NAME.LAST_NAME.name}
                  label={FORM_FIELDS_NAME.LAST_NAME.label}
                  placeholder={FORM_FIELDS_NAME.LAST_NAME.placeholder}
                  type={FORM_FIELDS_NAME.LAST_NAME.type}
                  isError={errors[FORM_FIELDS_NAME.LAST_NAME.name]}
                  errorMsg={getFormErrorMessage(
                    FORM_FIELDS_NAME.LAST_NAME.name
                  )}
                  rules={FORM_FIELDS_NAME.LAST_NAME.rules}
                  wrapperClass={'p-float-label'}
                />
              </div>
              <div className="mb-3 w-17rem md:w-20rem">
                <MzPhoneInput
                  control={control}
                  name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                  label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                  placeholder={FORM_FIELDS_NAME.PHONE_NUMBER.placeholder}
                  rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                  isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                  errorMsg={getFormErrorMessage(
                    FORM_FIELDS_NAME.PHONE_NUMBER.name
                  )}
                  country="in"
                />
              </div>
              <div className="  mb-3 w-17rem md:w-20rem">
                <MzInput
                  control={control}
                  name={FORM_FIELDS_NAME.EMAIL.name}
                  label={FORM_FIELDS_NAME.EMAIL.label}
                  placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                  type={FORM_FIELDS_NAME.EMAIL.type}
                  isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                  errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.EMAIL.name)}
                  rules={FORM_FIELDS_NAME.EMAIL.rules}
                  wrapperClass={'p-float-label'}
                />
              </div>
              <div className="mb-3 w-17rem md:w-20rem">
                <MzInput
                  control={control}
                  name={FORM_FIELDS_NAME.USERNAME.name}
                  label={FORM_FIELDS_NAME.USERNAME.label}
                  placeholder={FORM_FIELDS_NAME.USERNAME.placeholder}
                  type={FORM_FIELDS_NAME.USERNAME.type}
                  isError={errors[FORM_FIELDS_NAME.USERNAME.name]}
                  errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.USERNAME.name)}
                  rules={FORM_FIELDS_NAME.USERNAME.rules}
                  wrapperClass={'p-float-label'}
                  disabled
                />
              </div>

              <div className="mb-3 w-17rem md:w-20rem">
                <MzInput
                  control={control}
                  name={FORM_FIELDS_NAME.PASSWORD.name}
                  label={FORM_FIELDS_NAME.PASSWORD.label}
                  placeholder={FORM_FIELDS_NAME.PASSWORD.placeholder}
                  type={FORM_FIELDS_NAME.PASSWORD.type}
                  isError={errors[FORM_FIELDS_NAME.PASSWORD.name]}
                  errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.PASSWORD.name)}
                  rules={FORM_FIELDS_NAME.PASSWORD.rules}
                  wrapperClass={'p-float-label'}
                />
              </div>
              <div className="mb-3 text-sm  flex  justify-content-between align-items-center">
                <MzCheckbox
                  control={control}
                  name={FORM_FIELDS_NAME.TERMS_CONDITIONS.name} 
                  label={FORM_FIELDS_NAME.TERMS_CONDITIONS.label} 
                  isError={errors[FORM_FIELDS_NAME.TERMS_CONDITIONS.name]} 
                  errorMsg={getFormErrorMessage(
                    FORM_FIELDS_NAME.TERMS_CONDITIONS.name
                  )}  
                  rules={FORM_FIELDS_NAME.TERMS_CONDITIONS.rules}     
                />
              </div>

              <div className="mb-3">
                <Button
                  type="submit"
                  label="Submit"
                  className="w-full"
                  disabled={isLoading || isPageLevelError}
                ></Button>
              </div>
            </div>
          </form>
          <div className='text-sm'>
            Already have an account? &nbsp;
            <NavLink
              onClick={() => {
                toggleChange(true)
              }}
              style={{ textDecoration: 'none' }}
            >
              Sign in
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterComponent
