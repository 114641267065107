import React from 'react'
import TermsConditionComponent from '../../components/termsConditions'

function TermsConditionScreen() {
  return (
    <div>
      <TermsConditionComponent />
    </div>
  )
}

export default TermsConditionScreen
