import React, { useEffect, useState } from 'react'
import LinkComponent from '../../components/link'
import { deleteLink, fetchLinkList, initLink } from '../../redux/action/link'
import { connect } from 'react-redux'
import { ProgressBar } from 'primereact/progressbar'
import linkTableData from './data.json'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

const LinkScreen = (props) => {
  const {
    fetchLinkList,
    initLink,
    linksList,
    isLoading,
    isPageLevelError,
    userRole,
    // user,
    tenantList,
    // error,
    deleteLink,
    isDeleteLinkSuccess,
    isDeleteLinkError
  } = props
  useEffect(() => {
    initLink()
    const paylaod = {
      pageNumber: 0,
      pageSize: 10
    }
    fetchLinkList(paylaod)
    // eslint-disable-next-line
  }, [isDeleteLinkSuccess])

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [selectedLink, setSelectedLink] = useState(null)


  const history = useNavigate()
  const handleOnReadRecord = (data) => {
    history(`${ROUTE_PATH.LOCATION.HOME1.replace(':id', data?.link)}`)
  }

  const handleOnDeleteRecord = (data) => {
    setSelectedLink(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.LINK.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.LINK.ADD)
  }

  const handleOnDownloadRecord = (data) => {
    history(`${ROUTE_PATH.LINK.UPDATE.replace(':id', data?.id)}`)
  }
  const confirmDelete = () => {
    if (selectedLink) {
      deleteLink(selectedLink.id)
      setIsDeleteDialogVisible(false)
      setSelectedLink(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedLink(null)
  }

  const linkProps = {
    linksList,
    fetchLinkList,
    isPageLevelError,
    isLoading,
    linkTableData,
    userRole,
    tenantList,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord,
    handleOnDownloadRecord,
    // rowData
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const getToastProps = () => {
    if (isDeleteLinkSuccess) {
      const toastTitle = 'Link Status Change Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteLinkError) {
      let toastTitle = 'Error while Changing Link Status '
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />

      <LinkComponent linkProps={linkProps} />
      <Dialog
        header={`Confirm ${selectedLink?.active ? 'Deactivate' : 'Activate'}`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedLink?.active ? 'danger' : ''}
              onClick={confirmDelete}
            >
              Yes
            </Button>
            <Button severity="" onClick={cancelDelete}>
              No
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          Are you sure you want to{' '}
          {selectedLink?.active ? 'deactivate' : 'activate'} this link?{' '}
          <span className="text-primary">{selectedLink?.name}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initLink: () => dispatch(initLink()),
    fetchLinkList: (payload) => dispatch(fetchLinkList(payload)),
    deleteLink: (payload) => dispatch(deleteLink(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.linkReducer.isPageLevelError,
    isLoading: state.linkReducer.isLoading,
    error: state.linkReducer.error,
    linksList: state.linkReducer.linksList,
    userRole: state.loginReducer.userRole,

    user: state.userProfileReducer.user,
    isDeleteLinkSuccess: state.linkReducer.isDeleteLinkSuccess,
    isDeleteLinkError: state.linkReducer.isDeleteLinkError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkScreen)
