import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'
// import { USER_ROLE } from "../../constant/actionTypes/role";


export const fetchLinkList = async (payload) => {
    const {query}=payload
    let url = `${baseUrl}${API_PATH.LINK.FETCH}`
    let finalUrl;
    finalUrl = getDecoratedUrl({ url, payload, shouldFilter: false });
    if (query && query !== '') {
      finalUrl += `&query=${encodeURIComponent(query)}`;
    }
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}


export const fetchLinkRecord = async (payload) => {

  const url = `${baseUrl}${API_PATH.LINK.FETCH_BY_ID}/${payload}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchAllLinkByUser = async (payload) => {

  const url = `${baseUrl}${API_PATH.LINK.FETCH}/${payload}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const createLinkRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.LINK.ADD}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.message)
  }
}


export const updateLinkRecord = async (payload) => {
  const { usageLimit, id } = payload;
  const url = `${baseUrl}${API_PATH.LINK.EDIT}/${id}`;
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('usageLimit', usageLimit);
  try {
    const result = await axios.put(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail);
    }

    return result.data;
  } catch (error) {
    return handleAPIError(error.response?.data?.detail || 'An error occurred');
  }
};

export const updatePlotRecord = async (payload) => {
  const { updatePlot, id ,updatePin } = payload;
  const url = `${baseUrl}${API_PATH.LINK.UPDATE}/${id}`;
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('updatedPlotCoordinates', updatePlot);
  formData.append('updatedPinCoordinates', updatePin);
  try {
    const result = await axios.put(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail);
    }

    return result.data;
  } catch (error) {
    return handleAPIError(error.response?.data?.detail || 'An error occurred');
  }
};



export const deleteLink = async (linkId) => {
  const url = `${baseUrl}${API_PATH.LINK.DELETE}/${linkId}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const getLinkCount = async (role, payload) => {
  let url = ''
  // if(USER_ROLE.ADMIN === role){
  url = `${baseUrl}${API_PATH.LINK.COUNT}?tenantId.equals=${payload}`
  // }else{
  //   url = `${baseUrl}${API_PATH.LINK.COUNT}?userId.equals=${payload}`;
  // }

  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}
