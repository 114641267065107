import React, {  useState } from 'react'
import MzTable from '../../common/MzTable'
import ErrorPage from '../../common/Error'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Button } from 'primereact/button'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'

const AccessComponent = (props) => {
  const {
    accessList,
    isPageLevelError,
    accessTableData,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    isLoading,
    // userRole,
    fetchAccessList,
    handleOnCreatedRecord
  } = props.accessProps



  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 10, pageNumber: 0, totalRecords: 0, totalPages: 0 });



  const shouldRenderFullPageError = () => isPageLevelError
  const shouldRenderUserTransactionList = () =>
    accessTableData?.tableData?.columns?.length > 0
  const shouldRenderNotFoundView = () =>
    !shouldRenderFullPageError && !shouldRenderUserTransactionList
  const history = useNavigate()
  const filters = {
    global: { value: null },
    userName: { filterKey: 'username', value: null },
    email: { filterKey: 'email', value: null }
  }

  const loadLazyData = (event) => {
    const { pageNumber, pageSize } = event;
    setPaginationInfo((prev) => ({
      ...prev,
      pageNumber,
      pageSize,
    }));

    fetchAccessList({ pageNumber, pageSize });
  }
  const tableProps = {
    value: accessList?.content ?? [],
    loading: isLoading,

    paginationInfo: {
      ...paginationInfo,
      totalRecords: accessList?.totalElements ?? 0,
    },

    columns: accessTableData?.tableData?.columns,

    screenPermission: accessTableData?.tableData?.screenPermission,
    loadLazyData,
    emptyMessage: 'No Access Record Found',
    filters,
    sortField: null,
    showMoreAction: false,
    onReadRecord: handleOnReadRecord,
    onEditRecord: handleOnEditRecord,
    onDeleteRecord: handleOnDeleteRecord
  }

  const handleToCreateAccess = () => {
    handleOnCreatedRecord()
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'Access', command: () => history(ROUTE_PATH.ACCESS.HOME) }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center border-1 border-200 border-round bg-white">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
        <Button onClick={() => handleToCreateAccess()} className="py-2">
          Add Access
        </Button>
      </div>
    )
  }

  return (
    <div>
      <div className=" ">
        {renderPageHeaderSection()}
        {shouldRenderFullPageError() && (
          <div>
            <ErrorPage />
          </div>
        )}
        {shouldRenderUserTransactionList() && (
          <div className="border-1 border-200 border-round mt-3 p-2 bg-white">
            <MzTable {...tableProps} />
          </div>
        )}
        {shouldRenderNotFoundView() && <div>No record to show</div>}
      </div>
    </div>
  )
}

export default AccessComponent
