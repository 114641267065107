import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  createUserRecord,
  fetchUserRecord,
  initUser,
  updateUserRecord
} from '../../../redux/action/user'
// import { USER_ROLE } from "../../../constant/actionTypes/role";
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import AddUserComponent from '../../../components/user/addUser'

const AddUserScreen = (props) => {
  const {
    isCreateUserSuccess,
    isCreateUserError,
    isUserDetailSuccess,
    isUserDetailError,
    isEditUserSuccess,
    isEditUserError,
    initUser,
    isEdit,
    isLoading,
    createUserRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    userRole,
    fetchUserRecord,
    updateUserRecord,
    error
  } = props
  
  const history = useNavigate()
  useEffect(() => {
    initUser()
    // eslint-disable-next-line
  }, [])

  const getToastProps = () => {
    if (isCreateUserSuccess || isEditUserSuccess) {
      const toastTitle = isEdit
        ? 'User Updated Successfully'
        : 'User Created Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateUserError || isUserDetailError || isEditUserError) {
      let toastTitle = 'Error while Creating User'
      if (isEditUserError) {
        toastTitle = 'Error while updating User'
      } else if (isUserDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser'
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const navigatetoUser = () => {
    history(ROUTE_PATH.USER.HOME)
  }
  const addUserProps = {
    createUserRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    error,
    isCreateUserSuccess,
    isEditUserSuccess,
    isUserDetailSuccess,
    fetchUserRecord,
    updateUserRecord,
    navigatetoUser
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddUserComponent addUserProps={addUserProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initUser: () => dispatch(initUser()),
    createUserRecord: (payload) => dispatch(createUserRecord(payload)),
    fetchUserRecord: (payload) => dispatch(fetchUserRecord(payload)),
    updateUserRecord: (payload, id) => dispatch(updateUserRecord(payload, id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.userReducer),
    isPageLevelError: state.userReducer.isPageLevelError,
    isLoading: state.userReducer.isLoading,
    error: state.userReducer.error,
    isCreateUserSuccess: state.userReducer.isCreateUserSuccess,
    isCreateUserError: state.userReducer.isCreateUserError,
    isEditUserSuccess: state.userReducer.isEditUserSuccess,
    isEditUserError: state.userReducer.isEditUserError,
    isUserDetailError: state.userReducer.isUserDetailError,
    isUserDetailSuccess: state.userReducer.isUserDetailSuccess,
    userRole: state.loginReducer.userRole
  }
}

const selectFormFieldValueMap = (userReducer) => {
  return userReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserScreen)
