import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
// import { USER_ROLE } from "../../constant/actionTypes/role";

// export const fetchEmployeeList = async (payload) => {
//   let url;
//   if (!payload) {
//     url = `${baseUrl}${API_PATH.EMPOLYEE.FETCH}`;
//   } else {
//     url = `${baseUrl}${API_PATH.EMPOLYEE.FETCH}?tenantId.equals=${payload}`;
//   }
//   const token = localStorage.getItem("token");
//   try {
//     const result = await axios.get(url, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (result.data.error || result.status !== 200) {
//       return handleAPIError(result.data.detail);
//     }
//     return result.data;
//   } catch (error) {
//     console.error(error);
//     return handleAPIError(error);
//   }
// };

// export const fetchEmployeeRecord = async (payload) => {
//   const url = `${baseUrl}${API_PATH.EMPOLYEE.FETCH}/${payload}`;
//   const token = localStorage.getItem("token");
//   try {
//     const result = await axios.get(url, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (result?.data?.error || result.status !== 200) {
//       return handleAPIError(result.data.detail);
//     }
//     return result.data;
//   } catch (error) {
//     console.error(error);
//     return handleAPIError(error);
//   }
// };

export const createKmlUploadRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.FILE_UPLOAD.ADD}?villageId=${id}`
  const token = localStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

// export const updateEmployeeRecord = async (payload, id) => {
//   const url = `${baseUrl}${API_PATH.EMPOLYEE.EDIT}/${id}`;
//   const token = localStorage.getItem("token");
//   try {
//     const result = await axios.put(url, payload, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     if (result?.data?.error || result.status !== 200) {
//       return handleAPIError(result.data.detail);
//     }
//     return result.data;
//   } catch (error) {
//     return handleAPIError(error.response.data.detail);
//   }
// };

// export const deleteEmployee = async (employeeId) => {
//   const url = `${baseUrl}${API_PATH.EMPOLYEE.DELETE}/${employeeId}`;
//   const token = localStorage.getItem("token");
//   try {
//     const result = await axios.delete(url, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (result?.data?.error || result.status !== 204) {
//       return handleAPIError(result.data.detail);
//     }
//     return result.data;
//   } catch (error) {
//     console.error(error);
//     return handleAPIError(error.response.data.detail);
//   }
// };

// export const getEmployeeCount = async (role,payload) => {
//   let url =""
//   // if(USER_ROLE.ADMIN === role){
//     url = `${baseUrl}${API_PATH.EMPOLYEE.COUNT}?tenantId.equals=${payload}`;
//   // }else{
//   //   url = `${baseUrl}${API_PATH.EMPOLYEE.COUNT}?userId.equals=${payload}`;
//   // }

//   const token = localStorage.getItem("token");
//   try {
//     const result = await axios.get(url, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if ( result.status !== 200) {
//       return handleAPIError(result?.data?.detail ??"");
//     }
//     return result.data;
//   } catch (error) {
//     console.error(error);
//     return handleAPIError(error.response.data.detail);
//   }
// };
