import { BreadCrumb } from 'primereact/breadcrumb'
import React, { useEffect, useState } from 'react'
import { ROUTE_PATH } from '../../constant/urlConstant'
import MzTable from '../../common/MzTable'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import ErrorPage from '../../common/Error'
import { AutoComplete } from 'primereact/autocomplete'
import moment from 'moment';
const UserComponent = (props) => {
  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 10, pageNumber: 0, totalRecords: 0, totalPages: 0 });
  const {
    userList,
    isPageLevelError,
    isLoading,
    userTableData,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord,
    fetchUserList
  } = props.userProps;

  const history = useNavigate();

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderUserTransactionList = () => userTableData?.tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () => !shouldRenderFullPageError && !shouldRenderUserTransactionList;
  const [query, setQuery] = useState('');
  const [suggestions] = useState([]);
   
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query.length > 3 || query === '') {
        const payload = {
          query,
          pageNumber: 0,
          pageSize: 10,
        };
        fetchUserList(payload);
      }
    }, 300); // Delay of 500ms
    return () => clearTimeout(delayDebounceFn);
  }, [query, fetchUserList]);
   
  const filters = {
    global: { value: null },
    userName: { filterKey: 'username', value: null },
    email: { filterKey: 'email', value: null },
  };

  const loadLazyData = (event) => {

    const { pageNumber, pageSize } = event;


    setPaginationInfo({
      pageNumber,
      pageSize
    });

    fetchUserList({
      query: query,
      pageNumber,
      pageSize
    });

  };

  const tableProps = {
    // value: userList?.content ?? [],
    value: userList?.content?.map(user => ({
      ...user,
      createdAt: moment(user.createdAt).format('DD/MMM/YYYY HH:mm '),
    })) ?? [],
    loading: isLoading,
    columns: userTableData?.tableData?.columns,
    paginationInfo: {
      ...paginationInfo,
      totalRecords: userList?.totalElements ?? 0,
    },

    screenPermission: userTableData?.tableData?.screenPermission,
    loadLazyData,
    emptyMessage: 'No User Record Found',
    filters,
    sortField: null,
    showMoreAction: false,
    onReadRecord: handleOnReadRecord,
    onEditRecord: handleOnEditRecord,
    onDeleteRecord: handleOnDeleteRecord,
  };



  const handleToCreateUser = () => {
    handleOnCreatedRecord();
  };

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'User', command: () => history(ROUTE_PATH.USER.ADD) }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center border-1 border-200 border-round bg-white">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
        <div className="mr-2 flex justify-content-between h-full align-items-center gap-3">
          <div className="flex-grow-1" style={{ maxWidth: '150px' }}>
            <AutoComplete
              value={query}
              suggestions={suggestions}
              // completeMethod={searchUsers}
              field="label"
              onChange={(e) => setQuery(e.value)}
              dropdown
              placeholder={'Search....'}
              className="w-full"
            />
          </div>
          <div>
            <Button onClick={() => handleToCreateUser()} className="py-2">
              Add User
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className=" ">
        {renderPageHeaderSection()}
        {shouldRenderFullPageError() && (
          <div>
            <ErrorPage />
          </div>
        )}
        {shouldRenderUserTransactionList() && (
          <div className="border-1 border-200 border-round mt-3 p-2 bg-white">
            <MzTable {...tableProps} />
          </div>
        )}
        {shouldRenderNotFoundView() && <div>No record to show</div>}
      </div>
    </div>
  )
}
export default UserComponent
