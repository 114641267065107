import {
  INIT_GATNO,
  FETCH_GATNO_LIST,
  FETCH_GATNO_RECORD,
  CREATE_GATNO_RECORD,
  DELETE_GATNO,
  UPDATE_GATNO_RECORD,
  GET_GATNO_COUNT
} from '../../../constant/actionTypes/gatNo'
import { gatNoService } from '../../../services'

export const initGatNo = () => ({
  type: INIT_GATNO
})

export const setGtNoError = () => ({
  type: 'SET_GTNO_ERROR'
})

export const fetchGatNoListStart = () => ({
  type: FETCH_GATNO_LIST.START
})

export const fetchGatNoListSuccess = (gatNos) => ({
  type: FETCH_GATNO_LIST.SUCCESS,
  payload: gatNos
})

export const fetchGatNoListError = (error) => ({
  type: FETCH_GATNO_LIST.ERROR,
  payload: { error }
})

export const fetchGatNoList = (payload) => {
  return (dispatch) => {
    dispatch(fetchGatNoListStart(payload))
    gatNoService.fetchGatNoList(payload).then((gatNoData) => {
      if (!gatNoData.isError) {
        dispatch(fetchGatNoListSuccess(gatNoData))
      } else {
        dispatch(fetchGatNoListError(gatNoData))
      }
    })
  }
}

export const fetchGatNoRecordStart = () => ({
  type: FETCH_GATNO_RECORD.START
})

export const fetchGatNoRecordSuccess = (gatNo) => ({
  type: FETCH_GATNO_RECORD.SUCCESS,
  payload: gatNo
})

export const fetchGatNoRecordError = (error) => ({
  type: FETCH_GATNO_RECORD.ERROR,
  payload: { error }
})

export const fetchGatNoRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchGatNoRecordStart(payload))
    gatNoService.fetchGatNoRecord(payload).then((gatNoData) => {
      if (!gatNoData.isError) {
        dispatch(fetchGatNoRecordSuccess(gatNoData))
      } else {
        dispatch(fetchGatNoRecordError(gatNoData))
      }
    })
  }
}

export const fetchGatNoRecordByUrl = (payload) => {
  return (dispatch) => {
    dispatch(fetchGatNoRecordStart(payload))
    gatNoService.fetchGatNoRecordByUrl(payload).then((gatNoData) => {
      if (!gatNoData.isError) {
        dispatch(fetchGatNoRecordSuccess(gatNoData?.plotDTO ?? null))
      } else {
        dispatch(fetchGatNoRecordError(gatNoData))
      }
    })
  }
}

export const createGatNoRecordStart = () => ({
  type: CREATE_GATNO_RECORD.START
})

export const createGatNoRecordSuccess = (newGatNo) => ({
  type: CREATE_GATNO_RECORD.SUCCESS,
  payload: newGatNo
})

export const createGatNoRecordError = (error) => ({
  type: CREATE_GATNO_RECORD.ERROR,
  payload: { error }
})

export const createGatNoRecord = (payload) => {
  return (dispatch) => {
    dispatch(createGatNoRecordStart(payload))
    gatNoService.createGatNoRecord(payload).then((gatNoData) => {
      if (!gatNoData.isError) {
        dispatch(createGatNoRecordSuccess(gatNoData))
      } else {
        dispatch(createGatNoRecordError(gatNoData))
      }
    })
  }
}

export const updateGatNoRecordStart = (payload, id) => ({
  type: UPDATE_GATNO_RECORD.START,
  payload,
  id
})

export const updateGatNoRecordSuccess = (newGatNo) => ({
  type: UPDATE_GATNO_RECORD.SUCCESS,
  payload: newGatNo
})

export const updateGatNoRecordError = (error) => ({
  type: UPDATE_GATNO_RECORD.ERROR,
  payload: { error }
})

export const updateGatNoRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateGatNoRecordStart(payload, id))
    gatNoService.updateGatNoRecord(payload, id).then((gatNoData) => {
      if (!gatNoData.isError) {
        dispatch(updateGatNoRecordSuccess(gatNoData))
      } else {
        dispatch(updateGatNoRecordError(gatNoData))
      }
    })
  }
}

export const deleteGatNoStart = () => ({
  type: DELETE_GATNO.START
})

export const deleteGatNoSuccess = (gatNoId) => ({
  type: DELETE_GATNO.SUCCESS,
  payload: { gatNoId }
})

export const deleteGatNoError = (error) => ({
  type: DELETE_GATNO.ERROR,
  payload: { error }
})

export const deleteGatNo = (gatNoId) => {
  return (dispatch) => {
    dispatch(deleteGatNoStart())
    gatNoService.deleteGatNo(gatNoId).then((gatNoData) => {
      if (!gatNoData.isError) {
        dispatch(deleteGatNoSuccess(gatNoData))
      } else {
        dispatch(deleteGatNoError(gatNoData))
      }
    })
  }
}

export const getGatNoCountStart = (role, payload) => {
  return {
    type: GET_GATNO_COUNT.START,
    role,
    payload
  }
}
export const getGatNoCountSuccess = (gatNoData) => {
  return {
    type: GET_GATNO_COUNT.SUCCESS,
    payload: gatNoData
  }
}
export const getGatNoCountError = (error) => {
  return {
    type: GET_GATNO_COUNT.ERROR,
    payload: error
  }
}

export const getGatNoCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getGatNoCountStart(role, payload))
    gatNoService.getGatNoCount(role, payload).then((gatNoData) => {
      if (!gatNoData.isError) {
        dispatch(getGatNoCountSuccess(gatNoData))
      } else {
        dispatch(getGatNoCountError(gatNoData))
      }
    })
  }
}
