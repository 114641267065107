import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { baseUrl } from '../PostAPI'
import { handleAPIError } from '../common/errorHandler'

// export const postLogin = async (payload) => {
//   const url = `${API_PATH.AUTH.LOGIN}`;
//   try {
//     let result = await postApiLoginAsyn(url, payload);
//     if (result?.error) {
//       return handleAPIError(result.detail);
//     }
//     return result;
//   } catch (error) {
//     console.error(error);
//     return handleAPIError(error);
//   }
// };

export const postLogin = async (payload) => {
  const url = `${baseUrl}${API_PATH.AUTH.LOGIN}`
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.detail)
  }
}

export const postRegister = async (payload) => {
  const url = `${baseUrl}${API_PATH.AUTH.REGISTER}`
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.message ?? 'Internal Server Error'
    )
  }
}

export const postLogout = async () => {
  try {
    localStorage.removeItem('token')
    localStorage.removeItem('userProfile')
    localStorage.removeItem('userRole')
    localStorage.removeItem('isLoggedIn')
    
    let result = 'Logout Successfully'
    return result
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}
