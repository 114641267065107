export const FORM_FIELDS_NAME = {
  USER: {
    name: 'user',
    label: 'User',
    type: 'dropdown',
    optionLabel: 'email',
    optionValue: 'id',
    rules: {
      required: 'User is required'
    },
    options: [],
    placeholder: 'Select a User'
  },
  LIMIT:{
    name:'usageLimit',
    label:'Limit',
    rules: {
      required: 'Limit is required'
    },
    placeholder: 'Add Limit'
},
  PROVINCE: {
    name: 'state',
    label: 'State',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'State is required'
    },
    options: [],
    placeholder: 'Select a state'
  },
  DISTRICT: {
    name: 'district',
    label: 'District',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'District is required'
    },
    options: [],
    placeholder: 'Select a district'
  },
  SUB_DISTRICT: {
    name: 'taluka',
    label: 'SubDistrict',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'SubDistrict is required'
    },
    options: [],
    placeholder: 'Select a subdistrict'
  },
  VILLAGE: {
    name: 'village',
    label: 'Village',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'Village is required'
    },
    options: [],
    placeholder: 'Select a Village'
  },

  GATNO: {
    name: 'gatNo',
    label: 'GatNo',
    type: 'dropdown',
    optionLabel: 'plotNumber',
    optionValue: 'id',
    rules: {
      required: 'GatNo is required'
    },
    options: [],
    placeholder: 'Select a gatNo'
  },
  NAME: {
    name: 'name',
    label: 'Department Name',
    type: 'text',
    placeholder: 'Enter a department name',
    rules: {
      required: 'Department name is required',
      minLength: {
        value: 2,
        message: 'Department name must be at least 2 characters'
      },
      maxLength: {
        value: 20,
        message: 'Department name cannot exceed 20 characters'
      }
    }
  }
}
