import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ROUTE_PATH } from '../../../constant/urlConstant';
import { useNavigate, useParams } from 'react-router-dom';

const DEFAULT_CENTER = { lat: 18.590727879655105, lng: 73.74850060623852 };

const UpdateMapComponent = ({ updateMapProps }) => {
    const {
        selectedLink,
        isPageLevelError,
        isLoading,
        isEdit,
        isEditLinkSuccess,
        isLinkDetailSuccess,
        fetchLinkRecord,
        updatePlotRecord,
        navigatetoLink,
        plotCoordinates,
        pinCoordinates,
        plotNumber
    } = updateMapProps;

    const { handleSubmit, reset } = useForm({
        defaultValues: useMemo(() => selectedLink, [selectedLink]),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const history = useNavigate();
    const { id } = useParams();
    const [polygonHistory, setPolygonHistory] = useState(plotCoordinates);
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const polygonRef = useRef(null);


    useEffect(() => {
        if (isEditLinkSuccess) {
            setTimeout(() => {
                reset()
                navigatetoLink()
            }, 1000)
        }
        // eslint-disable-next-line
    }, [isEditLinkSuccess])

    useEffect(() => {
        if (isLinkDetailSuccess) {
            reset({
                ...selectedLink
            })
        }
        // eslint-disable-next-line
    }, [isLinkDetailSuccess])



    useEffect(() => {
        if (isEdit && id) {
            fetchLinkRecord(id)
        } else {
            reset(selectedLink)
        }
        // eslint-disable-next-line
    }, [isEdit, id])




    useEffect(() => {
        const loadGoogleMapsScript = () => {
            return new Promise((resolve, reject) => {
                if (window.google && window.google.maps) {
                    resolve()
                    return
                }
                const script = document.createElement('script')
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDDSTNCtV1XVngi_7dyaKxFeOL-paKpWr0&libraries=drawing,geometry&callback=initMap`
                script.async = true
                script.defer = true
                script.onload = resolve
                script.onerror = reject
                document.body.appendChild(script)
            })
        }
        const initializeMap = () => {
            const mapCenter = pinCoordinates?.[0] || DEFAULT_CENTER
            mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
                zoom: pinCoordinates ? 18.5 : 13,
                center: mapCenter,
                scaleControl: true,

                mapTypeId: window.google.maps.MapTypeId.HYBRID,
                mapTypeControl: true
            })

            if (plotCoordinates && Array.isArray(plotCoordinates)) {
                plotCoordinates.forEach(
                    (coords) => {
                        const polygon = new window.google.maps.Polygon({
                            paths: coords,
                            strokeColor: '#0bb980',
                            strokeOpacity: 0.9,
                            strokeWeight: 2,
                            fillColor: '#0bb980',
                            fillOpacity: 0.3,
                            draggable: true
                        })

                        polygon.setMap(mapRef.current)
                        polygonRef.current = polygon
                        window.google.maps.event.addListener(polygon, 'dragend', () => {
                            const newCoords = polygon
                                .getPath()
                                .getArray()
                                .map((latLng) => ({
                                    lat: latLng.lat(),
                                    lng: latLng.lng()
                                }))

                            setPolygonHistory(newCoords)

                            polygon.setPaths(newCoords)
                        })
                    },
                    [plotCoordinates]
                )

            }

            if (pinCoordinates) {
                pinCoordinates.forEach((pinCoord) => {
                    new window.google.maps.Marker({
                        position: pinCoord,
                        map: mapRef.current,
                        title: plotNumber || '',
                        label: {
                            text: plotNumber || '',
                            color: 'white',
                            fontSize: '11px',
                            fontWeight: 'bold'
                        },
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 15,
                            fillColor: 'green',
                            fillOpacity: 1,
                            strokeWeight: 2,
                            strokeColor: 'white'
                        }
                    })
                })
            }
        }

        window.initMap = initializeMap
        loadGoogleMapsScript().then(() => {
            if (document.readyState === 'complete') {
                initializeMap()
            }
        })


        return () => {
            window.initMap = undefined
            if (mapRef.current) mapRef.current = null
            if (polygonRef.current) polygonRef.current.setMap(null)
        }
        // eslint-disable-next-line 
    }, [pinCoordinates, plotCoordinates, plotNumber])



    const calculateCentroid = (polygonCoordinates) => {
        let area = 0;
        let centroidX = 0;
        let centroidY = 0;
        
        for (let i = 0; i < polygonCoordinates.length - 1; i++) {
            const x0 = polygonCoordinates[i].lng;
            const y0 = polygonCoordinates[i].lat;
            const x1 = polygonCoordinates[i + 1].lng;
            const y1 = polygonCoordinates[i + 1].lat;
    
            const a = x0 * y1 - x1 * y0;
            area += a;
            centroidX += (x0 + x1) * a;
            centroidY += (y0 + y1) * a;
        }
    
        area = area / 2;
        centroidX = centroidX / (6 * area);
        centroidY = centroidY / (6 * area);
    
        return {
            lat: centroidY,
            lng: centroidX
        };
    };

    const onSubmit = (data) => {
        if (isEdit && id) {

            const polygonHistoryString = JSON.stringify(polygonHistory);
            const updatePin = [calculateCentroid(polygonHistory)]; 
            const updatePinString = JSON.stringify(updatePin);
            const updatePayload = {
                id: data.id,
                updatePlot: polygonHistoryString,
                updatePin:updatePinString
            };
            updatePlotRecord(updatePayload);  
        }
    };
    

    const renderPageHeaderSection = () => {
        const breadCrumbItems = [
            { label: 'Link', command: () => history(ROUTE_PATH.LINK.HOME) },
            {
                label: 'Update Plot' ,
                command: () =>
                    history(
                        isEdit
                            ? `${ROUTE_PATH.LINK.EDIT.replace(':id', id)}`
                            : ROUTE_PATH.LINK.ADD
                    )
            }
        ]
        const home = {
            icon: 'pi pi-home',
            command: () => history(ROUTE_PATH.DASHBOARD.HOME)
        }

        return (
            <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
                <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
            </div>
        )
    }
    return (
        <div>
            {renderPageHeaderSection()}

            <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-2 w-full px-4 md:mt-5"
                >

                    <div
                        className="w-full;"

                    >
                        <div
                            id="map"
                            ref={mapContainerRef}
                            style={{ height: 'calc(65vh - 4rem)', width: '100%' }}
                        ></div>


                    </div>

                    <div className="grid justify-content-center mt-3 mb-5">
                        <div className="col-12 md:col-6">
                            <Button
                                label="Cancel"
                                className="w-full"
                                severity="secondary"
                                onClick={() => navigatetoLink()}
                                disabled={isLoading || isPageLevelError}
                            />
                        </div>
                        <div className="col-12 md:col-6">
                            <Button
                                type="submit"
                                label={isEdit ? "Update" : "Submit"}
                                className="w-full"
                                disabled={isLoading || isPageLevelError}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default UpdateMapComponent
