export const INIT_PROVINCE = 'INIT_PROVINCE'

export const FETCH_PROVINCE_LIST = {
  START: 'FETCH_PROVINCE_START',
  SUCCESS: 'FETCH_PROVINCE_SUCCESS',
  ERROR: 'FETCH_PROVINCE_ERROR'
}

export const FETCH_PROVINCE_RECORD = {
  START: 'FETCH_PROVINCE_RECORD_START',
  SUCCESS: 'FETCH_PROVINCE_RECORD_SUCCESS',
  ERROR: 'FETCH_PROVINCE_RECORD_ERROR'
}

export const CREATE_PROVINCE_RECORD = {
  START: 'CREATE_PROVINCE_RECORD_START',
  SUCCESS: 'CREATE_PROVINCE_RECORD_SUCCESS',
  ERROR: 'CREATE_PROVINCE_RECORD_ERROR'
}

export const UPDATE_PROVINCE_RECORD = {
  START: 'UPDATE_PROVINCE_RECORD_START',
  SUCCESS: 'UPDATE_PROVINCE_RECORD_SUCCESS',
  ERROR: 'UPDATE_PROVINCE_RECORD_ERROR'
}

export const DELETE_PROVINCE = {
  START: 'DELETE_PROVINCE_START',
  SUCCESS: 'DELETE_PROVINCE_SUCCESS',
  ERROR: 'DELETE_PROVINCE_ERROR'
}

export const GET_PROVINCE_COUNT = {
  START: 'GET_PROVINCE_COUNT_START',
  SUCCESS: 'GET_PROVINCE_COUNT_SUCCESS',
  ERROR: 'GET_PROVINCE_COUNT_ERROR'
}
