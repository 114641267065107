import {
  INIT_CONTACTUS,
  FETCH_CONTACTUS_LIST,
  CREATE_CONTACTUS_RECORD
} from '../../../constant/actionTypes/contactUs'

const formFieldValueMap = {
  email: '',
  mobile: '',
  fname: '',
  lname: '',
  address: '',
  msg: ''
}

const initialState = {
  contactUsList: [],
  contactUsCount: 0,
  selectedcontactUs: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreatecontactUsSuccess: false,
  isEditcontactUsSuccess: false,
  iscontactUsDetailSuccess: false,
  isDeletecontactUsSuccess: false,
  isDeletecontactUsError: false,
  iscontactUsDetailError: false,
  isCreatecontactUsError: false,
  isEditcontactUsError: false
}

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CONTACTUS:
      return {
        ...state,
        formFieldValueMap: null,
        contactUsList: [],
        selectedcontactUs: null,
        isLoading: false,
        error: null
      }
    case FETCH_CONTACTUS_LIST.START:
    case CREATE_CONTACTUS_RECORD.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreatecontactUsSuccess: false,
        isEditcontactUsSuccess: false,
        iscontactUsDetailSuccess: false,
        isDeletecontactUsSuccess: false,
        isDeletecontactUsError: false,
        iscontactUsDetailError: false,
        isCreatecontactUsError: false,
        isEditcontactUsError: false
      }
    case FETCH_CONTACTUS_LIST.SUCCESS:
      return {
        ...state,
        contactUsList: action.payload,
        isLoading: false,
        error: null
      }
    case CREATE_CONTACTUS_RECORD.SUCCESS:
      return {
        ...state,
        contactUsList: [...state.contactUsList, action.payload],
        isLoading: false,
        error: null,
        isCreatecontactUsSuccess: true
      }
    case FETCH_CONTACTUS_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case CREATE_CONTACTUS_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreatecontactUsError: true
      }

    default:
      return state
  }
}

export default contactUsReducer
